@import 'var.less';

.select-type-render {
	display: flex;
	flex-wrap: wrap;
	margin-left: -10px;

	> li {
		height: 30px;
		font-size: 14px;
		color: @light-black;
		border: 1px solid @border;
		.flex-center;
		border-radius: 5px;
		margin-left: 10px;
		cursor: pointer;
		padding: 0 15px;
		margin-bottom: 10px;

		&.active {
			border-color: @red;
			position: relative;

			&:after {
				content: '';
				position: absolute;
				right: 0;
				bottom: 0;
				.icon-select-checked;
			}
		}
		&.disabled{
			color: @gray;
			background-color: @bg-gray;
		}
	}
}

.num-toggle-render {
	display: flex;
	align-items: center;
	color: @light-black;

	.add, .subtract {
		font-size: 20px;
		cursor: pointer;
		padding: 0 5px;
		height: 100%;
		user-select: none;
	}

	.current-number {
		color: @light-black;
		margin: 0 5px;
		border: 1px solid @border;
		border-radius: 5px;
		padding: 0 5px;
		width: 60px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		appearance: none;
		background-color: #fff;
	}
}

.vue-pick-up-address-component{
	.vue-pick-up-address-list{
		>li{
			padding-top: 30px;
			padding-bottom: 30px;
			padding-right: 50px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			position: relative;

			& + li{
				border-top: 1px solid @border;
			}
			&:first-child{
				padding-top: 2px;
			}
			&:last-child{
				padding-bottom: 20px;
			}
			.top,.bottom{
				display: flex;
				align-items: center;
				flex-grow: 1;
			}
			.top{
				padding-bottom: 10px;
			}
			.bottom{

			}
			.column{
				height: 100%;
				flex-shrink: 0;
			}
			.primary{
				width: 340px;
				margin-right: 20px;
			}
			.second{
				flex-grow: 1;
			}
			input,select{
				height: 45px;
				line-height: 45px;
			}
			.pick-up-item-title{
				font-size: 14px;
				color: @black;
				line-height: 1.5;
				padding: 10px 0;
			}
			.delete{
				position: absolute;
				top: 50%;
				margin-top: -9px;
				right: 0;
				margin-left: -9px;
				height: 18px;
				width: 18px;
				>img{
					width: 18px;
					height: 17px;
				}
			}
		}
	}
	.add{
		display: inline-block;
		.img-wrap(32px, 32px);
	}
}

.vue-goods-type-component{
	.vue-goods-type-table{
		text-align: left;
		width: 100%;
		thead{
			tr{
				th{
					font-size: 14px;
					color: @black;
					line-height: 1.5;
					padding: 10px 0;
				}
			}
		}
		tbody{
			tr{
				td{
					padding-top: 20px;
				}
				&:first-child{
					td{
						padding-top: 0;
					}
				}
			}
		}
		tr,td{
			box-sizing: border-box;
		}
		.sitting-title{
			width: 340px;
			padding-right: 20px;
		}
		.price{
			padding-right: 20px;
		}
		.price,.stock{
			width: 250px;
		}
		.delete{
			width: 50px;
			text-align: center;
			vertical-align: middle;
		}
		input{
			width: 100%;
			box-sizing: border-box;
		}
	}
	.add{
		margin-top: 20px;
		display: inline-block;
		.img-wrap(32px, 32px);
	}
}

.popup-select-wrapper{
	.popup-wrapper{
		padding-bottom: 20px;
		width: 865px;
		max-width: 865px;
		.popup-content{
			overflow: visible;
			max-height: unset;
		}
		.popup-footer{
			padding: 0 30px;
		}
	}
	.search-area{
		display: flex;
		align-items: center;
		select{
			width: 240px;
			margin-right: 10px;
		}
		.popup-search-word{
			border-radius: 4px 0 0 4px !important;
		}
		.btn-popup-search{
			border-radius: 0 4px 4px 0;
			flex-shrink: 0;
		}
	}
	&__show-modal{
		margin-bottom: 20px;
	}
	&__selected-list{
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	&__selected-item{
		display: inline-flex;
		align-items: center;
		padding: 0 20px;
		background-color: @bg-gray;
		height: 40px;
		line-height: 40px;
		font-size: 14px;
		color: @light-black;
		width: calc(50% - 30px);
		box-sizing: border-box;
		margin-bottom: 10px;
		&-desc{
			flex-grow: 1;
			padding-right: 20px;
			.text-overflow;
		}
		&-remove{
			flex-shrink: 0;
		}
	}
	&__data-list{
		display: flex;
		flex-wrap: wrap;
		margin-right: -20px;
		margin-top: -20px;
		>li{
			width: 250px;
			margin: 20px 20px 0 0;
			transition: all .3s;
			&:hover{
				box-shadow: 0 2px 10px 0 #dcdcdc;
			}
		}
	}
	&__data-item{
		cursor: pointer;
		display: flex;
		flex-direction: column;
		position: relative;
		border: 1px solid @border;
		&.active{
			border-color: @green;
			box-shadow: 0 2px 10px 0 @green;
			.data-item-title{
				color: @green;
			}
		}
		&-img{
			.img-wrap(100%, 173px);
		}
		&-content{
			padding: 0 10px;
			&-title{
				font-size: 16px;
				height: 45px;
				line-height: 45px;
				color: @black;
				.text-overflow;
			}
		}
	}
}
