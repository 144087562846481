
.clearfix {
  &:after {
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    height: 0;
  }
}

.container {
  width: 1080px;
  margin: 0 auto;
}
//多行裁剪
.multi-text-overflow(@line-height,@num){
	overflow : hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: @num;
	-webkit-box-orient: vertical;
	line-height: @line-height;
	height: @line-height * @num;
  }
.wrapper {
  .clearfix;
  > .content {
    float: left;
    width: 760px;
  }
}

a {
  text-decoration: none;
  color: #333;
}

b, strong {
  font-weight: bold;
}

p {
  font-size: 14px;
  line-height: 1.8;
  margin-bottom: 10px;
}

p, div {
  //word-wrap: break-word;
  //word-break: break-all;
}

.line {
  display: block;
  height: 1px;
  background-color: #fff;
}

.line-grey {
  display: block;
  height: 1px;
  background-color: #e9e9e9;
}

a {
  text-decoration: none;
  color: #333;
}

p {
  font-size: 14px;
  line-height: 1.8;
  margin-bottom: 10px;
}

.tab-pannel {
  .tab-title-list {
    li {
      a {
        display: block;
      }
    }
  }
  .tan-content-list {
    li {

    }
  }
}


.clearfix {
  clear: both;
}

.abs-right-top {
	position: absolute;
	top: 0;
	right: 0;
}

select {
  height: 45px;
  line-height: 45px;
  color: #666;
  font-size: 14px;
  background-color: #eeeeee;
  border: none;
  outline: none;
  padding: 0 0 0 20px;
  width: 190px;
}

textarea {
  background-color: #eeeeee;
  color: #666;
  padding: 10px;
  outline: none;
  border: none;
  font: 14px/1.4286 arial, "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
}

.page {
  padding-bottom: 150px;
  background-color: #fff;
}

//图片上传框
.img-cover-upload(@content: '添加图片'){
  .ossuploader-add{
    width: 300px ;
    height: 210px ;
    line-height: 210px ;
    background: #eeeeee;
    border: 0 ;
    border-radius: 4px ;
    color: #1e81cd ;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    &:after{
      content: @content;
      display: inline-block;
    }
    i,p{
      display: none;
    }
  }
}

.mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 998;
  display: none;
}

.modal-box {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 80%;
  height: 80%;
  padding: 0 40px;
  margin: auto;
  background: #fff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 999;
  display: none;
}

.modal-box.confirm-box {
  width: 600px;
  height: 300px;
}

.modal-box.confirm-box .content {
  margin: 20px 40px 40px;
  padding-top: 20px;
}

.modal-box.confirm-box .button-group {
  margin-top: 20px;
}

.modal-box.confirm-box .button-group .button {
  margin: 0 20px;
  line-height: 30px;
  width: 100px;
  font-size: 16px;
}

.modal-box .close {
  cursor: pointer;
  line-height: 70px;
}

.modal-box .close img {
  width: 20px;
}

.modal-box .modal-head h2.title {
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e9e9e9;
}

.modal-box .content {
  position: absolute;
  top: 50px;
  margin: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
}

.modal-box .content .close {
  line-height: inherit;
}

.modal-box .content h3 {
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.bdshare-button-style0-24 a {
  background-image: url(../../../../img/share-icons.png) !important;
  background-repeat: no-repeat;
  width: 20px !important;
  height: 20px !important;
  padding: 0 !important;
  line-height: 1 !important;
  margin: 0 5px 0 0 !important;
}

.popup-item {
  cursor: pointer;
}

button, input[type=button], a {
  cursor: pointer;
}

input[type=text], input[type=password], input[type=number], .select-default {
  height: 45px;
  line-height: 45px;
  color: #666;
  font-size: 14px;
  background-color: #eeeeee;
  border: none;
  outline: none;
  padding: 0 20px;
  border-radius: 4px;
}

textarea {
  background-color: #eeeeee;
  color: #666;
  padding: 10px;
  outline: none;
  border: none;
  font: 14px/1.4286 arial, "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.tab-pannel, .tab-pannel-static {

  .tab-title-list {
    &.default {
      border-bottom: 1px solid @border;
      box-sizing: border-box;
      height: 55px;
      line-height: 55px;
      font-size: 18px;
      color: @light-black;
      overflow: hidden;
      li {
        float: left;
        height: 100%;
        &.active {
          a {
            border-bottom: 2px solid @green;
            color: @green;
            display: inline-block;
            box-sizing: border-box;
            height: 100%;
          }
        }
        & + li {
          margin-left: 50px;
        }
      }
    }
  }
  .tab-content-list {

  }
}

.tab-pannel-static {
  position: relative;
  .operation {
    position: absolute;
    right: 0;
    top: 15px;
  }
}

//按钮

//循环生成 .btn-2,.btn-3
.btn-group(5);

.btn-group(@n, @i: 2) when (@i =< @n) {
  @margin-left: 10px;
  .btn-@{i} {
    overflow: hidden;
    .btn {
      float: left;
      width: calc(~"(100% - @{margin-left} * (@{i} - 1)) / @{i}");
      &:not(.hidden) + .btn {
        margin-left: @margin-left;
      }
    }
  }
  .btn-group(@n, (@i + 1));
}

.btn {
  padding: 0;
  border-radius: 4px;
  border: none;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  &:not(.hidden) + & {
    margin-left: 10px;
  }

  &.btn-green {
    background-color: @green;
    color: #fff;
    &.btn-border {
      color: @green;
      border: 1px solid @green;
      &:hover {
        color: #fff;
        background-color: @green !important;
      }
    }
  }
  &.btn-orange {
    color: #fff;
    background-color: @orange;
    &.btn-border {
      color: @orange;
      border: 1px solid @orange;
      &:hover {
        color: #fff;
        background-color: @orange !important;
      }
    }
  }
  
  &.btn-blue {
		color: #fff;
		background-color: @blue;

		&.btn-border {
			color: @blue;
			border: 1px solid @blue;

			&:hover {
				color: #fff;
				background-color: @blue !important;
			}
		}
	}

  &.btn-gray {
    color: rgba(146, 146, 146, 0.5);
    background-color: rgba(146, 146, 146, 0.5);
    &.btn-border {
      color: rgba(146, 146, 146, 0.5);
      border: 1px solid rgba(146, 146, 146, 0.5);
      &:hover {
        color: @green;
        background-color: #fff !important;
        border-color: @green;
      }
    }
  }

  &.btn-dark {
		color: @light-black;
		background-color: #e6e6e6;

		&.btn-border {
			color: @light-black;
			border: 1px solid #e6e6e6;

			&:hover {
				color: @light-black;
				background-color: #fff !important;
				border-color: @light-black;
			}
		}
	}

  &.btn-red {
		background-color: @red;
		color: #fff;

		&.btn-border {
			color: @red;
			border: 1px solid @red;

			&:hover {
				color: #fff;
				background-color: @red !important;
			}
		}
	}

  //形状
  &.btn-radius {
    border-radius: 25px;
  }
  &.btn-border {
    background-color: #fff !important;
  }
  &.btn-block {
    width: 100%;
    display: block;
  }
  &.btn-disabled {
    background-color: @gray !important;
    cursor: no-drop;
  }

  //大小
  &.btn25 {
    line-height: 25px;
    height: 25px;
    padding-left: 10px;
    padding-right: 10px;
  }
  &.btn35 {
    line-height: 35px;
    height: 35px;
    padding-left: 10px;
    padding-right: 10px;
  }
  &.btn-30{
    line-height: 30px;
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }
  &.btn20 {
    line-height: 20px;
    height: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  &.btn-list{
    line-height: 26px;
    width: 76px;
    height: 26px;
  }
  &.btn50 {
    height: 50px;
  }
  &.btn-140{
    width: 140px;
  }
  &.other-btn-150{
    width: 150px;
    font-size: 14px !important;
    height: 40px !important;
    line-height: 40px !important;
  }
  &.btn-150{
    width: 150px;
  }
  &.btn-80{
    width: 80px;
  }
  &.btn-h-45 {
		height: 45px !important;
		line-height: 45px !important;
	}
  &.btn-sm {
    height: 40px;
    line-height: 40px;
    width: 110px;
  }
  &.btn-md {
    height: 50px;
    line-height: 50px;
    width: 130px;
  }
  &.btn-lg {
		height: 35px;
		line-height: 35px;
		width: 145px;
	}
  &.btn-auto-width{
    padding-left: 10px;
    padding-right: 10px;
  }
}

//按钮结束




//标签
.tag {
  display: inline-block;
  border-radius: 24px;
  height: 24px;
  line-height: 24px;
  padding: 0 10px;
  font-size: 12px;
  &.tag-primary, &.tag-green {
    background-color: @green;
    color: #fff;
  }
  &.tag-second, &.tag-orange {
    background-color: @orange;
    color: #fff;
  }
  &.tag-sm{
    height: 15px;
    line-height: 15px;
    padding: 0 5px;
  }
}

//标签结束

//加载更多
.load_more {
  margin-top: 20px;
  cursor: pointer;
}

//数字-标题  列表
.summary-list {
  overflow: hidden;
  li {
    float: left;
    box-sizing: border-box;
    padding: 0 40px;
    .title {
      line-height: 1;
      color: @gray;
      font-size: 14px;
      margin-bottom: 15px;
    }
    .desc {
      line-height: 1;
      color: @orange;
      font-size: 18px;
    }
    & + li {
      border-left: 1px solid @border;
    }
  }

  &.summary-list-reverse {
		> li {
			display: flex;
			flex-direction: column-reverse;

			.title {
				margin-bottom: 0;
			}

			.desc {
				margin-bottom: 15px;
			}
		}
	}
}

//侧边栏
.sidebar {
  width: 300px;
  float: right;
}

//白底灰色阴影
.white-pannel {
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 12px 24px 0 rgba(15, 60, 3, 0.08);
}

//弹出信息框，一般用于ajax信息返回
.msgBoxBackGround {
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.6;
  z-index: 1000;
}

.msgBox {
  width: 500px !important;
  left: 50% !important;
  margin-left: -250px;
  box-sizing: border-box;
  padding: 15px;
  position: fixed;
  z-index: 1050;
  background-color: #fff;
  border-radius: 5px;
  .msgBoxTitle {
    margin-bottom: 20px;
    width: 100%;
    border-bottom: 1px solid @border;
    span {
      display: inline-block;
      font-size: 18px;
      border-bottom: 3px solid @green;
      margin-bottom: -2px;
      height: 36px;
      line-height: 36px;
    }
  }
  .msgBoxImage {
    display: none;
  }
  .msgBoxContent {
    color: @light-black;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }

  .msgBoxButtons {
    text-align: center;
    .btn {
      height: 40px;
      line-height: 40px;
      width: 140px;
      color: #fff;
      background-color: @green;
      margin: 0 10px;
      &:first-child{
				background-color: @orange;
			}
			&:last-child{
				background-color: @green;
			}
    }
  }
}

//弹出信息框结束
.arrd-select-wrap {
  margin-right: -2%;
  select.addr-select {
    width: 32% !important;
    margin-right: 1%;
  }
}

input[type=radio], input[type=checkbox], label {
  vertical-align: middle;
}

textarea {
  resize: none;
}

.edui-default .edui-editor {
  box-sizing: border-box;
}

.form-desc-list{
  color: @black;
  @width: 105px;
  >li{
    overflow: hidden;
    margin-bottom: 30px;
    >.title,.content>.title{
      float: left;
      width: @width;
      text-align: right;
      font-size: 14px;
    }
    >.desc,.content>.desc{
      margin-left: @width;
      font-size: 16px;
    }
    .content{
      margin-bottom: 5px;
    }
    .msg{
      margin-left: @width;
      font-size: 0;
      i{
        float: left;
      }
      .desc{
        font-size: 14px;
        margin-left: 18px;
        color: @gray;
      }
    }
  }
}



.base-manager-list{
  >li{
    border-bottom: 1px dashed @border;
    position: relative;
    overflow: hidden;
    .img{
      float: left;
    }
    .content{
      float: left;
    }
    .opera{
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin-left: -10px;
      display: flex;
      align-items: center;
      .btn{
        margin-left: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

.manager-list(@width, @height, @spaceLeft , @contentWidth){
  .base-manager-list;
  >li{
    .img{
      .img-wrap(@width,@height);
      margin-right: @spaceLeft;
    }
    .content{
      min-height: @height;
      width: @contentWidth;
    }
  }
}

.headimg-manager-list(@width, @spaceLeft, @contentWidth){
  .base-manager-list;
  >li{
    .img{
      .avatar(@width);
      margin-right: @spaceLeft;
    }
    .content{
      min-height: @width;
      width: @contentWidth;
    }
  }
}

//默认的表格样式
.table-default{
  width: 100%;
  text-align: left;
  tr{
    border-bottom: 1px dashed @border;
    td,th{
      padding: 20px 0;
      line-height: 1.5;
      vertical-align: middle;
    }
  }
  thead{
    font-size: 16px;
    color: @black;
  }
  tbody{
    color: @light-black;
    font-size: 14px;
    tr{
      td{
        box-sizing: border-box;
        padding-right: 10px;
        .opera{
          .btn{
            padding: 0 10px;
            height: 26px;
            line-height: 26px;
          }
        }
      }
    }
  }
}

.multi-text-overflow(@line-height,@num){
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: @num;
  -webkit-box-orient: vertical;
  line-height: @line-height;
  height: @line-height * @num;
}

.ossuploader-add i{
  width: 40px;
  height: 40px;
}

.select-content-search{
  font-size: 0;
  display: inline-block;
  overflow: hidden;
  select{
    float: left;
    height: 35px;
    line-height: 35px;
    width: 150px !important;
    margin-right: 0 !important;
  }
  .search-keyword{
    float: left;
    input[type="text"]{
      border-left: 0;
      width: 200px !important;
    }
    input[type="text"],button{
      height: 35px;
      line-height: 35px;
    }
  }
}

.table-extend{
  position: relative;
  overflow: hidden;
  max-width: 100%;
  table{
    table-layout: fixed;
    .thead,tbody{
      th,td{
        width: 135px;
        border: 1px solid @border;
        word-break: break-all;
      }
    }
  }
}

.badge{
  width: 60px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  &.badge-orange{
    color: #fff;
    background-color: @orange;
  }
  & + &{
    margin-left: 10px;
  }
  &.badge-gray {
		color: #fff;
		background-color: #CCCCCC;
	}
  
  &.badge-green {
		color: #fff;
		background-color: @green;
	}
  &.badge-b {
		width: 70px;
		height: 25px;
		line-height: 25px;
		font-size: 14px;
	}

}

.search-list-wrapper {
	display: flex;
	padding: 10px 0;
	border-bottom: 1px solid @border;
	.btn {
		width: 80px;
		font-size: 14px;
		margin-right: 20px;
		flex-shrink: 0;
	}

	.search-type-list {
		font-size: 14px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-left: -30px;
		>li{
			height: 30px;
			line-height: 30px;
			margin-left: 30px;
			&.active,&:hover{
				a{
					color: @green;
				}
			}
			a{
				color: @light-black;
			}
		}
	}
}

.qr-code-container{
	.img-qr-code{
		position: absolute;
		left: 0;
		top: 110%;
		width: 120px;
		height: 120px;
		display: none;
	}
	&:hover{
		.img-qr-code{
			display: block;
		}
	}
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-gray {
  background-color: #f2f3f5 !important;
}
.warning-text{
	padding-left: 20px;
	font-size: 14px;
	line-height: 1.5;
	color: @gray;
	position: relative;
	&:before{
		content: '';
		display: inline-block;
		.icon-warning;
		position: absolute;
		left: 0;
		top: 3px;
	}
	&.align-form-field{
		margin-left: 60px;
	}
}

//选中 激活的框
.selected-item{
	position: relative;
	&:after{
		content: '';
		.icon-donate-select;
		position: absolute;
		right: 0;
		bottom: 0;
	}
}



.address-default-select{
	.no-radius;
	.abs-right-top;
	.border-box;
	background-color: #ebeced !important;
}

.sp-price{
	color: @red !important;
	font-size: 24px !important;
	.sub{
		font-size: 14px;
	}
}


.arrd-select-wrapper, .select-group {
	overflow: hidden;

	select {
		width: calc(~"(100% - 40px) / 3") !important;
		float: left;
		border-radius: 4px;

		& + select {
			margin-left: 20px;
		}
	}
}


.obfit-cover {
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.ossuploader-dash-border {
  justify-content: center;
}

.notice_msg {
  font-size: 14px;
  color: #999;
  margin: 30px 0 30px 165px;
}

.hasHeader {
  padding-top: 165px;
}