.footer-new {
    width: 100%;
    .footer-top{
        background-image: url("@{img_dir}footer-bg.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 70px 0 25px;
    }
    .footer-content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left-bar{
            display: flex;
            ul{
                margin-right: 90px;
                .h1{
                    font-size: 18px;
                    color: #222;
                    font-weight: bold;
                    margin-bottom: 25px;
                }
                li{
                    font-size: 16px;
                    color: #666;
                    margin-bottom: 20px;
                }
            }
        }
        .righ-bar{
            .logo{
                img{
                    height: 80px;
                }
            }
            .text{
                font-size: 16px;
                color: #666;
                margin-top: 15px;
            }
        }
    }
    .footer-bottom {
        height: 48px;
        background-color: #68c258;
        .container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
        }
        .web-info {
            display: flex;
            color: #fff;
            font-size: 14px;
            a {
                color: #fff;
            }
            li {
                margin-right: 45px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}


.page-user-wish-list {
    .checkbox_box {
        margin-right: 10px;
        .checkbox {
            cursor: pointer;
            border: 1px solid #68c258;
            padding: 3px;
            width: 17px;
            height: 17px;
            box-sizing: border-box;
            > div {
                width: 100%;
                height: 100%;
            }
            &.active {
                > div {
                    background-color: #68c258;
                }
            }
        }
    }
}

.page-user-wish-list {
    #chooseTopicId {
        .select_form {
            > select {
                width: 100%;
            }
        }
        .btns {
            margin-top: 20px;
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
}

.xh-orguser-search {
    margin-left: -10px;
    margin-top: -10px;
    display: flex;
    flex-wrap: wrap;
    .select-content-search {
        float: unset;
    }
    &-item {
        margin-left: 10px;
        margin-top: 10px;
    }
}

.update_four_to_three {
    .charity-list .item {
        width: calc((100% - (20px * 3)) / 3);
    }
}

.w1420{
    width: 75%;
    margin: 0 auto;
    max-width: 1420px;
    min-width: 1080px;
}

.pc-wishBanner{
    img{
        display: block;
        width: 100%;
    }
}
.pc-wish-list-page, 
.pc-zixun-page {
    background: #F6F6F6;

    .list-content{
        margin: 70px auto 0;
        text-align: center;
        .list{
            display: grid;
            grid-template-columns: repeat(auto-fill,calc((100% - 70px)/3));
            column-gap: 35px;
            row-gap: 40px;
            .item{
                border-radius: 26px;
                overflow: hidden;
                background: #fff;
                text-align: left;
                transition: all .3s;
                .img-box{
                    width: 100%;
                    // height: 230px;
                    img{
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .bottom{
                    padding:28px 30px;
                    .title{
                        font-size: 22px;
                        color: #222;
                        font-weight: bold;
                    }
                    .text{
                        font-size: 18px;
                        color: #666;
                        margin-top: 30px;
                        span{
                            color: #FD7400;
                        }
                    }
                }
                &:hover{
                    box-shadow: 0px 1px 21px 0px rgba(0,0,0,0.15);
                    transform: translateY(-10px);
                }
            }
        }
    }
}
.pc-wish-list-page {
    .list-content{
        .list{
            .item{
                .img-box{
                    height: 230px;
                }
            }
        }
    }
}

.pc-wish-list-page {
    .list-content{
        margin: 70px auto 0;
        text-align: center;
        .topic_title{
            font-size: 34px;
            color: #222;
            font-weight: bold;
            line-height: 48px;
            position: relative;
            display: inline-block;
            margin-bottom: 50px;
            &::before{
                content: '';
                width: 111px;
                height: 10px;
                background: #68C258;
                position: absolute;
                bottom: 5px;
                left: -14px;
                z-index:1;
            }
            span{
                position: relative;
                z-index: 2;
            }

        }
    }
}

.pc-wish-data-bar{
    height: 140px;
    background: #fff;
    .container{
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: space-around;
        .item{
            text-align: center;
            .num{
                font-size: 32px;
                color: #68C258;
                font-weight: bold;
            }
            .desc{
                font-size: 18px;
                color: #222;
            }
        }
    }
}

.pc-wishTopic-page,
.pc-zixun-page {
    .topic-title{
        font-size: 30px;
        color: #222;
        font-weight: bold;
        display: inline-block;
        position: relative;
        line-height: 1;
        margin-bottom: 45px;
        &::before{
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 8px;
            background: #68C258;
            z-index: 1;
        }
        span{
            position: relative;
            z-index: 2;
        }
    }
}

.pc-wishTopic-page{
    background: #F6F6F6;
    
    .unit-section{
        margin-top: 65px;
        background: #fff;
        box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.1);
        border-radius: 30px;
        overflow: hidden;
        padding: 38px 30px 50px;
        .swiper-content{
            display: flex;
            align-items: center;
            .prev{
                flex-shrink: 0;
                margin-right: 20px;
                cursor: pointer;
                &.swiper-button-disabled{
                    cursor: default;
                    .icon-prev{
                        background: url("/Public/img/pc/prev.png") no-repeat center; 
                    }
                }
            }
            .next{
                flex-shrink: 0;
                margin-left: 20px;
                cursor: pointer;
                &.swiper-button-disabled{
                    cursor: default;
                    .icon-next{
                        background: url("/Public/img/pc/next.png") no-repeat center; 
                    }
                }
            }
            .swiper-container{
                width: 100%;
                flex: 1;
            }
            .swiper-slide{
                height: auto;
                .img-box{
                    height: 94px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #F6F6F6;
                    padding: 10px 12px;
                    transition: all .3s;
                    &:hover{
                        border-color: #68C258;
                    }
                }
            }
        }
    }
    .topic-section{
        display: grid;
        grid-template-columns: repeat(auto-fill,calc((100% - 30px)/2));
        column-gap: 30px;
        margin-top: 65px;
        .left-bar{
            background: #fff;
            border-radius: 30px;
            padding: 38px 30px 45px;
            .desc{
                font-size: 18px;
                color: #666;
                line-height: 1.8;
            }
            .more{
                display: inline-block;
                color: #68C258;
                font-size: 18px;
                margin-top: 20px;
            }
        }
        .right-bar{
            background: #fff;
            border-radius: 30px;
            padding: 38px 30px 45px;
            .active-swiper{
                .img-box{
                    width: 100%;
                    height: 320px;
                    border-radius: 20px;
                    overflow: hidden;
                    img{
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .swiper-pagination-bullet{
                    opacity: 1;
                    background: #fff;
                    &.swiper-pagination-bullet-active{
                        background: #68C258;
                    }
                }
            }
        }
    }
    .wish-section{
        margin-top: 65px;
        background: #fff;
        box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.1);
        border-radius: 30px;
        overflow: hidden;
        padding: 38px 30px 50px;
        .topic-title{
            margin-bottom: 20px;
        }
        .tips{
            font-size: 18px;
            color: #68C258;
            margin-bottom: 30px;
        }
        #wish_search_form{
            .content{
                display: flex;
                align-items: center;
                select{
                    .icon-select-drow;
                    width: 130px;
                    height: 35px;
                    background-color: #fff;
                    color: @green;
                    border-radius: 4px;
                    border: 1px solid @green;
                    margin-right: 20px;
                    font-size: 16px;
                    appearance: none;
                    background-position: 85px center;
                    line-height: 35px;
                    &:nth-child(2){
                        width: 156px;
                        background-position: 111px center;
                    }
                }
                .search-keyword{
                    input[type=text]{
                        background-color: #fff;
                        width: 355px;
                        border: 1px solid #68c258;
                        border-right: 0;
                    }
                }
                .screen-btn{
                    margin-left: 30px;
                    width: 126px;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 6px;
                    background: #FF9B00;
                    color: #fff;
                    font-size: 16px;
                    .icon-drowDown{
                        background: url("/Public/img/pc/down-arrow-white.png") no-repeat center; 
                        width: 14px;
                        height: 9px;
                        background-origin:content-box;
                        background-size: 14px 9px;
                        margin-left: 8px;
                    }
                }
            }
        }
        .screen-list{
            margin-top: 30px;
            .title{
                font-size: 18px;
                color: #222;
            }
            ul {
                li {
                    display: flex;
                    border-bottom: 1px dashed #e5e5e5;
                    &:nth-child(1) {
                        margin-top: 0;
                    }
                    .hidden-input {
                        display: none;
                    }
                    .label-title {
                        flex-shrink: 0;
                        label {
                            display: block;
                            font-size: 16px;
                            box-sizing: border-box;
                            color: #222222;
                            height: 58px;
                            line-height: 58px;
                            min-width: 96px;
                        }
                    }
                    .right-choose {
                        font-size: 18px;
                        flex-grow: 1;
                        display: flex;
                        justify-content: space-between;
                        font-size: 16px;
                        &.has-search-input {
                            border-bottom: none;
                            align-items: center;
                        }
                        .choose-item {
                            line-height: 58px;
                            overflow: hidden;
                            display: flex;
                            .all {
                                cursor: pointer;
                                flex-shrink: 0;
                                display: flex;
                                align-items: center;
                                height: 58px;
                                &:hover,&.choose{
                                    color: #68C258;
                                }
                            }
                            .main-item {
                                display: flex;
                                flex-wrap: wrap;
                                margin-right: -32px;
                                >div {
                                    cursor: pointer;
                                    display: flex;
                                    align-items: center;
                                    margin-right: 32px;
                                    &.checkBox_selected{
                                        color: #68C258;
                                    }
                                    >label {
                                        display: flex;
                                        align-items: center;
                                        cursor: pointer;
                                        &:hover{
                                            color: #68C258;
                                        }
                                    }
                                }
                                &.has-regional {
                                    padding-left: 52px;
                                    >div {
                                        margin-right: 44px;
                                    }
                                }
                            }
    
                        }
                        .icon {
                            line-height: 55px;
                            cursor: pointer;
                            .icon-tobottom{
                                background: url("/Public/img/pc/down-arrow.png") no-repeat center;
                                width: 14px;
                                height: 9px;
                                background-origin:content-box;
                                background-size: 14px 9px;
                                margin-left: 8px;
                                transition: all .3s;
                            }
                            &.up {
                                .icon-tobottom{
                                    transform: rotateZ(180deg);
                                }
                            }
                        }
                    }
                }
            }
        }
        .wish-list{
            .item{
                display: flex;
                margin-top: 58px;
                justify-content: space-between;
                .img-box{
                    position: relative;
                    width: 380px;
                    height: 230px;
                    overflow: hidden;
                    border-radius: 20px;
                    flex-shrink: 0;
                    img{
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        position: relative;
                        z-index: 1;
                        transition: all .3s;
                    }
                    .status{
                        position: absolute;
                        width: 120px;
                        top: 0;
                        right: 0;
                        color: #fff;
                        font-size: 18px;
                        height: 40px;
                        line-height: 40px;
                        z-index: 2;
                        text-align: center;
                        border-bottom-left-radius: 20px;
                        &.onging{
                            background: rgba(104, 194, 88, 0.9);
                        }
                        &.end{
                            background: rgba(0,0,0,0.5);
                        }
                    }
                }
                .right-cont{
                    width: calc(100% - 410px);
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    .title{
                        font-size: 24px;
                        color: #222;
                        font-weight: bold;
                        transition: all .3s;
                    }
                    .desc{
                        font-size: 18px;
                        color: #666;
                        margin-top: 30px;
                    }
                    .tag{
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        margin-top: auto;
                        padding: 0;
                        .tag-item{
                            font-size: 18px;
                            margin-right: 56px;
                            &.target{
                                color: #68C258;
                            }
                            &.now{
                                color: #FF9B00;
                            }
                            span{
                                color: #666;
                            }
                        }
                    }
                    .progress{
                        height: 14px;
                        overflow: hidden;
                        position: relative;
                        margin-top: 14px;
                        .progress-wraper {
                          border-radius: 25px;
                          overflow: hidden;
                          background-color: #ededed;
                          height: 8px;
                          margin-top: 3px;
                          margin-right: 58px;
                          .progress-inner{
                            height: 8px;
                            border-radius: 25px;
                            background-color: @green;
                            position: relative;
                            &.active::before{
                                content: "";
                                opacity: 0;
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background: #FFF;
                                border-radius: 25px;
                                animation: progress-active 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
                            }
                          }
                        }
                        .count {
                          text-align: right;
                          line-height: 1;
                          width: 40px;
                          position: absolute;
                          right: 0;
                          top: 0;
                          color: @green;
                          font-size: 16px;
                        }
                    }
                    .icon-success{
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        z-index: 2;
                    }
                }
                &:hover{
                    .img-box{
                        img{
                            transform: scale(1.1);
                        }
                    }
                    .right-cont{
                        .title{
                            color:@green;
                        }
                    }
                }
            }
        }
    }
}

.icon-prev{
    display: inline-block;
    background: url("/Public/img/pc/prev-hover.png") no-repeat center;
    width: 15px;
    height: 28px;
    background-origin:content-box;
    background-size: 15px 28px;
}
.icon-next{
    display: inline-block;
    background: url("/Public/img/pc/next-hover.png") no-repeat center;
    width: 15px;
    height: 28px;
    background-origin:content-box;
    background-size: 15px 28px;
}

.pc-wishDetails-page{
    background: #F6F6F6;
    .section{
        box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.1);
        background: #FFFFFF;
        padding:36px 30px;
        border-radius: 30px;
        overflow: hidden;
        margin-top: 70px;
    }
    .topic-title{
        margin-bottom: 38px;
        display: flex;
        align-items: center;
        .title{
            font-size: 30px;
            color: #222;
            font-weight: bold;
            display: inline-block;
            position: relative;
            line-height: 1;
            &::before{
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 8px;
                background: #68C258;
                z-index: 1;
            }
            span{
                position: relative;
                z-index: 2;
            }
        }
        .more{
            font-size: 18px;
            color: @green;
            margin-left: auto;
        }
    }
    .header-section{
        display: flex;
        justify-content: space-between;
        .left-img{
            width: calc(48% - 28px);
            height: 375px;
            border-radius: 20px;
            overflow: hidden;
            position: relative;
            flex-shrink: 0;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
            .status{
                position: absolute;
                right: 20px;
                bottom: 20px;
                position: absolute;
                width: 120px;
                color: #fff;
                font-size: 18px;
                height: 40px;
                line-height: 40px;
                z-index: 2;
                text-align: center;
                border-radius: 8px;
                &.onging{
                    background: rgba(104, 194, 88, 0.9);
                }
                &.end{
                    background: rgba(0,0,0,0.5);
                }
            }
        }
        .right-cont{
            width: 52%;
            position: relative;
            .title{
                font-size: 24px;
                color: #222222;
                font-weight: bold;
            }
            .desc-list{
                margin-top: 40px;
                .item{
                    font-size: 18px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 18px;
                    .label{
                        color: #666666;
                    }
                    >a{
                        display: flex;
                        align-items: center;
                    }
                    .name{
                        color: @green;
                        text-decoration: underline;
                    }
                    .logo{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 40px;
                        height: 40px;
                        border: 1px solid #EEEEEE;
                        margin-right: 10px;
                    }
                }
            }
            .progress{
                margin-top: 10px;
                height: 18px;
                display: flex;
                align-items: center;
                .progress-wraper{
                    width: calc(100% - 58px);
                    margin-top: 0;
                    margin-right: 0;
                }
                .count{
                    color: @green;
                    font-size: 16px;
                }
            }
            .data{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                .item{
                    display: flex;
                    margin-top: 20px;
                    align-items: center;
                    font-size: 18px;
                    color: #222;
                    margin-right: 9%;
                    line-height: 1;
                    &:last-child{
                        margin-right: 0;
                    }
                    >img{
                        margin-right: 10px;
                    }
                    span{
                        color: @green;
                    }
                }
            }
            .btn-group{
                display: flex;
                align-items: center;
                margin-top: 30px;
                .btn{
                    margin-right: 30px;
                    width: 180px;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    border-radius: 6px;
                    color: #fff;
                    font-size: 18px;
                    margin-left: 0;
                    &.orange{
                        background: #FF9B00;
                    }
                    &.green{
                        background: @green;
                    }
                }
            }
            .scan-code{
                position: absolute;
                right: 0;
                top: 0;
                cursor: pointer;
                .box{
                    position: absolute;
                    right: 0;
                    top: 0;
                    pointer-events: none;
                    width: 150px;
                    height: 150px;
                    border-radius: 10px;
                    border: 1px solid @green;
                    padding: 10px;
                    background: #fff;
                    box-sizing: border-box;
                    opacity: 0;
                    transition: all .6s;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .phone-cont{
                    text-align: center;
                    img{
                        margin: 0 auto;
                    }
                    .img1{
                        display: block;
                    }
                    .img2{
                        display: none;
                    }
                    .text{
                        font-size: 16px;
                        color: #999;
                        margin-top: 5px;
                        transition: all .3s;
                    }
                }
                &:hover{
                    .box{
                        right: 110%;
                        opacity: 1;
                    }
                    .phone-cont{
                        .img1{
                            display: none;
                        }
                        .img2{
                            display: block;
                        }
                        .text{
                            color: @green;
                        }
                    }
                }
            }
        }
    }
    .record-section{
        .list{
            display: grid;
            grid-template-columns: repeat(auto-fill,calc((100% - 350px)/8));
            column-gap: 50px;
            row-gap: 50px;
            .item{
                text-align: center;
                .avatar{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    border-radius: 50%;
                    width: 125px;
                    height: 125px;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .bottom{
                    margin-top: 25px;
                    .name{
                        font-size: 18px;
                        color: #222222;
                    }
                    .donate_count{
                        font-size: 16px;
                        color: @green;
                        font-weight: bold;
                        margin-top: 5px;
                    }
                }
            }

        }
    }
    .detail-section{
        .list{
            .item{
                padding: 40px 0;
                border-bottom: 1px dashed @green;
                &:first-child{
                    padding-top: 0;
                }
                &:last-child{
                    border: none;
                    padding-bottom: 0;
                }
                .head{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .date{
                        font-size: 20px;
                        color: @green;
                    }
                    .more{
                        width: 98px;
                        height: 36px;
                        border: 1px solid @green;
                        border-radius: 10px;
                        font-size: 16px;
                        text-align: center;
                        line-height: 36px;
                        color: @green;
                    }
                }
                .desc{
                    font-size: 16px;
                    line-height: 1.8;
                    color: #666;
                    margin-top: 20px;
                }
                .album{
                    .img-gallery{
                        display: flex;
                        flex-wrap: wrap;
                        li{
                            width: 325px;
                            margin-right: 20px;
                            margin-top: 26px;
                        }
                    }
                }
            }
        }
    }
}

.pc-wishArticle-page{
    background: #F6F6F6;
    min-height: 100vh;
    .wishArticle{
        box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.1);
        background: #FFFFFF;
        padding: 0 30px 40px;
        border-radius: 30px;
        overflow: hidden;
        .head{
            position: relative;
            padding: 40px 60px;
            text-align: center;
            border-bottom: 1px solid #EEEEEE;
            margin-bottom: 40px;
            .back{
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            .title{
                font-size: 30px;
                font-weight: bold;
                color: @green;
                line-height: 1.6;
            }
        }
        .article-content{
            .topic-title{
                font-size: 24px;
                color: #222;
                font-weight: bold;
                display: inline-block;
                position: relative;
                line-height: 1;
                margin-bottom: 30px;
                &::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 8px;
                    background: #68C258;
                    z-index: 1;
                }
                span{
                    position: relative;
                    z-index: 2;
                }
            }
            .date{
                font-size: 20px;
                color: #68C258;
                margin-bottom: 15px;
            }
        }
        .donate-record-list{
            .topic-title{
                font-size: 24px;
                color: #222;
                font-weight: bold;
                display: inline-block;
                position: relative;
                line-height: 1;
                margin-bottom: 10px;
                &::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 8px;
                    background: #68C258;
                    z-index: 1;
                }
                span{
                    position: relative;
                    z-index: 2;
                }
            }
            .list{
                .item{
                    padding: 28px 0;
                    border-bottom: 1px dashed @green;
                    display: flex;
                    align-items: center;
                    .avatar{
                        width: 110px;
                        height: 110px;
                        overflow: hidden;
                        border-radius: 50%;
                        flex-shrink: 0;
                        img{
                            display: block;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .content{
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        margin-left: 18px;
                        .name{
                            font-size: 20px;
                            color: #222;
                        }
                        .date{
                            color: #999;
                            margin-top: 30px;
                            font-size: 16px;
                        }
                    }
                    .count{
                        margin-left: auto;
                        font-size: 20px;
                        font-weight: bold;
                        flex-shrink: 0;
                        color: #222222;
                        span{
                            color: @green;
                        }
                    }
                }
            }
        }
    }
}

.page-wish-user-donate{
    .select-item{
        .icon-select-drow;
        width: 130px;
        height: 35px;
        line-height: 36px;
        background-color: #fff;
        color: @green;
        border-radius: 4px;
        border: 1px solid @green;
        margin-right: 20px;
        font-size: 16px;
        appearance: none;
        background-position: 85px center;
    }
    .donate-list{
        li{
            overflow: hidden;
            padding: 20px 0;
            border-bottom: 1px solid #ededed;
            display: flex;
            align-items: center;
            .meta{
                width: 360px;
                margin-right: 50px;
                color: #333333;
                .title{
                    font-size: 16px;
                }
                .date,.order_num{
                    font-size: 14px;
                    margin-top: 5px;
                    span{
                        color: #999;
                    }
                }
            }
            .donate_count{
                font-size: 14px;
                color: #f61919;
            }
            .btn-content{
                margin-left: auto;
            }
            .btn{
                width: 100px;
                height: 25px;
                line-height: 25px;
            }
        }
    }
}

.page-wish-donate-index{
    h4{
        &.title{
            font-size: 16px;
            span{
                color: @green;
                font-size: 18px;
            }
        }
    }
    .tips-message > .message {
        margin-left: 10px;
        font-size: 14px;
        color: #999999;
        line-height: 1.5;
    }
    .tips-text{
        padding-left: 24px;
        background: url('/Public/mobile/img/notice.png') no-repeat top left 3px;
        background-size:16px 16px;
        color: #999;
        margin-top: 8px;
        line-height: 1.2;
    }
    .form-group{
        p{
            margin-bottom: 0;
        }
    }
    .donate_count_box{
        padding: 0 0 30px;
        border-bottom: 1px solid #e9e9e9;
        .donate_count_title{
          font-size: 14px;
          font-weight: 500;
          margin-bottom:20px;
        }
        .donate_input_box{
          overflow: hidden;
          width: 100%;
          .donate_count_item{
            cursor: pointer;
            position: relative;
            width: calc(~'25% - 10px');
            font-size: 14px;
            line-height: 44px;
            border-radius: 4px;
            margin-left: 5px;
            margin-right: 5px;
            border: 2px solid #f3f3f3;
            height: 44px;
            &:first-child{
              margin-left: 0;
            }
            &:last-child{
              margin-right: 0;
            }
          }
          .donate_count_item_active{
            border:2px solid @red;
          }
        }
        .other_num{
          position: relative;
          overflow: hidden;
          >label,>span{
            font-size: 14px;
          }
          >input{
            width: calc(~'100% - 70px');
          }
        }
      }
      .donate_form .selected {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 22px;
        height: 22px;
    }
    .btn-submit {
        width: 145px;
        height: 35px;
        line-height: 35px;
    }
    .pay-method{
        .input{
            span{
                cursor: pointer;
                font-size: 16px;
                text-align: center;
                height: 60px;
                line-height: 58px;
                box-sizing: border-box;
                float: left;
                border: 1px solid #ededed;
                border-radius: 4px;
                width: 180px;
                color: #333333;
                position: relative;
                &.on{
                    border: 3px solid @red;
                    line-height: 54px;
                    &:after{
                      content: '';
                      .icon-donate-select;
                      position: absolute;
                      right: 0;
                      bottom: 0;
                    }
                  }
                & + span{
                    margin-left: 20px;
                }
            }
        }
    }
    .form-group.form-invoice{
        padding: 30px 0;
        .title{
            line-height: 1.2;
            height: auto;        
        }
        .input label {
            display: inline-block;
            &+label{
                margin-left: 45px;
            }
        }
        .input{
            line-height: 15px;
            input{
                margin-right: 5px;
            }
        }
    }
}
.pc-wishBanner{
//通用轮播图
.slide_show{
    .swiper-slide {
      width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .play-icon{
      position: absolute;
      left: 50%;
      top: 50%;
      width: 114px;
      height: 114px;
      transform: translate(-50%, -50%);
    }
    .swiper-pagination-bullet{
      background: rgba(255,255,255,.7)!important;
      width: 20px;
      height: 8px;
      opacity: 1!important;
      border-radius: 8px;
      bottom: 20px!important;
      transition: all .3s;
    }
    .swiper-pagination-bullet-active {
      background: #68c258 !important;
      width: 30px;
      transition: all .3s;
    }
  }
}
#video-popup{
    iframe{
        width: 100%;
        height: 490px;
    }
}
body.no-scroll{
    overflow: hidden;
}

.msg-mask{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: rgba(0,0,0,0.5);
    top: 0;
    left: 0;
    display: none;
    transition: all .3s;
}
.notify-bar{
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1001;
    background: #fff;
    width: 0;
    height: 100%;
    transition: all .6s;
    overflow-x: hidden;
    &.show{
        width: 600px;
    }
    .notify-head{
        background: #F2F3F5;
        padding: 30px;
        width: 600px;
        box-sizing: border-box;
        .top{
            display: flex;
            align-items: center;
            .title{
                display: flex;
                align-items: flex-start;
                span{
                    font-size: 24px;
                    color: #333;
                    margin-left: 12px;
                    font-weight: bold;
                }
            }
            .close{
                margin-left: auto;
                flex-shrink: 0;
                cursor: pointer;
            }
        }
        .control-cont{
            margin-top: 30px;
            display: flex;
            select {
                .icon-select-drow;
                width: 130px;
                height: 35px;
                line-height: 35px;
                background-color: #fff;
                color: #90D147;
                border-radius: 4px;
                border: 1px solid #90D147;
                margin-right: 20px;
                font-size: 16px;
                appearance: none;
                background-position: 85px center;
                padding-left: 14px;
            }
            .mark-btn{
                cursor: pointer;
                width: 150px;
                height: 35px;
                background-color: #fff;
                color: #90D147;
                border: 1px solid #90D147;
                border-radius: 4px;
                padding:0 10px 0 14px;
                font-size: 16px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                span{
                    margin-left: 6px;
                    line-height: 1;
                }
            }
            .more{
                margin-left: auto;
                width: 100px;
                height: 35px;
                line-height: 35px;
                background-color: #90D147;
                color: #fff;
                font-size: 16px;
                text-align: center;
                border-radius: 4px;
            }
        }
    }
    .notify-body{
        height: calc(100% - 159px);
        overflow-x: hidden;
        overflow-y: auto;
        .notify-list{
            width: 600px;
            a.item{
                display: block;
                padding: 20px 30px;
                border-bottom: 1px solid #F2F3F5;
                .title{
                    position: relative;
                    display: flex;
                    align-items: center;
                    &::before{
                        content: '';
                        display: block;
                        width: 8px;
                        height: 8px;
                        background: #F61919;
                        border-radius: 50%;
                        flex-shrink: 0;
                        margin-right: 10px;
                    }
                    span{
                        font-size: 16px;
                        color: #333;
                        width: calc(100% - 18px);
                    }
                }
                .date{
                    font-size: 14px;
                    color: #999;
                    margin-top: 14px;
                    margin-left: 18px;
                }
                &:last-child{
                    border: none;
                }
                &.read{
                    .title{
                        &::before{
                            opacity: 0;
                        }
                        span{
                            color: #999;
                        }
                    }
                }
            }
        }
    }
    .noData{
        margin-top: 92px;
        text-align: center;
        width: 600px;
        .tips{
            margin-top: 18px;
            font-size: 16px;
            color: #666;
        }
    }
}

.mark-mask{
    position: fixed;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s;
    opacity: 0;
    z-index: 1002;
    &.active{
        opacity: 1;
    }
}
.mark-popup-wrapper{
    z-index: 1003;
    width: 500px;
    position: fixed;
    border-radius: 4px;
    background-color: #fff;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    opacity: 0;
    transition: all 0.3s;
    margin-top: 20px;
    &.active{
        opacity: 1;
        margin-top: 0;
    }
    .mark-popup-header {
        padding: 10px 30px 0;
        h3.title {
            border-bottom: 1px solid #e9e9e9;
            margin-bottom: 20px;
            span {
                display: inline-block;
                font-size: 18px;
                border-bottom: 3px solid #68c258;
                margin-bottom: -2px;
                height: 36px;
                line-height: 36px;
            }
        }
    }
    .mark-popup-content {
        max-height: 500px;
        overflow: auto;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0 30px;
        color: #666666;
        p {
            font-size: 16px;
            line-height: 1.6;
        }
    }
    .mark-popup-footer {
        padding-bottom: 20px;
        .btn {
            width: 140px;
            &.close-mark{
                border: 1px solid #68C258;
                background: #fff;
                border-radius: 4px;
                color: #68C258;
                border-radius: 4px;
            }
        }
    }
}
.msg-center{
    .msg-center-head{
        padding-top: 30px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        >select{
            cursor: pointer;
            width: 130px;
            border-radius: 4px;
            font-size: 14px;
            padding-left: 6px;
            height: 35px;
            line-height: 35px;
            background-color: #F4F4F4;
            color: #666;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            position: relative;
            display: inline-block;
            background-image: url("../../../../img/pc/down-arrow2.png");
            background-repeat: no-repeat;
            background-size: 17px 17px;
            background-position:110px center;
        }
        .mark-btn{
            width: 150px;
            height: 35px;
            background: #68C258;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            span{
                font-size: 14px;
                color: #fff;
                margin-left: 7px;
            }
        }
    }

    .msg-table-content{
        .table{
            border-top: 1px solid #E9E9E9;
            .tr{
                display: flex;
            }
            .td,.th{
                padding: 14px 18px;
                border-right: 1px solid #E9E9E9;
                border-bottom: 1px solid #E9E9E9;
                box-sizing: border-box;
            }
            .table-thead{
                background: #F4F4F4;
                border-left: 1px solid #E9E9E9;
                .th:nth-child(1){
                    width: 480px;
                }
                .th:nth-child(2){
                    width: 179px;
                }
                .th:nth-child(3){
                    width: 149px;
                }
                .th{
                    font-weight: bold;
                    font-size: 16px;
                    color: #333;
                }
            }
            .table-body{
                border-left: 1px solid #E9E9E9;
                .td{
                    font-size: 14px;
                    color: #333;
                    >a{
                        display: block;
                    }
                    .date, .type {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
                >.tr{
                    .td:nth-child(1){
                        width: 480px;
                    }
                    .td:nth-child(2){
                        width: 179px;
                    }
                    .td:nth-child(3){
                        width: 149px;
                    }
                    &.read{
                        .td{
                            color: #999;
                            >a{
                                color: #999;
                            }
                        }
                    }
                }
                .noData{
                    text-align: center;
                    border-bottom: 1px solid #E9E9E9;
                    border-right: 1px solid #E9E9E9;
                    padding: 14px 18px;
                    font-size: 14px;
                    color: #666;
                }
            }
        }
    }
}
.msg-detail{
    padding-top: 30px;
    display: flex;
    align-items: flex-start;
    .back{
        margin-right: 20px;
        flex-shrink: 0;
    }
    .main-info{
        flex: 1;
        h1{
            font-size: 20px;
            color: #333;
            font-weight: bold;
        }
        .date{
            color: #999;
            font-size: 14px;
            margin-top: 28px;
            padding-bottom: 26px;
            border-bottom: 1px solid #E9E9E9;
            margin-bottom: 30px;
        }
    }
}


// 拥有备案号时间的form需要加长
.has_filing_code_time_form {
    .form-group .title {
        width: 160px;
    }
    .form-group .input {
        margin-left: 160px;
    }
    .form-group .msg {
        margin-left: 160px;
    }

}

.pc_wish_topic_video_model {
    display: none;
    .modal_box {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
        background: rgba(0, 0, 0, 0.5);
        .btnClose {
            position: absolute;
            top: calc((100% - 80%) / 4);
            right: calc((100% - 80%) / 4);
            transform: translate(50%, -50%);
            cursor: pointer;
        }
        .modal_content {
            width: 80%;
            height: 80%;
            top: 50%;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
        }
    }
}

.sz-report-success {
    padding-top: 165px;
    .message-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        > h1 {
            color: #68c258;
            font-size: 26px;
            margin-bottom: 25px;
        }
        .article-content {
            p {
                text-align: center;
                font-size: 20px;
                color: #999;
                .text-black {
                    color: #333;
                }
            }
        }
        .operation {
            margin-top: 20px;
            > a {
                font-size: 16px;
                padding: 0 18px;
            }
        }
    }
}

// 添加关联项目显示的名称字数太长需要做限制
.selected-activity-need-limit-font {
    span.text-cut {
        width: 100%;
    }
}
.icon-new-xh {
    height: 19px;
    width: auto;
    max-width: 19px;
    max-width: 19px;
}