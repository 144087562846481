@import 'var.less';

.sx-page{
  .project-list{
    .item{
      a{
        > .img{
          height: 145px;
          img{
            height: 100%;
          }
        }
      }

    }
  }
}
.activity-detail {
  //padding-top: 20px;
  .summary {
    zoom: 1;
    overflow: hidden;
    margin-bottom: 40px;
    .img {
      float: left;
      width: 460px;
      position: relative;
      padding-top: 2px;
      img {
        width: 460px;
        height: 284px;
      }
    }
    .img-wrap {
      width: 285px;
      height: 285px;
      border-radius: 50%;
      img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
      }
    }
    .content {
      float: right;
      width: 600px;
      height: 284px;
      font-size: 14px;
      position: relative;
      h1 {
        font-size: 24px;
        color: #68c258;
        margin-bottom: 20px;
      }
      .meta {
        &.other-activity-meta {
          position: relative;
          .progress-bar {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            .circle-cover {
              width: 87px;
              height: 87px;
              border: 3px solid #fd7400;
              border-radius: 50%;
              position: absolute;
              z-index: 9999;
              left: 0;
              top: 0;
            }
            .circle {
              width: 87px;
              height: 87px;
              border: 3px solid #feb97f;
              border-radius: 50%;
              .progress-text {
                font-size: 24px;
                width: 100%;
                color: @orange;
                line-height: 87px;
                text-align: center;
              }
            }
          }
          .item {
            width: 80%;
            .right{
              color:#333;
            }
            .time {
              padding-left: 0;
              background: none;
              a.link-right{
                color:#333;
                vertical-align: middle;
              }
            }
            .fund-list {
              margin-top: 20px;
              margin-bottom: 20px;
              overflow: hidden;
              zoom: 1;
              li {
                border-right: 1px solid #E9E9E9;
                padding-right: 40px;
                float: left;
                .text {
                  color: #666666;
                  margin-bottom: 0;
                }
                .show-money {
                  color: @orange;
                  font-size: 20px;
                  margin-bottom: 0;
                  font-weight: bold;
                  text-align: center;
                }
                & + li {
                  padding-left: 45px;
                }
                &:last-child {
                  border: none;
                }
              }

            }
            .address {
              padding-left: 0;
              background: none;
            }
            .apply {
              padding-left: 0;
              background: none;
            }
            .lowest-goal {
              padding-left: 0;
              background: none;
            }
            .voluntary {
              padding-left: 0;
              background: none;
            }
          }
        }
        font-size: 14px;
        .item {
          color: #999999;

          div {
            margin: 10px 0;
          }
          a, span,.title {
            &.show-money {
              font-weight: bold;
              font-size: 14px;
              color: @orange;
            }
          }
          span{
            vertical-align: middle;
          }
          .desc{
            color: #333;
          }
          .detail-tips {
            display: inline-block;
            margin: 0;
            .icon-warning {
              padding-right: 5px;
            }
            span {
              color: #999999;
            }
            &.not-line-tips{
              margin-left: 20px;

            }
          }
          a {
            color: #1e81cd;
          }
          a.link-right{
            color:#333;
          }

          .time {
            padding-left: 25px;
            background: url(../../../../img/icon/time.png) no-repeat left center;
          }
          .address {
            display: table;
            padding-left: 25px;
            background: url(../../../../img/icon/address2.png) no-repeat left top;
            overflow: hidden;
            .title{
              word-break: keep-all;
              white-space:nowrap;
              width: 70px;
              float: left;
              vertical-align: top;
            }
            .desc{
              width: 485px;
              vertical-align: top;
            }
          }
          .apply-time{
            padding-left: 25px;
            background: url(../../../../img/icon/clock.png) no-repeat left center;
            background-size: 15px 14px;
          }
          .apply {
            padding-left: 25px;
            background: url(../../../../img/icon/apply.png) no-repeat left center;
          }
          .lowest-goal {
            padding-left: 25px;
            background: url(../../../../img/icon/lowest-goal.png) no-repeat left center;
          }
          .voluntary {
            padding-left: 25px;
            background: url(../../../../img/activity-voluntary.png) no-repeat left center;
          }
          .price {
            padding-left: 25px;
            background: url(../../../../img/icon/price.png) no-repeat left center;
            span {
              &.show-money {
                color: @orange;
                padding-right: 20px;
              }
            }
          }
          .money {
            color: @orange;
          }
        }
      }
      .bottom {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 40px;
        .btn {
          float: left;
          height: 35px;
          border-radius: 3px;
          line-height: 35px;
          font-size: 18px;
        }
        .share {
          float: left;
          margin-left: 15px;
          height: 35px;
          line-height: 35px;
          padding-top: 2.5px;
        }
        .tips-text {
          float: left;
          margin-left: 20px;
          line-height: 35px;
          span{
            color:#999;
            font-size: 12px;
          }
        }
        .qr-code-container {
          position: absolute;
          right: 0px;
          bottom: 0;
          width: 190px;
          box-sizing: border-box;
          border: 1px solid #68c258;
          border-radius: 5px;
          overflow: hidden;
          padding: 6px;
          .img-box {
            width: 70px;
            height: 70px;
            overflow: hidden;
            float: left;
          }
          .tips {
            position: absolute;
            margin-left: 78px;
            top: 50%;
            transform: translateY(-50%);
            color: #999;
            > div{
              font-size: 12px;
              margin-bottom: 5px;
              line-height: 1.2;
              &:last-child{
                margin-bottom: 0;
              }

            }
          }
        }

      }

    }

    .user-content-665 {
      width: 665px;
      height: 285px;
    }
  }
  .detail {
    border-top: 10px solid #f5f5f5;
    .container {
      zoom: 1;
      overflow: hidden;
      .main-info {
        font-size: 18px;

        &.all-info {
          width: 100%;
          float: none;
        }
        width: 760px;
        float: left;
        .nav-tabs {
          border-bottom: 1px solid #e9e9e9;
          padding-top: 5px;
          > li {
            text-align: center;
            font-size: 18px;
            display: inline-block;
            padding: 10px 0px;
            margin-right: 50px;
            &.active {
              border-bottom: 2px solid @green;
              a {
                color: @green;
              }
            }
          }
        }
        .tab-content > .tab-pane {
          display: none;
          //padding-top: 20px;
        }
        .tab-content > .active {
          display: block;
        }
        .tab-content {
          .search-content{
            margin-bottom: 10px;
            margin-top: 25px;
            overflow: hidden;
            @height: 30px;
            form{
              float: left;
              line-height: @height;
              height: @height;
              .search-form-wrapper{
                line-height: @height;
                height: @height;
                input[type="text"]{
                  background-color: #f5f5f5;
                  border: 1px solid #e6e6e6;
                  border-right: 0;
                  line-height: @height;
                  height: @height;
                }
                .btn-search{
                  line-height: @height;
                  height: @height;
                }
              }
            }
            .btn{
              line-height: @height;
              height: @height;
              width: 100px;
              float: right;
            }
          }

          .activity-project-list {
            .item {
              zoom: 1;
              overflow: hidden;
              padding: 20px 0 14px 0;
              border-bottom: 1px dashed #e9e9e9;
              position: relative;
              img.avatar {
                float: left;
                margin-right: 20px;
                width: 144px;
                height: 96px;
              }
              .name {
                float: left;
                width: 78%;
                height: 101px;
                max-height: 101px;
                overflow: hidden;
                position: relative;
                h4 {
                  font-size: 16px;
                  margin: 0 0 25px;
                  .multi-text-overflow(1.5,2);
                  height: 45px;
                }
                .p-bottom-box{
                  position: absolute;
                  bottom: 0;
                  left: 0;
                }
                p {
                  margin-bottom: 0;
                }
                .date {
                  color: #999999;
                  margin-bottom: 0;
                }
                .text-opacity-gray{
                  color:#666666;
                }
                .title {
                  color: #999999;
                }
                .money {
                  color: @orange;
                }
              }
              .status {
                position: absolute;
                right: 0;
                top: 20px;
                width: 75px;
                height: 25px;
                line-height: 25px;
                -ms-text-align-last: right;
                text-align: center;
                border: 1px solid @green;
                border-radius: 4px;
                color: @green;
              }
            }
          }
          .apply-record {
            .item {
              zoom: 1;
              overflow: hidden;
              padding: 20px 0;
              border-bottom: 1px dashed #e9e9e9;
              position: relative;
              .avatar {
                float: left;
                margin-right: 20px;
                .avatar(100px);
                &.team-logo{
                  .img-wrap(135px,100px);
                  border-radius: 3px;
                }
                &.user-avatar{
                  .avatar(68px);
                }

              }

              .name {
                display: table;
                width: calc(100% - 120px);
                width: 70%;
                &.team-name{
                  height: 95px;
                }
                h4 {
                  font-size: 16px;
                  margin: 0 0 20px 0;
                }
                p {
                  margin-bottom: 0;
                  color:#666;
                  font-size: 14px
                }
                .slogan {
                  width: 75%;
                  .text-overflow;
                }
                .date {
                  color: #999999;
                  margin-bottom: 0;
                }
                .title {
                  color: #999999;
                }
                .money {
                  color: @orange;
                }
              }
              .status {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
              }
            }
            a.more, .btn_loadmore {
              display: block;
              margin: 30px auto 10px;
              padding: 10px 20px;
              border: 1px dashed #E9E9E9;
              width: 100px;
              text-align: center;
            }

            .participation {
              .item {
                .status {
                  color: @orange;
                }
              }
            }
          }

          .donate-record {
            table {
              width: 100%;
              font-size: 14px;
              tr {
                color: #333;
                &.table-head{
                  font-weight: bold;
                }
              }

              .item {
                color: #666;
                border-bottom: 1px solid #e9e9e9;
                td {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  padding: 20px 10px 20px 0;
                }
              }
            }

          }
          .member {
            .member-list {
              margin-top: 60px;
              overflow: hidden;
              zoom: 1;
              margin-right: -145px;
              li {
                float: left;
                width: 100px;
                margin-right: 145px;
                padding-bottom: 24px;
                .avatar-box {
                  .avatar(100px);
                  margin-bottom: 20px;
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
                .member-name {
                  color: #333;
                  margin-bottom: 0;
                  font-size: 16px;
                  text-align: center;
                  height: 40px;
                  line-height: 1.2;
                  overflow: hidden;
                  .captain-flag {
                    color: @orange;
                    vertical-align: top;
                  }
                }

              }
            }
          }

        }
      }
      .sidebar {
        width: 300px;
        float: right;
        .stat {

          //margin-top: 30px;

          .stat-panel-title {
            color: #68c258;
            font-size: 18px;
            position: relative;
            border-bottom: 1px solid #e9e9e9;
            box-sizing: border-box;
            line-height: 50px;
            .title{
              height: 50px;
              line-height: 50px;
              border-bottom: 2px solid #68c258;
              display: inline-block;
           }
          }
          .stat-panel-content {
            border: 1px solid #E9E9E9;
            background: #e5fce0;
            color: #666666;
            font-size: 16px;
            padding-left: 22px;
            padding-right: 22px;
            margin-top: 20px;
            .title {
              border-bottom: 1px solid #d1f6c1;
              .number {
                color: #fd7400;
                font-size: 18px;
                line-height: 60px;
                height: 60px;
                font-weight: bold;
              }
              &:last-child {
                border: none;
              }
            }
          }
        }
        .activity-org {
          .stat-panel-title{
            color: #68c258;
            font-size: 18px;
            position: relative;
            border-bottom: 1px solid #e9e9e9;
            box-sizing: border-box;
            line-height: 50px;
            margin-bottom: 25px;
            h3.title{
              height: 50px;
              line-height: 50px;
              border-bottom: 2px solid #68c258;
              display: inline-block;
            }
          }
          a.item {
            position: relative;
            color: #666;
            font-size: 14px;
            display: block;
            overflow: hidden;
            zoom: 1;
            .org-logo {
              .img-wrap(80px, 80px);
              border: 1px solid #E9E9E9;
              float: left;
              margin-right: 50px;
              box-sizing: border-box;
              img{
                max-height: 100%;
              }
            }
            .name {
              line-height: 1.7;
              float: left;
              height: 79px;
              width: 168px;
              border-bottom: 1px dashed #e9e9e9;
              h3 {
                width: 95%;
                margin-bottom: 13px;
              }
              p {
                font-size: 12px;
                color: #999999;
                .show-num {
                  color: @orange;
                  font-weight: bold;
                }
              }
            }
            .to-link {
              position: absolute;
              right: 0px;
              top: 50%;
              transform: translateY(-50%);
            }
          }

        }
      }
    }
  }
}

.sx-form {
  .success-content {
    text-align: center;
    .icon-checked {
      margin-top: 130px;
      margin-bottom: 30px;
    }
    h1 {
      font-size: 24px;
      color: @green;
      font-weight: bold;
    }
    .article-content {
      p {
        color: #666666;
        font-size: 14px;
        max-width: 535px;
        margin: 45px auto 110px auto;
        text-align: center;
        .num {
          color: @green;
        }
        .site {
          color: @orange;
        }
      }
    }

  }
  .form-group {
    &.checkbox-group{
      .wrapper{
        .title{
          float: left;
        }
        .input{
          label{
            display: block;
            &+label{
              margin-top: 10px;
            }
          }
        }
      }
    }
    &.sx-jb-left-reminder{
      .wrapper{
        display: table;
        width: 100%;
        .title{
          float: none;
          line-height: 1.2;
          display: table-cell;
          vertical-align: middle;
          padding-right: 10px;
        }
        .input{
          display: table-cell;
          vertical-align: middle;
        }
        .input{
          input[type=checkbox],input[type=radio]{
            width: 17px;
            height: 17px;
            margin-left: 15px;
          }
          input[type=checkbox]:first-child,input[type=radio]:first-child{
            margin-left: 0;
          }
        }
      }
      .msg{
        margin-left: 115px !important;
      }
    }
    .wrapper {
      .input{
        &.warning{
          .bg('warning',png);
        }
      }
      .radio-box {
        margin-right: 40px;
        display: inline-block;
        input {
          width: 18px;
          height: 18px;
        }

        span {
          padding-left: 7px;
        }
      }
      label {
        input[type=checkbox] {
          width: 17px;
          height: 17px;
          vertical-align: middle;
        }

        .agreement {
          display: inline-block;
          font-size: 14px;
          color: #666666;
          .text-agreement {
            padding-left: 5px;
            color: @orange;
            border-bottom: 1px dashed;
          }
        }
      }
      .projcet-name {
        font-size: 16px;
        color: #333;
        line-height: 45px;
      }
      .show-money {
        font-size: 18px;
        color: @orange;
      }
    }
    .msg {
      padding-bottom: 0;
      .tips-text {
        padding-left: 20px;
      }
    }
  }
  .pb150 {
    padding-bottom: 150px;
  }
  .btn-text-left {
    text-align: left;
    margin-left: 100px;
  }
}

.wrapper.wrapper-circle .ossuploader-add, .wrapper.wrapper-circle .ossuploader-dash-border {
  background: #eeeeee;
  border: none;
  width: 160px;
  display: flex;
  color: @green;
  border-radius: 50%;
  height: 160px;
  cursor: pointer;
  justify-content: center;
  overflow: hidden;
  i {
    display: none;
  }
  p {
    font-size: 14px;
    color: @green;
  }
}

.wrapper.wrapper-square .ossuploader-add, .wrapper.wrapper-square .ossuploader-dash-border {
  background: #eeeeee;
  border: none;
  width: 300px;
  display: flex;
  color: @green;
  height: 210px;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  i {
    display: none;
  }
  p {
    font-size: 14px;
    color: @green;
  }
}

.donation-detail {
  .main-info {
    .mr85 {
      margin-right: 85px;
    }
    .first-msg {
      font-size: 14px;
      color: #999999;
      margin-top: -10px;
      margin-left: 30px;
      margin-bottom: 50px;
    }
    .sub-title {
      font-size: 18px;
      margin-bottom: 20px;
    }
    > .title {
      font-size: 16px;
      .msg {
        display: block;
        color: #333;
        font-size: 16px;
        float: left;
        width: 100%;
        display: block;
        margin-top: 10px;
        a.to-link {
          display: inline-block;
          color: @orange;

        }
      }
    }
  }
}

#chooseSupportItem.popup-wrapper.sx-j-popup {
  min-width: auto;
  max-width: 742px;
  .popup-header {
    padding-top: 25px;
    h3.title {
      span {
        padding-bottom: 25px;
        font-weight: bold;
        border-bottom: none;
      }
    }
  }
  .search-project {
    position: absolute;
    right: 100px;
    top: 25px;
    input[type=text] {
      height: 40px;
      width: 240px;
      float: left;
      border-radius: 0;
    }
    .search_icon {
      margin-left: 4px;
    }
  }
  .popup-info {
    .sx-project-list {
      li {
        margin-bottom: 20px;
        .item {
          overflow: hidden;
          zoom: 1;
          .img-info(120px, 83px);
          .content {
            width: calc(100% - 200px - 20px);
            margin-left: 177px;
            .title {
              margin-bottom: 21px;
            }
            border-bottom: 1px dashed #e9e9e9;
          }
          input[type=checkbox] {
            width: 17px;
            height: 17px;
            float: left;
            margin-right: 20px;
            margin-top: 32px;
          }
        }
      }
    }
    .activity-list {
      ul {
        li {
          position: relative;
          overflow: hidden;
          zoom: 1;
          float: none;
          margin: 0;
          margin-bottom: 20px;
          border: none;
          width: auto;
          input[type=checkbox] {
            width: 17px;
            height: 17px;
            margin-right: 17px;
            float: left;
            margin-top: 35px;
          }

          a {
            float: left;
            .img-info(120px, 83px);
            .content {
              width: calc(100% - 200px - 20px);
              width: 504px;
              padding: 0;
              .title {
                margin-bottom: 21px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap
              }
              border-bottom: 1px dashed #e9e9e9;
            }
          }
        }
        .on {
          &:before {
            content: '';
            .bg(checkbox-icon, png);
            width: 10px;
            height: 8px;
            display: inline-block;
            position: absolute;
            left: 3px;
            top: 39px;
          }

        }
      }
    }
  }
  .icon-close {
    top: 35px;
  }
}

.sx-sign-item {
  height: 100px;
  &:first-child {
    border-top: 1px dashed #E9E9E9;
  }
  position: relative;
  overflow: hidden;
  zoom: 1;
  font-size: 14px;
  padding: 20px 0;
  border-bottom: 1px dashed #e9e9e9;
  input[type=checkbox] {
    position: absolute;
    left: 0;
    top: 50%;
    width: 18px;
    height: 18px;
    transform: translateY(-50%);
  }
  .img {
    float: left;
    .avatar(100px);
    img {
      border-radius: 50%;
    }
  }
  .content {
    float: left;
    padding: 0;
    margin-left: 34px;
    width: 248px;
    display: table;
    .al-center {
      display: table-cell;
      vertical-align: middle;
      .title {
        margin-top: 20px;
        margin-bottom: 20px;
        display: inline-block;
        font-size: 16px;
        color: #333;
      }
      .desc {
        color: #999999;
        font-size: 14px;
      }
    }

  }
  .right-main {
    width: 350px;
    float: right;
    position: relative;
    height: 99px;
    .state {
      color: @orange;
      display: table;
      margin-bottom: 0;
      margin-right: 65px;
      height: 100%;
      div{
        display: table-cell;
        vertical-align: middle;
      }
    }
    .check-state{
      color:#1e81cd;
      float: left;
      line-height: 100px;
      height: 100px;
      margin-bottom: 0;
      margin-right: 65px;
    }
    .right-button {
      margin-top: 5px;
      width: 270px;
      transform: translateY(-50%);
      position: absolute;
      top: 50%;
      right: 0;
      margin-left: -5px;
      text-align: right;
      a {
        line-height: 26px;
        margin-left: 5px;
        height: 26px;
        border-radius: 13px;
        margin-bottom: 10px;
      }
    }
    &.signin-layout{
      width: 393px;
      & > .state{
        line-height: 100px;
      }
    }
  }

}

.sx-sign-item.sign-item-team {
  height: 95px;
  .img {
    border-radius: 0;
    .img-wrap(135px,100%);
    img {
      border-radius: 0;
    }
  }
  .content {
    font-size: 14px;
    .title {
      margin-top: 5px;
      font-size: 16px;
      margin-bottom: 8px;
    }
    .desc {
      margin-bottom: 0;
    }
    .num {
      color: #666666;
      margin-bottom: 12px;
    }
  }
}
.page-help-success{
  .container{
    .msg-content{
      .icon-checked{
        display: block;
        margin: 0 auto 30px;
      }
      .title{
        font-size: 24px;
        color: #68c258;
        margin-bottom: 30px;
        text-align: center;
      }
      .main-info{
        font-size: 14px;
        color: #666666;
        .user-name{
          text-align: left;
        }
        .btn-group{
          margin-top: 30px;
        }
      }


    }
  }

}

.page-team-profile{
  .sx-jb-profile{
    .form-group{
      &.sx-add-group{
        .title{
          width: 105px;
        }
        .input{
          margin-left: 105px;
        }
      }
    }
    .form-desc-list{
      > li {
        .msg{
          i{
            float: none;
          }
        }
      }
    }
  }
  .form-desc-list{
    >li{
      .msg{
        i{
          float: none;
        }
      }
      span.title{
        height: 22px;
        line-height: 22px;
      }
    }
    .sub{
      height: 25px;
      line-height: 25px;
    }
  }

}
.popup-wrapper.share-popup .popup-content .project-content .img{
  &.sx-jb-img{
    width: 90px;
    height: 90px;
  }
}
.popup-wrapper.share-popup .popup-content .project-content .content{
  &.sx-jb-content{
    margin-left: 100px;
    width: calc(100% - 90px - 10px);
  }
}

//修改图片插件框过大的类

.user-center.page-team-profile  .c-upload-img-wrap .ossuploader-dash-border{
  width: 300px;
  height: 210px;
}

.user-center.page-team-manager .team-manager .team-member-list > li .img img{
  width: 100%;
}

//发起活动项目
.support-project .selected-list .selected-activity{
  width: 360px;
  height: 40px;
  line-height: 40px;
}

//清除默认弹窗的样式
.clear-default-popup {
  .popup-wrapper{
    .popup-content{
      margin-top: -30px;
    }
  }
}

//扩展信息的checkbox
.form-group {
  &.checkbox-group {
    .wrapper {
      .title {
        float: none;
      }
      .input {
        label {
          display: block;
          & + label {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

@media print {
  .noprint{
    display: none !important;
  }
}
.page.project-detail{
  .summary .content {
    .follow-area{
      position: absolute;
      right: 0px;
      width: 190px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border: 1px solid #68c258;
      /* margin-top: -45px; */
      border-radius: 5px;
      overflow: hidden;
      bottom: 0;
      padding: 6px;
      height: auto;
      .img{
        width: 70px;
        height: 70px;
        overflow: hidden;
        float: left;
      }
      .desc{
        float: none;
        color: #999;
        margin-left: 78px;
        position: absolute;
        top: 50%;
        margin-top: 0;
        transform: translateY(-50%);
        &.multi-line{
          > p{
            margin-bottom: 5px;
            font-size: 12px;
            &:last-child{
              margin-bottom: 0;
            }
          }
        }
        p{
          font-size: 14px;
        }

      }

    }
    .bottom .btn{
      float: left;
    }
  }

  .item{
    &.item-showy{
      zoom: 1;
      overflow: hidden;
      padding: 20px 0;
      border-bottom: 1px dashed #e9e9e9;
      position: relative;
      .avatar {
        float: left;
        margin-right: 20px;
        .avatar(100px);
        &.team-logo{
          .img-wrap(135px,100px);
          border-radius: 3px;
        }
        &.user-avatar{
          .avatar(68px);
        }

      }

      .name {
        display: table;
        width: 70%;
        &.team-name{
          height: 95px;
        }
        h4 {
          font-size: 16px;
          margin: 0 0 20px 0;
        }
        p {
          margin-bottom: 0;
          color:#666;
          font-size: 14px
        }
        .slogan {
          width: 75%;
          .text-overflow;
        }
        .date {
          color: #999999;
          margin-bottom: 0;
        }
        .title {
          color: #999999;
        }
        .money {
          color: @orange;
        }
      }
      .status {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
#verifyBox{
  .popup-info{
    .top-team-message{
      padding-bottom: 34px;
      border-bottom: 1px solid @border-color;
      .img-box{
        .img-wrap(134px,94px);
        float: left;
        img{
          height: 100%;
        }
      }
      .content{
        margin-left: 160px ;
        h4{
          font-size: 16px;
          color: #333;
          margin-bottom: 12px;
        }
        p{
          color: @gray;
          font-size: 14px;
          width: 565px;
          max-width: 565px;
          margin-bottom: 0;
          .text-overflow();
          .desc{
            .text-overflow();
          }
        }
        .second-item{
          margin-bottom: 12px;
        }
        .desc{
          color: #333;
        }

      }
    }
    .msg-group{
      padding: 25px 0;
      border-bottom: 1px solid @border-color;
      overflow: hidden;
      zoom: 1;
      h3{
        font-weight: bold;
        font-size: 16px;
        color: #333;
        padding-bottom: 10px;
      }
      .form-group{
        margin-bottom: 0;
        font-size: 14px;
        .title{
          color: @light-black;
          width: 70px;
          max-width: 70px;
          height: 38px;
          line-height: 38px;
          text-align: left;
        }
        .input{
          color: #333;
          width: 90%;
          margin-left: 70px;
          max-width: 90%;
          height: 38px;
          line-height: 38px;
          .text-overflow();
        }
        &.more-line{
          width: 50%;
          float: left;
          .input{
            width: 80%;
          }
          &:after{
            content:"";
            zoom: 1;
            display:block;
            clear:both;
          }
        }
      }
      .member-list{
        overflow: hidden;
        zoom: 1;
        li.member{
          float: left;
          margin-right: 35px;
          padding: 5px 0;
        }
      }

    }
    .left-align{
      margin: 12px 0;
    }
    .btn-group{
      .btn-orange{
        background-image: none;
        position: static;
      }
    }
  }
}

.page{
  .code-share-box{
    padding: 0 280px;
    padding-top: 69px;
    padding-bottom: 49px;
      .code-img-box{
        text-align: center;
        img{
          border: 1px solid @border-color;
          padding: 10px;
        }
      }
      .bottom{
        margin-top: 25px;
        text-align: center;
        a{
          text-align: center;
        }
      }
  }
  .share-tips{
    font-size: 14px;
    color:#333;
    margin: 0 146px;
    padding-bottom: 195px;
    .share-link{
      margin-bottom: 0;
    }
  }
}

.popup-wrapper{
  .popup-footer{
    .btn-group{
      .icon-close{
        position: static;
        background-image: none;
      }
    }
    .form-group{
      .msg{
        .desc{
          color: #f61919;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
    .top-border{
      padding-top: 20px;
      margin-left: 25px;
      border-top: 1px solid @border-color;
      margin-right: 45px;
    }

  }
}

.user-tips-showy{
  text-align: center;
  .msg{
    margin-left: 0;
    i.icon-warning{
      left: auto;
      margin-top: 1px;
    }
    span{
      font-size: 13px;
    }
  }
}

.data-pannel{
  .data-pannel-item{
    &:first-child{
      display: inline-block;
    }
    .left{
      color: #fff;
    }
    .right{
      color: #fff;
    }
  }
}
.article-content{
  iframe,object,embed{
    width: 100%;

  }
}
//限制证书的宽度
//.popup-content.credential{
//  .credential-img{
//    max-width: 100%;
//    max-height:500px;
//  }
//}
.sidebar .accept-org a .img img{
  max-height: 100%;
}

.line-group{
  margin-bottom: 45px;
  p.field-name{
    margin-bottom: 20px;
  }
}
.user-center .user-content > .title-under-gray-line{
  .fr{
    position: relative;
    .vertical-center{
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      > a{
        margin-bottom: 0; 
      }
    }
  }
}

.loving-heart-box{
  a.item{
    padding: 20px 0;
    overflow: hidden;
    zoom: 1;
    display: inline-block;

    .img-box{
      float: left;
      .img-wrap(223px,145px);
    }
    .right-content{
      margin-left: 280px;
      width: 460px;
      border-bottom: 1px solid @border-color;
      height: 145px;
      .title{
        width: 90%;
        font-size: 14px;
        color: #666666;
        margin-bottom: 70px;
        .multi-text-overflow(21px,2);
      }
      .date{
        font-size: 12px;
        color: #999999;
      }
    }
  }
}