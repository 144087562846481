.sygy-page{
  margin-top: 123px;
  width: 100%;
  .container{
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-grow: 0;
    box-sizing: border-box;
    padding: 40px 100px;
    background-image: url('@{img_dir}/pc/green-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: #fff;
    .box {
      .title{
        text-align: center;
        font-size: 40px;
        margin-bottom: 10px;
      }
      .p {
        letter-spacing: 8px;
        font-size: 20px;
        margin-bottom: 30px;
      }
      .qr-code-container-wish{
        width: 225px;
        margin: 0 auto;
        text-align: center;
        box-sizing: border-box;
        .qr-code-wish{
          width: 100%;
          margin-bottom: 10px;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .decorate-img {
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      img {
        height: 100%;
        width: auto;
      }
    }
  }
}
.user-center{
  .donate_record{
    .qr-code-container{
      margin: 0 auto;
      width: 300px;
      height: 100%;
      text-align: center;
      font-size: 18px;
      .qr-code{
        min-height: 300px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.user-center{
  .user-content{
    .title-under-gray-line{
      .no-under-line{
        border-bottom:none;
      }
    }
  }
}

.page-support-project-list .support-project-list .item{
  height: auto;
}



.qr-code-wrap .qr-code .qr-code-content.fix{
  max-width: 120px;
}