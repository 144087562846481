


//全局设置
html {
  height: 100%;
}

body {
  height: 100%;
  position: relative;
  background-color: #fff;
}

.body {
  height: auto;
  min-height: 100%;
  background-color: #fff;
}

.body.body-user-center {
  background-color: #f2f3f5;
}

.footer {
  position: relative;
  z-index: 10;
  margin-top: -100px;
  height: 100px;
  box-sizing: border-box;
}
//全局设置结束

//文字样式
.text-white {
  color: #fff !important;
}

.text-red {
  color: @red !important;
}

.text-blue {
  color: @blue !important;
}
.text-orange{
  color: @orange !important;
  vertical-align: top;
}
.other-text-orange{
  color: @orange !important;
  vertical-align: top;
  line-height: inherit;
}

.text-jb-gray{
  color:#666666 ;
}

//文字样式结束

//背景样式
//纯色
.bg-transparent {
  background-color: transparent !important;
}
.bg-form{
  background: #f2f3f5 !important;
}
.bg-white {
  background-color: #fff !important;
}

.bg-grey{
  background-color: @bg-gray !important;
}

//背景图
.bg(@imgUrl,@imgType: jpg,@bgPos: center center,@bgSize: cover) {
  background: url('@{img_dir}@{imgUrl}.@{imgType}') no-repeat @bgPos;
  background-size: @bgSize;
}

//背景样式结束

.cursor-pointer{
  cursor: pointer;
}

//标题->描述
.title-desc-list {
  li {
    overflow: hidden;
    .title {
      color: @gray;
    }
    .desc {
      color: @light-black;
    }
    & + li {
      margin-top: 10px;
    }
    span {
      float: left;
    }
  }
  &.inline{
    li{
      float: left;
      margin-top: 0;
      span {
        float: none;
        display: inline;
      }
    }
  }
  &.list-stat{
    font-size: 14px;
    overflow: hidden;
    li{
      .desc{
        color: @red;
      }
      &:first-child{
        float: left;
      }
      &:last-child{
        float: right;
      }
      & + li{
        margin-top: 0;
      }
    }
  }
}

.title-desc-item{
  font-size: 0;
  .title,.desc{
    vertical-align: middle;
    display: inline-block;
  }
  span,div{
    font-size: 14px;
  }
  .title{
    text-align: right;
    width: 130px;
    color: @light-black;
  }
  .desc{
    color: @black;
  }
  & + .title-desc-item{
    margin-top: 20px;
  }
  &.title-left-align{
    .title{
      width: 105px;
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.5;
}

.font-italic,.fsi, i, em {
  font-style: italic;
}

strong, .fwb, b {
  font-weight: 700;
}

.fade-out {
  opacity: 0 !important;
}

.fade-in {
  opacity: 1 !important;
}

// 图标 列表
.icon-desc{
  font-size: 14px;
  color: @light-black;
  .icon{
    float: left;
    font-size: 0;
    width: 25px;
    margin-top: 2px;
    i{
      vertical-align: top;
    }
  }
  .desc{
    margin-left: 25px;
    line-height: 1.5;
  }

  & + &{
    margin-top: 20px;
  }
}

// 图标 列表
.icon-list {
  font-size: 12px;
  color: @gray;
  display: flex;
  justify-content: space-between;
  li {
    display: flex;
    flex-grow: 1;
    align-items: center;
    i {
      margin-right: 5px;
    }
  }
}

//3列图片
.img-list {
  margin-left: -20px;
  margin-bottom: -10px;
  overflow: hidden;
  li {
    margin-bottom: 10px;
    float: left;
    cursor: pointer;
    width: calc(~"(100% - 60px) / 3");
    border-radius: 4px;
    overflow: hidden;
    margin-left: 20px;
    a {
      display: block;
      height: 100%;
    }
  }
}

//循环生成 .img-list2,.img-list3
.img-list(5);

.img-list(@n, @i: 2) when (@i =< @n) {
  @margin-left: 20px;
  .img-list@{i} {
    overflow: hidden;
    margin-left: -@margin-left;
    margin-bottom: -10px;
    li {
      margin-bottom: 10px;
      margin-left: @margin-left;
      float: left;
      width : calc(~"(100% - @{i} * @{margin-left}) / @{i}");
      img{
        border-radius: 5px;
      }
    }
  }
  .img-list(@n, (@i + 1));
}

.icon-wrap {
   font-size: 14px;
   color: @gray;

   i {
     margin-right: 10px;
   }
 }




a:focus, button:focus, input:focus, select:focus, option:focus {
  outline: none;
}

input, textarea {
  box-sizing: border-box;
}

.text-nowrap {
  white-space: nowrap;
}

i {
  display: inline-block;
  vertical-align: middle;
  outline: none;
}

img {
  vertical-align: middle;
  //pointer-events: none; //禁止微信点击图片放大
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right !important;
}

span {
  display: inline-block;
  outline: none;
  vertical-align: middle;
}

canvas {
  vertical-align: middle;
}

.hidden {
  display: none !important;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.margin-center {
  margin: 0 auto;
}

.fl {
  float: left !important;
}

.fr {
  float: right !important;
}

.clearfix {
  clear: both;
}

//标题类
//左边带边框的标题
.title-left-deco {
  font-size: 24px;
  color: @green;
  line-height: 1.2;
  padding-left: 5px;
  border-left: 3px solid @green;
}

//带下划线的标题
.title-under-line {
	height: 50px;
	line-height: 50px;
	margin-bottom: 20px;
	padding: 0;
	border-bottom: 1px solid #ededed;
	h3{
		font-size: 18px;
		height: 50px;
		line-height: 50px;
		box-sizing: border-box;
		vertical-align: middle;
		color: #68c258;
		display: inline-block;
		border-bottom: 2px solid #68c258;
	}
	.more{
      float: right;
      font-size: 14px;
      color: @green;
	}
    .text-gray{
      font-size: 16px;
    }
}

.title-under-gray-line{
	border-bottom: 1px solid #e5e5e5;
    overflow: hidden;
	.title{
      display: inline-block;
		font-size: 18px;
		color: @green;
		padding: 10px 0;
		box-sizing: border-box;
		border-bottom: 3px solid #68c258;
		margin-bottom: -2px;
        &.off{
          border-bottom: 0;
          color: @black;
        }
	}
    .more{
      float: right;
      font-size: 14px;
      color: @green;
      line-height: 50px;
      height: 50px;
    }
}

.title-under-green-line{
  border-bottom: 1px solid @green;
  overflow: hidden;
  margin-bottom: 30px;
  @height: 76px;
  @line-height: 81px;
  height: @height;
  line-height: @line-height;
  >.title{
    font-size: 22px;
    color: @green;
    float: left;
    height: @height;
    line-height: @line-height;
  }
  >.more{
    float: right;
    font-size: 16px;
    color: @gray;
  }
  >.desc{
    float: left;
    font-size: 16px;
    color: @gray;
  }
}


//绿色背景白色字标题
.title-with-bg-green {
  background-color: @green;
  > .title {
    font-size: 20px;
    .text-white;
    text-align: center;
    height: 50px;
    line-height: 50px;
  }
}


//快速展示  左边图片  右边文字
//头像-> 内容
.headimg-info(@width: 50px,@margin-left: 20px){
  >.img{
    float: left;
    .avatar(@width);
    box-sizing: border-box;
  }
  >.content{
    margin-left: (@width + @margin-left);
    min-height: @width;//这里的宽也就是头像的高
    display: table;
    width:calc(~"100% - @{width} - @{margin-left}");
    position: relative;
    margin-top: 0;
    height: @width;
    >.title{
      color: @light-black;
      font-size: 16px;
    }
    >.desc{
      color: @gray;
      font-size: 14px;
    }
  }
  .v-align{
    display: table-cell;
    vertical-align: middle;
  }
}

.img-info(@width: 50px,@height: 80px,@margin-left: 20px){
  .img{
    float: left;
    .img-wrap(@width,@height);
  }
  .content{
    margin-left: (@width + @margin-left);
    min-height: @height;
    height: @height;
    position: relative;
    display: table;
    width: calc(~"100% - @{width} - @{margin-left}");
    >.title{
      color: @light-black;
      font-size: 16px;
    }
    >.desc{
      color: @gray;
      font-size: 14px;
    }
  }
  .v-align{
    display: table-cell;
    vertical-align: middle;
  }
}


.dis-table-cell{
  display: table-cell;
  vertical-align: middle;
}
//快速展示  左边图片  右边文字结束

//两边带有横线的标题
.title-line-deco {
  color: #fff;
  .text-center;
  font-size: 0;
  &:after, &:before {
    content: '';
    display: inline-block;
    height: 1px;
    width: .5px;
    background-color: #fff;
    vertical-align: middle;
  }
  span {
    font-size: .3px;
    vertical-align: middle;
    padding: 0 .15px;
  }
}

//标题类结束

.icon-msg {
  overflow: hidden;
  position: relative;
  i {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .desc {
    padding-left: 20px;
    color: @gray;
    font-size: 12px;
    line-height: 1.2;
  }
}

.unit{
  font-size: 14px;
  color: @black;
  .abs-right-center;
  right: 15px;
}

.unit-wrapper{
  position: relative;
}

//表单类
.form-group,.form-control{
	margin-bottom: 20px;
    overflow: hidden;
    .wrapper{
		overflow: hidden;
	}
  .title,.field-name{
    height: 45px;
    line-height: 45px;
    text-align: right;
    width: 130px;
    font-size: 14px;
    float: left;
    .text-orange{
      padding-right: 3px;
    }
    .red{
      color: #fd7400;
    }
    &.top{
      line-height: 1.5;
      height: auto;
    }
  }
  &.checkbox-group{
    line-height: 1.5;
    height: auto;
    .title,.input,.field-name,.form-field{
      line-height: 1.5;
      height: auto;
    }
    .input,.form-field{
      margin-right: -15px;
    }
    label{
      margin-right: 15px;
      display: inline-block;
      cursor: pointer;
      line-height: 1.5;
    }
    span{
      margin-left: 3px;
      font-size: 14px;
      color: @black;
      vertical-align: middle;
    }
  }
  .input,.form-field{
    position: relative;
    margin-left: 140px;
    .tips{
      margin-bottom: 0;
      line-height: 45px;
    }
    input[type=text],textarea,select{
	  width: 100%;
	  box-sizing: border-box;
      border-radius: 4px;
	}
	.input-select-box{
		height: 45px;
		line-height: 45px;
        label{
          & + label{
            margin-left: 55px;
          }
        }
		input[type=checkbox],input[type=radio]{
			margin-right: 3px;
            width: 20px;
            height: 20px;
		}
	}
    textarea{
      height: 120px;
      border-radius: 4px;
    }
    .unit{

    }
  }
  .text{
    padding-top: 10px;
    line-height: 1.8;
  }
  .img{
      width: 200px;
  }
  .msg,.checkbox-msg{
    margin-left: 140px;
    margin-top: 5px;
    position: relative;
  }
  .msg{
      .icon-msg;
    .tips-text{
      padding-left: 20px;
    }
  }
  .arrd-select-wrap{
      margin-bottom: 5px;
  }
  .checkbox-msg{
    input[type="checkbox"]{
      width: 16px;
      height: 16px;
    }
    .desc{
      font-size: 14px;
      color: @light-black;
      line-height: 1.5;
    }
  }

  //两个一起的时间
  &.select-time{
    >.input{
      .form-group{
        @width: 75px;
        margin-bottom: 0;
        float: left;
        .title{
          width: @width;
          text-align: left;
          white-space: nowrap;
        }
        .input{
          margin-left: @width;
        }
        & + .form-group{
          margin-left: 45px;
        }
      }
    }
  }



  //选择框
  &.checkbox-select{
    @line-height: 20px;
    .title{
      height: @line-height;
      line-height: @line-height;
    }
    .input{
      height: @line-height;
      line-height: @line-height;
      label{
        display: inline-block;
        vertical-align: top;
        height: @line-height;
        line-height: @line-height;
        input[type='radio'],input[type='checkbox']{
          width: 20px;
          height: 20px;
        }
        span,input[type='radio'],input[type='checkbox']{
          vertical-align: top;
        }
        span{
          height: @line-height;
          line-height: @line-height;
          margin-left: 10px;
        }
        & + label{
          margin-left: 45px;
        }
      }
    }
    .msg{
    }
  }
}

//快速方形椭圆
.square-radius{
  border-radius: 4px !important;
}
.radius-square {
  border-radius: 4px !important;
}

.no-radius{
  border-radius: 0 !important;
}

//.form-control{
//  margin-bottom: 20px;
//  overflow: hidden;
//  .field-name{
//    height: 45px;
//    line-height: 45px;
//    text-align: right;
//    width: 130px;
//    font-size: 14px;
//    float: left;
//    margin-bottom: 0;
//  }
//  .form-field{
//    margin-left: 130px;
//  }
//}

//表单类结束

//设置圆形图片
.avatar(@size) {
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  width: @size;
  height: @size;
  line-height: @size;
  img {
    border-radius: 50%;
  }
}

.inline-block{
  display: inline-block;
}

//图片超出裁剪
.img-wrap(@width,@height: auto, @radius: 0) {
  width: @width;
  height: @height;
  overflow: hidden;
  text-align: center;
  line-height: @height;
  border-radius: @radius;
  font-size: 0;
  img {
    border-radius: @radius;
  }
}

@-webkit-keyframes progress-active {
  from {
    opacity: 0.5;
    -webkit-transform: translate(-100%);
            transform: translate(-100%);
  }
  to {
    opacity: 0.1;
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
@keyframes progress-active {
  from {
    opacity: 0.5;
    -webkit-transform: translate(-100%);
            transform: translate(-100%);
  }
  to {
    opacity: 0.1;
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
.progress {
  height: 14px;
  overflow: hidden;
  position: relative;
  .progress-wraper {
    border-radius: 25px;
    overflow: hidden;
    background-color: #ededed;
    height: 8px;
    margin-top: 3px;
    margin-right: 40px;
    .progress-inner {
      height: 8px;
      border-radius: 25px;
      background-color: @green;
      position: relative;
      &.active::before{
          content: "";
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #FFF;
          border-radius: 25px;
          animation: progress-active 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
      }
    }
  }
  .count {
    text-align: right;
    line-height: 1.2;
    width: 40px;
    position: absolute;
    right: 0;
    top: 0;
    color: @orange;
    font-size: 14px;
  }
}

.rotation-animate {
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

//移动端加载更多
.load-more {
  font-size: 12px;
  color: #f5f5f5;
  text-align: center;
  background-color: #fff;
  padding-top: .45px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-left: .1px;
  }
  &.active {
    i {
      .rotation-animate;
    }
  }
}

.visibility-hidden {
  visibility: hidden;
}

.unset-filter {
  filter: none;
}

.gray-filter {
  filter: grayscale(100%);
}

.circle {
  border-radius: 50%;
}

.radius {
  border-radius: 100px;
}



.over-hidden {
  overflow: hidden;
}

.img-radius-square {
  .radius-square;
  .over-hidden;
}

.icon-close-mask {
  cursor: pointer;
}

.animate {
  transition: all .3s;
}

.w-100 {
  width: 100% !important;
}

.mw0 {
  min-width: 0;
}
.plr20{
  padding: 0 20px 0 20px !important;
}
.pb30 {
  padding-bottom: 30px !important;
}

.pt30 {
  padding-top: 30px !important;
}
.pt0{
  padding-top: 0px !important;
}
.pt20 {
  padding-top: 20px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.mb40{
  margin-bottom: 40px !important;
}

.ml-40 {
  margin-left: -40px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt40{
  margin-top: 40px !important;
}
.mt50{
  margin-top: 50px !important;
}
.mb30 {
  margin-bottom: 30px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.ml5{
  margin-left: 5px;
}

.mt5{
  margin-top: 5px;
}

.padding30 {
  padding: 30px;
}

.clear-margin {
  margin: 0 !important;
}

.clear-padding {
  padding: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.line-height1{
  line-height: 1.2;
}

.tips-link{
	border-bottom: 1px dashed @green;
	padding-bottom: 2px;
	display: inline-block;
}
//提示信息页面
.msg-page{
    position: relative;
    .container{
        background-color: #fff;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 100px;
        .msg-content{
            width: 550px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            min-height: 338px;
            .img-status{
                display: block;
                margin: 0 auto 30px;
            }
            h1{
                font-size: 24px;
                color: @green;
                margin-bottom: 30px;
                text-align: center;
            }
            .content{
                font-size: 14px;
                color: @light-black;
                line-height: 1.8;
            }
            .operation{
                position: absolute;
                bottom: 30px;
                width: 100%;
                text-align: center;
                .btn{
                    width: 140px;
                    margin: 0 20px;
                }
            }
        }
    }
}

.border-bottom{
  border-bottom: 1px solid @border;
}

.cursor-default{
  cursor: default;
}

.border-box{
	box-sizing: border-box;
}
.btn-fixed-button{
  position: absolute;
  bottom: 10px;
  right: 45%;
}
//显眼的颜色
.showy-cost-red{
  color:#e24c37 !important;
  font-weight: bold;
}
//进入项目链接
.to-project-link{
  color: #1e81cd;
  font-size: 14px;
  cursor: pointer;
  &:hover{
    color:#1e81cd;
    border-bottom: 1px dashed #1e81cd;
  }
  &:active{
    color:#1e81cd;
  }
  &:visited{
    color:#1e81cd;
  }
}

.ml10 {
  margin-left: 10px !important;
}
.qr-code-wrapper{
  width: 175px;
  height: 80px;
  box-sizing: border-box;
  border: 1px solid @green;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 5px 0 5px;
  .img{
    .img-wrap(60px, 60px);
    margin-right: 10px;
  }
  >.tips {
    height: 100%;
    display: flex;
    align-items: center;
    color: @gray;
    font-size: 14px;
    line-height: 1.5;
  }
}

.btn-set(@width, @height){
  width: @width;
  height: @height;
  line-height: @height;
}

.red-point-mark{
  position: relative;
  &:after{
    content: '';
    position: absolute;
    right: -7px;
    top: 2px;
    background-color: @red;
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }
}
.number-left-list(@mr, @number, @mt:30px) {
  margin-top: -@mr;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .item {
    margin-top: @mt;
    width: calc((100% - @mr * (@number - 1)) / @number);
    margin-right: @mr;
    &:nth-child(@{number}n) {
        margin-right: 0;
    }
  }
}

.text-cut {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-cut-2 {
	overflow : hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.text-cut-3 {
	overflow : hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
.text-cut-8{
	overflow : hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp:8;
	-webkit-box-orient: vertical;
}