// 慈善资讯
.pc-zixun-page {
    background-color: #fff;

    .list-content {
        padding-top: 70px;
        margin: 0 auto;
        text-align: left;

        .module-title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: 45px;

            .topic-title {
                margin-bottom: 0px;
            }

            a {
                font-size: 18px;
                line-height: 18px;
                letter-spacing: 0px;
                color: @green;
            }
        }

        .list {
            .item {
                width: 100%;
                border: solid 1px #cccccc;
                .img-box {
                    width: 100%;
                    aspect-ratio:224 / 115;
                    // height: 100%;
                    max-height: 230px;
                    position: relative;
                    &::after {
                        // position: absolute;
                        content: '';
                        display: block;
                        width: 100%;
                        padding-top: 51.34%;
                    }
                    > img {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
                &:hover {
                    box-shadow: 0px 1px 21px 0px rgba(0, 0, 0, 0.15);
                    
                }
                
            }
        }
    }

    .zixun-main {
        width: 100%;
        background-color: #fff;

        .module_content {
            background-color: #fff;
            padding-bottom: 60px;

            &:nth-child(2n) {
                background-color: @bg-gray;
            }


            .zixun_common_box {
                width: 100%;
                height: 382px;
                display: flex;
                justify-content: space-between;
                .left-box {
                    display: block;
                    width: 632px;
                    height: 382px;
                    border-radius: 20px;
                    overflow: hidden;
                }

                .img-box {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;

                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    .mask_box {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 69px;
                        padding-left: 28px;
                        background-color: @green;
                        opacity: 0.9;
                        font-size: 22px;
                        line-height: 69px; //33px
                        color: #ffffff;
                        box-sizing: border-box;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .right_list {
                    width: 684px;
                    height: 100%;
                    margin-left: 74px;

                    .item {
                        display: block;
                        height: 51px;
                        border-bottom: 1px dashed #D9DADC;
                        padding-bottom: 26px;
                        margin-bottom: 27px;
                        transition: all 0.3s;

                        &:nth-child(4) {
                            border: none;
                            padding-bottom: 0px;
                            margin-bottom: 0px;
                        }

                        .title {
                            height: 33px;
                            font-size: 22px;
                            line-height: 33px;
                            color: #222222;
                            margin-bottom: 11px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .date {
                            font-size: 18px;
                            line-height: 18px;
                            color: #666666;
                        }
                        &:hover {
                            .title {
                                color: @green;
                            }
                        }
                    }

                }
            }

        }


    }
}

.pc-zixunDetail-page {
    background-color: @bg-gray;
    padding-top: 165px;

    .zixunDetail_main {
        width: 100%;
        padding: 38px 60px 80px;
        background-color: #ffffff;
        box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.1);
        border-radius: 30px;

        .topic-title {
            font-size: 30px;
            color: #222;
            font-weight: bold;
            display: inline-block;
            position: relative;
            line-height: 1;
            margin-bottom: 30px;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 8px;
                background: #68C258;
                z-index: 1;
            }

            span {
                position: relative;
                z-index: 2;
            }
        }

        .news-list {
            margin-bottom: 20px;
            .item {
                margin-top: 29px;
                .img-info(411px, 248px, 70px);
                display: block;
                overflow: hidden;

                .img {
                    border-radius: 22px;
                }

                .content {
                    border-bottom: 1px dashed @border;
                    box-sizing: border-box;
                    padding-top: 50px;

                    .title {
                        font-size: 24px;
                        color: @black;
                        margin-bottom: 30px;
                        line-height: 1.2;
                    }

                    .time {
                        font-size: 18px;
                        line-height: 1.2;
                        color: @gray;
                        margin-bottom: 30px;
                    }

                    .desc {
                        font-size: 16px;
                        color: @light-black;
                        margin-bottom: 0;
                    }
                }

                &:hover {
                    .content {
                        .title {
                            color: @green;
                        }
                    }
                }
            }
        }

        .paging_box {
            width: 100%;
            display: flex;
            justify-content: center;
        }

    }

}