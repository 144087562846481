@import 'var.less';
@import 'tool.less';
.user-center{
    background-color: #f2f3f5;
    padding: 165px 0 150px;
    zoom: 1;
    overflow: hidden;
    .operation{
        overflow: hidden;
        margin-left: -10px;
        margin-bottom: -5px;

        .btn{
            margin-bottom: 5px;
            margin-left: 10px;
            width: 110px;
            height: 28px;
            line-height: 28px;
            float: left;
        }
    }
    .user-menu{
        float: left;
        width: 210px;
        background-color: #fff;
        margin-right: 20px;
        .menu{
            margin: 20px 0;
            .menu-item{
                display: block;
                height: 40px;
                line-height: 40px;
                margin-bottom: 10px;
                padding-left: 30px;
                font-size: 16px;
                color: @light-black;
                border-left: 3px solid transparent;
                &.active{
                    background-color: #e4ecd8;
                    border-left: 3px solid @green;
                }
            }
        }
    }
    .user-content{
        float: right;
        width: 850px;
        background-color: #fff;
        padding: 0 20px 20px 20px;
        box-sizing: border-box;
        .user-profile{
            .over-hidden;
            padding-bottom: 20px;
            border-bottom: 1px solid @green;
            margin-top: 20px;
            margin-bottom: 15px;
            .headimg{
                float: left;
                margin-right: 20px;
                img{
                    .avatar(70px);
                }
            }
            .org-img{
                margin-right: 20px;
                float: left;
                .img-wrap(90px,90px);
            }
            .user-info{
                float: left;
                .info-title{
                    font-size: 18px;
                    color: @light-black;
                    margin-bottom: 15px;
                }
                .stats_list{
                    .stats_list_item{
                        display: inline-block;
                        font-size: 14px;
                        color: @gray;
                        margin-right: 50px;
                    }
                    span{
                        display: inline-block;
                        vertical-align: middle;
                        font-size: 16px;
                        .text-red;
                    }
                }
            }
        }
    }
    .summary-list{
        margin-right: -30px;
        .summary-item{
            padding: 0;
            border: 1px solid #f5f5f5;
            border-radius: 5px;
            box-shadow: 2px 2px 15px #e5e5e5;
            width: 250px;
            margin-right: 30px;
            margin-bottom: 15px;
            a{
                display: block;
                padding: 30px 25px;
            }
            p{
                margin-bottom: 0;
                line-height: 1;
            }
            .summary-item-title{
                color: @black;
                font-size: 16px;
                margin-bottom: 15px;
            }
            .summary-desc{
                font-size: 14px;
                color: @light-black;
                span{
                    font-size: 24px;
                    .text-red;
                    vertical-align: text-bottom;
                    margin-right: 5px;
                }
            }
        }
    }
}

.user-auth{
    .form-group{
        margin-bottom: 0;
    }
}

.select-auth-role{
    p.desc{
        margin-top: 30px;
    }
    .role-list{
        margin-top: 30px;
        margin-bottom: 100px;
        .item{
            display: inline-block;
            border: 1px solid #e5e5e5;
            border-radius: 5px;
            padding: 20px 5px;
            width: 200px;
            height: 120px;
            box-sizing: border-box;
            margin-right: 30px;
            h2{
                text-align: center;
                font-size: 18px;
                margin-bottom: 15px;
                &:after{
                    content: '';
                    width: 40px;
                    height: 1px;
                    border-top: 1px solid #e5e5e5;
                    margin: 10px auto 0;
                    display: block;
                }
            }
            p{
                text-align: center;
                font-size: 14px;
                color: @gray;
            }
        }
    }
}

.donate_record{
    .record_item{
        .over-hidden;
        padding: 20px 0;
        border-bottom: 1px solid @border;
        img{
            float: left;
            width: 120px;
            height: 71px;
            margin-right: 20px;
        }
        .record_desc{
            float: left;
            width: 360px;
            margin-right: 50px;
            color: @black;
            .donate-slogan{
                font-size: 16px;
                color: @black;
                margin-bottom: 15px;
            }
            .user_name{
                font-size: 14px;
                margin-bottom: 0;
                span{
                    color: @black;
                }
                
            }
            .create_time{
                font-size: 12px;
            }
            .donate_stat{
                display: inline-block;
                margin-right: 50px;
                margin-bottom: 0;
                span{
                    .text-red;
                }
            }
        }
        .donate_count{
            float: left;
            font-size: 14px;
            .text-red;
            margin-bottom: 0;
            margin-top: 8px;
        }
        .btn{
            float: right;
            width: 100px;
            height: 25px;
            line-height: 25px;
            margin-top: 8px;
        }
    }
}

.user-help-list{
    margin-bottom: 30px;
    .item{
        padding: 20px 0;
        border-bottom: 1px solid @border;
        .title{
            .over-hidden;
            margin-bottom: 10px;
            .set-top{
                font-size: 16px;
                color: @gray;
            }
            a{
                float: left;
                font-size: 16px;
                color: @green;
                cursor: pointer;
            }
            .tag{
                float: right;
                margin-left: 5px;
            }
        }
        .progress{
            margin-bottom: 10px;
            width: 48%;
            float: left;
        }
        .bottom{
            .over-hidden;
        }
        .stat{
            float: left;
            clear: left;
            p{
                display: inline-block;
                margin-right: 50px;
                margin-bottom: 0;
                color: @gray;
                span{
                    .text-red;
                }
            }
        }
        .meta{
            float: left;
            clear: left;
            p{
                margin-bottom: 0;
                color: @gray;
                span{
                    .text-red;
                }
            }
        }
        .operation{
            float: right;
            max-width: 50%;
        }

    }
}

.user-project-list{
    margin-bottom: 30px;
    .item{
        padding: 20px 0;
        .title{
            .over-hidden;
            margin-bottom: 10px;
            .left{
                width: 400px;
                font-size: 0;
                float: left;
                height: 40px;
                &.hasCalcWidth {
                    width: calc(100% - 170px);
                }
                .set-top{
                    font-size: 16px;
                    color: @gray;
                }
                a{
                    vertical-align: middle;
                    display: inline;
                    font-size: 16px;
                    cursor: pointer;
                    color: @black;
                    margin-right: 15px;
                    &:hover{
                        color: @green;
                    }
                }
            }

            .status-show-wrapper{
                float: right;
            }
            .tag{
                float: right;
                margin-left: 5px;
                cursor: pointer;
            }
        }
        .progress{
            margin-bottom: 10px;
            width: 48%;
            float: left;
        }
        .bottom{
            .over-hidden;
            .left-content{
                .over-hidden;
                float: left;
                width: 50%;
                .progress{
                    width: 100%;
                }
            }
        }
        .stat{
            clear: left;
            float: left;
            p{
                display: inline-block;
                margin-right: 50px;
                margin-bottom: 0;
                color: @gray;
                span{
                    .text-red;
                }
            }
        }
        .meta{
            float: left;
            clear: left;
            p{
                margin-bottom: 0;
                color: @gray;
                span{
                    .text-red;
                }
            }
        }
        .operation{
            float: right;
            max-width: 45%;
        }

    }
}

.user-center-form{
    width: 620px;
    margin-top: 30px;
}

.user-update-list-table {
    margin: 20px 0;
    width: 100%;
    .thead {
        border-bottom: 1px #eee solid;
        th {
            padding: 10px;
            font-weight: bold;
            text-align: left
        }
    }
    td {
        padding: 10px;
        vertical-align: middle;
    }
    .help-btn {
        padding: 0 5px;
        margin-right: 5px
    }
}

.weixin-grid-list{
    overflow: hidden;
    margin-right: -20px;
    .item{
        width: 160px;
        height: 190px;
        padding: 20px;
        background-color: #fff;
        float: left;
        margin-right: 20px;
        margin-bottom: 20px;
        text-align: center;
        border: 1px dashed #cecece;
        .img-wrap{
            width: 120px;
            overflow: hidden;
            height: 120px;
            margin: 0 auto;
        }
        h2{
            margin-top: 20px;
        }
        .operation{
            margin-top: 10px;
            .btn{
                width: auto;
                padding-left: 5px;
                padding-right: 5px;
            }
        }
    }
}

.percent-width {
    .user-center-form {
        width: 100%;
    }
}