
.bar{
  border-bottom: 5px solid @border;
}
.addr-select{
  border-radius: 4px;
}
.arrd-select-wrapper,.select-group{
  overflow: hidden;
  select{
    width:calc(~"(100% - 40px) / 3") !important;
    float: left;
    border-radius: 4px;
    & + select{
      margin-left: 20px;
    }
  }
}

label{
  cursor: pointer;
}

.col2 {
  overflow: hidden;
  zoom: 1;
  > .left {
    float: left;
  }
  > .right {
    float: right;
  }
}

.text-green{
  color: @green !important;
}

.text-orange{
  color: @orange !important;
}

.text-gray{
  color: @gray !important;
}

.text-black{
  color: @black !important;
}

.text-light-black{
  color: @light-black !important;
}

.pointer{
  cursor: pointer;
}

.abs-center{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

.abs-left-center{
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transform-origin: 50% 51%;
}

.abs-right-center{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transform-origin: 50% 51%;
  right: 0;
}

.main{
  margin-bottom: 40px;
}


header {
  box-shadow: 0 5px 16px 0 rgba(91, 187, 74, 0.1);
  margin-bottom: 30px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: transform .5s;
  .header {
    position: relative;
    .user-area{
      position: relative;
      &::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(255,255,255,0.7);
        backdrop-filter: blur(10px);
        z-index: -1;
        transition: all .3s;
      }
      &.active{
        background: rgba(255,255,255,1);
      }
      height: 30px;
      line-height: 30px;
    }
    .nav-area{
      background: rgba(255,255,255,0.1);
      backdrop-filter: blur(10px);
      .container{
        height: 85px;
        position: relative;
      }
    }
    .welcome{
      display: inline-block;
      color: @green;
    }
    //登陆区域
    .login-area{
      // height: 21px;
      // overflow: hidden;
      // text-align: right;
      float: right;
      line-height: 30px;
      height: 30px;
      color: @gray;
      // position: absolute;
      // right: 0;
      // top: 15px;
      a{
        color: @gray;
        &:hover{
          color: @green;
        }
      }
      &:hover{
        .no-login {
          height: 0;
        }
        .login-type{
          height: 21px;
        }
      }
      .no-login{
      }
      .login-type{
        height: 0;
      }
      .no-login,.login-type{
        overflow: hidden;
        transition: all .3s;
      }
    }
    //用户登录后区域
    .user-nav{
      // position: absolute;
      // right: 0;
      // top: 15px;
      // z-index: 2;
      float: right;
      height: 30px;
      position: relative;
      z-index: 2;
      font-size: 14px;
      display: flex;
      .msg-box{
        position: relative;
        margin-right: 26px;
        cursor: pointer;
        .bell{
          width: 16px;
        }
        .num{
          min-width: 16px;
          height: 16px;
          border-radius: 50%;
          position: absolute;
          background: #F61919;
          color: #fff;
          font-size: 12px;
          line-height: 1.5;
          text-align: center;
          top: 0px;
          right: -12px;
          border: 2px solid #fff;
        }
      }
      .account-name{
        margin-top: 7px;
        color: @green;
        line-height: 1.2;
        cursor: pointer;
        .name{
          margin-right: 5px;
        }
        .name,i{
        }
      }
      ul{
        position: absolute;
        top: 30px;
        left: 50%;
        width: 80px;
        margin-left: -40px;
        box-sizing: border-box;
        display: none;
        border: 1px solid #e5e5e5;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 0 5px 0 #ccc;
        text-align: center;
        background-color: #fff;
        li{
          a{
            padding: 2px 5px;
            display: block;
            height: 30px;
            line-height: 30px;
            &:hover{
              color: #fff;
              background-color: @green;
            }
          }
          &+li{
            border-top: 1px solid #ccc;
          }
        }
      }
    }
    //logo
    .logo-wrap {
      .abs-left-center;
      a{
        .logo-img{
          height: 56px;
        }
      }
    }
  }
}

//顶部导航区域
.index-nav {
  font-size: 16px;
  position: absolute;
  right: 0;
  top: 35px;
  ul {
    li {
      float: left;
      position: relative;
      & + li {
        margin-left: 40px;
      }
      &.active {
        a {
          color: @green;
          &:after {
            width: 18px;
          }
        }
      }
      &:hover {
        .active;
      }
      a {
        color: #111;
        transition: color .3s;
        &:after {
          content: '';
          position: absolute;
          left: 0;
          transition: width .3s;
          bottom: -5px;
          background-color: @green;
          height: 1px;
          width: 0;
        }
      }
    }
  }
}

.slide-pagination {
  li {
    float: left;
    cursor: pointer;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid @green;
    box-sizing: border-box;
    &.on {
      background-color: @green;
    }
    & + li {
      margin-left: 15px;
    }
  }
  &.white{
    li{
      border: 1px solid #fff;
      &.on {
        background-color: #fff;
      }
    }
  }
}

.sz-home {
  .banner {
    margin-top: 20px;
    .main;
    .sz-home-slide {
      margin-top: -30px;
      position: relative;
      .hd {
        .container;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        .swiper-pagination {
          height: 50px;
          overflow: hidden;
          position: absolute;
          right: 0;
          bottom: 0;
          z-index: 1;
          .swiper-pagination-bullet {
            margin-top: 18px;
            margin-left: 15px;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            cursor: pointer;
            box-sizing: border-box;
            border: 1px solid @green;
            background: transparent;
            opacity: 1;
            &-active {
              background-color: @green;
            }
          }
        }
      }
      .bd { //图片
        min-width: 1080px;
        ul {
          li {
            position: relative;
            text-align: center;
            img {
            }
            .slide-title {
              position: absolute;
              left: 0;
              bottom: 0;
              text-align: left;
              background-color: rgba(255, 255, 255, 0.6);
              right: 0;
              h2 {
                color: @green;
                font-size: 18px;
                height: 50px;
                line-height: 50px;
                .text-overflow;
                width: 915px;
              }
            }
          }
        }
      }
    }
  }
}

//右侧二维码
.qr-code-wrap {
  position: absolute;
  z-index: 9;
  top: 55%;
  right: 0;
  font-size: 16px;
  color: #fff;
  text-align: center;
  .qr-code {
    .qr-code-content {
      width: 130px;
      margin-bottom: 10px;
      padding: 20px 20px 10px 20px;
      border-radius: 4px;
      .bg(bg-float-info2, png, center, cover);
      .qr-code-desc {
        font-size: 16px;
      }
      img {
        margin-bottom: 10px;
      }
      .follow-desc {
        margin-bottom: 0;
      }
    }
    .img-close-qr-tips, .img-show-qr-tips {
      cursor: pointer;
    }
  }
  .qr-code-small {
    text-align: right;
    cursor: pointer;
  }
}

//标题
.title-wrapper {
  overflow: hidden;
  .more {
    color: @green;
    float: right;
    line-height: 24px;
    height: 24px;
  }
}

.title-left-deco {
  color: @green;
  float: left;
  font-size: 24px;
}

.footer {
  .bg(bg-footer, jpg, center, cover);
  padding: 20px 0 10px;
  color: #fff;
  font-size: 14px;
  .container {
    overflow: hidden;
    position: relative;
    .content {
      float: left;
      font-size: 12px;
      .title-left-deco {
        color: #fff;
        border-left-color: #fff;
        margin-bottom: 13px;
        float: none;
      }
      .web-info {
        margin-bottom: 5px;
        overflow: hidden;
        a {
          color: #fff;
        }
        li {
          float: left;
          line-height: 1.2;
          &:first-child {
            width: 415px;
          }
          &:nth-child(2) {
            width: 270px;
          }
          &:nth-child(3) {
            width: 150px;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .footer-logo {
      position: absolute;
      top: 50%;
      margin-top: -30px;
      right: 0;
      overflow: hidden;
      width: 60px;
      height: 60px;
      line-height: 60px;
      border-radius: 4px;
      background-color: #fff;
      text-align: center;
    }
  }
}

//全局覆盖(用于点击全局覆盖)
.fill-click{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 2;
}

//模拟下拉框
.select-search{
  position: relative;
  .search-meta{
    color: @green;
    border-radius: 4px;
    border: 1px solid @green;
    padding-left: 10px;
    padding-right: 15px;
    font-size: 16px;
    display: inline-block;
    height: 35px;
    line-height: 35px;
    box-sizing: border-box;
    cursor: pointer;
    span{
      vertical-align: top;
      height: 100%;
      max-width: 100px;
      white-space: nowrap;
      overflow: hidden;
    }
    &.active{
      &:after{
        transform: rotate(180deg);
      }
    }
    &:after{
      content: '';
      display: inline-block;
      .icon-select-drow;
      transform: translateY(-50%);
      margin-left: 20px;
      transition: all .3s;
      transform-origin: center 25%;
    }
  }
  .search-pannel{
    position: absolute;
    padding: 20px;
    left: 0;
    top: 45px;
    background-color: #fff;
    box-shadow: 0 3px 10px 0 rgba(7, 12, 1, 0.28);
    width: 200px;
    z-index: 3;
    display: none;
    border-radius: 4px;

    .search-list{
      font-size: 14px;
      overflow: hidden;
      margin-left: -10px;
      margin-bottom: -10px;
      li{
        cursor: pointer;
        float: left;
        padding-left: 15px;
        padding-right: 15px;
        height: 30px;
        line-height: 30px;
        border-radius: 4px;
        background-color: #e6e6e6;
        color: @light-black;
        margin-left: 10px;
        margin-bottom: 10px;
        &.active{
          background-color: @green;
          color: #fff;
        }
      }
    }
  }
}

//项目搜索框
.orguser-search {
  margin-bottom: 30px;
  font-size: 0;
  .clearfix;

  .select-search{
    float: left;
    margin-right: 20px;
  }
  select{
    border-radius: 4px;
    & + select{
      margin-left: 1px !important;
    }
  }
  select, input[type="text"], button {
    height: 35px;
    line-height: 35px;
    vertical-align: middle;
    float: left;
  }
  .search-keyword{
    margin-left: 10px;
    display: inline-block;
		vertical-align: middle;
    input[type=text]{
      width: 355px;
    }
  }
  select {
    padding: 0 10px 0 20px;
    box-sizing: border-box;
    width: unset;
    min-width: 130px;
    height: 35px;
  }

  .btn-new-project{
    float: right;
    width: 145px;
    height: 35px;
    line-height: 35px;
    font-size: 18px;
    text-align: center;
  }
}

///项目搜索框
.search {
  margin-bottom: 30px;
  font-size: 0;
  .clearfix;

  .select-search{
    float: left;
    margin-right: 20px;
  }
  select, input[type="text"], button {
    height: 35px;
    line-height: 35px;
    vertical-align: middle;
    float: left;
  }
  .search-keyword{
    display: inline-block;
    input[type=text]{
      background-color: #fff;
      width: 355px;
      border: 1px solid @green;
      border-right: 0;
    }
  }
  select {
    .icon-select-drow;
    width: 130px;
    height: 35px;
    background-color: #fff;
    color: @green;
    border-radius: 4px;
    border: 1px solid @green;
    margin-right: 20px;
    font-size: 16px;
    appearance: none;
    background-position: 85px center;
  }

  .btn-new-project{
    float: right;
    width: 145px;
    height: 35px;
    line-height: 35px;
    font-size: 18px;
    text-align: center;
  }
}

//搜索关键词
.search-keyword{
  overflow: hidden;
  input[type="text"],button{
    float: left;
    height: 35px;
    line-height: 35px;
    vertical-align: middle;
  }
  input[type="text"] {
    border-radius: 4px 0 0 4px;
  }
  button {
    width: 65px;
    border-radius: 0 4px 4px 0;
    border: unset;
    background-color: @green;
    position: relative;
    .icon-search{
      .abs-center;
    }
  }
}


//分页
.pagination{
  text-align: center;
  font-size: 14px;
  &.pagination-padding{
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .page-nav{
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
    &:first-child{
      margin-left: 0;
      margin-right: 10px;
    }

    &.page-fixed{
    }
    &.page-nearby{
      color: #fff;
      height: 30px;
      width: 30px;
      border-radius: 4px;
      line-height: 30px;
      background-color: @green;
      &.disabled{
        color: @light-black;
        background-color: #e3e3e3;
      }
    }
  }
  .number{
    height: 30px;
    line-height: 30px;
    margin: 0 5px;
    text-align: center;
    width: 20px;
    display: inline-block;
    &.on{
      color: @green;
      font-weight: bold;
      position: relative;
      &:after{
        content: '';
        border-bottom: 2px solid @green;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

  }
  .omit{
    margin-left: 10px;
  }
  .total{
    color: @gray;
    margin-left: 20px;
  }
}



//项目列表
.project-list {
  overflow: hidden;
  margin-left: -20px;
  margin-bottom: -20px;
  &.help-list{ //善助
    .item{
      height: auto;
    }
  }
  &.donate-list{ //善捐
    .item{
      height: 300px;
    }
  }
  .item {
    height: 314px;
    margin-left: 20px;
    padding: 10px;
    float: left;
    box-sizing: border-box;
    width: 240px;
    border: 1px solid @border;
    transition: all .3s;
    margin-bottom: 20px;
    &:hover {
      border: 1px solid @green;
    }
    a{
      >.img {
        .img-wrap(100%, 135px);
        position: relative;
        .tag{
          position: absolute;
          right: 0;
          top: 0;
          font-size: 12px;
          color: #fff;
          background-color: #fd7400;
          height: 25px;
          line-height: 25px;
          border-radius: 0;
          padding: 0 10px;
        }
      }
      >.content {
        margin-top: 20px;
        text-align: left;
        h3 {
          height: 48px;
          font-size: 16px;
          margin-bottom: 10px;
          font-weight: 400;
          word-break: break-all;
        }
      }
      .bottom{
        border-top: 1px solid @border;
        margin-top: 15px;
        padding-top: 10px;
      }
      .helper-info{
        margin-top: 10px;
      }
      .target {
        line-height: 1.2;
        font-size: 14px;
        margin-top: 10px;
        span {
          line-height: 1.2;
        }
        .title {
          color: @gray;
          padding-right: 5px;
          margin-right: 5px;
          border-right: 1px solid @gray;
        }
      }
      .target-inline{
        overflow: hidden;
        .target{
          margin-top: 0;
          float: left;
          width: 50%;
          box-sizing: border-box;
          & + .target{
            border-left: 1px solid @border;
          }
          &:last-child{
            text-align: right;
          }
        }
      }
      .desc {
        margin-top: 3px;
        margin-bottom: 0;
        color: @light-black;
        .text-overflow;
      }
      .initiator-title{
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid @border;
        color: @light-black;
        .text-overflow;
      }
      .activity-info{
        color: @gray;
        font-size: 14px;
        .time{
          float: left;
        }
        .number{
          float: right;
        }
        .address{
          clear: both;
          padding-top: 5px;
          .text-overflow;
        }
      }

    }
  }

  //jb
  &.support-project-list{
    .item {
      a{
        width: 223px;
        > .img {
          height: 145px;
          width: 220px;
        }
        > .content {
          h3{
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .target{
          .title{
            border-right: 1px solid #ddd;
            padding-right: 7px;
            margin-right: 7px;
            span{
              vertical-align: middle;
            }
          }
        }
        .initiator-title{
          .text-desc{
            color: @gray;
            vertical-align: top;
          }
        }

      }

    }
  }
  &.apply-list.signin-list{
    .item{
      padding-bottom: 0;
      a{
        display: inline-block;
        height: inherit;
        width: 223px;
        > .img {
          width: 220px;
          height: 152px;
        }
        > .content {
          h3{
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 5px;
          }
        }
        .target{
          margin-top: 5px;
          text-align: left;
          padding-left: 10px;
          &:first-child{
            //padding-right: 10px;
            padding-left: 0;
            span.text-unit{
              display: inline-block;
              max-width: 61px;
              height: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          span{
           vertical-align: middle;
          }
          .text-slogan{
            overflow: hidden;
            text-overflow: ellipsis;
            width: 190px;
            display: inline-block;
          }
        }
        .target-inline{
          padding-top: 8px;
          padding-bottom: 10px;
        }
      }
    }
    &.person-signin-list{
      .item{
        height: 320px ;
        a{
          width: 223px;
          .img{
            img{
              width: 152px;
            }
          }
        }
      }
    }
  }
}
.project-search-list{
  &.help-list{ //善助
    .item{
      height: auto;
    }
  }
  &.donate-list{ //善捐
    .item{
      height: 310px;
    }
  }
  .item{
    width: 255px;
    padding: 15px;
  }
  & + .pagination{
    margin-top: 40px;
  }
}

.helper-info{
  .headimg-info(50px,15px);
  .content{
    .helper-title{
      line-height: 1.2;
      font-size: 16px;
      color: @light-black;
    }
  }
}
//善助，善捐  项目列表 结束


.home {
  .stat-list {
    background-color: #96d345;
    border-radius: 4px;
    color: #fff;
    padding-top: 17px;
    padding-bottom: 17px;
    margin-bottom: 25px;
    overflow: hidden;
    li {
      padding-left: 40px;
      box-sizing: border-box;
      float: left;
      align-items: center;
      width: 33.33%;
      overflow: hidden;
      & + li{
        border-left: 1px solid #a2df44;
      }
      .icon-wrapper {
        float: left;
        margin-right: 30px;
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        border: 1px solid #fff;
        box-sizing: border-box;
        position: relative;
        i{
          .abs-center;
          filter: drop-shadow(1px 2px 4px #adadad);
        }
      }
      .content {
        float: left;
        .count {
          font-size: 24px;
        }
        .title {
          font-size: 16px;
        }
      }
    }
  }
  .sidebar{
    .news,.topic{
      border: 1px solid @green;
      padding-top: 20px;
      >.title {
        overflow: hidden;
         margin-bottom: 0;
        line-height: 1.2;
        height: auto;
        .more{
          display: inline-block;
        }
        .title-under-line{
          overflow: hidden;
          border-bottom: 0;
          line-height: 24px;
          height: auto;
          margin-bottom: 0;
          h3 {
            height: auto;
            border-bottom: none;
            line-height: 1.2;
            .title-left-deco;
            font-weight: 400;
            font-size: 24px;
          }
        }

      }
    }
  }
}

.sz-home {
  .main {
    .content {
      .project {
        margin-bottom: 40px;
        .title-wrapper{
          margin-bottom: 20px;
        }
      }
    }
  }
}

.sidebar-box {
  padding: 20px 13px;
  border: 1px solid @green;
}

.sidebar-panel {
  background-color: #fff;
  box-sizing: border-box;
  & + & {
    margin-top: 20px;
  }
}

.sidebar {
  //.title {
  //   .title-wrapper;
  //   margin-bottom: 20px;
  //   h3 {
  //     .title-left-deco;
  //     font-weight: 400;
  //     font-size: 24px;
  //   }
  //}
  .title-under-line {
    height: 50px;
    line-height: 50px;
    margin-bottom: 20px;
    padding: 0;
    h3{
      font-size: 18px;
      height: 50px;
      line-height: 50px;
      box-sizing: border-box;
      vertical-align: middle;
    }
  }
  .qa{
    .title-under-line{
      margin-bottom: 0;
    }
    ul{
      li{
        .icon-wrap;
        height: 45px;
        line-height: 45px;
        .text-overflow;
        border-bottom: 1px dashed @border;
      }
    }
  }
  .start-org,.accept-org{
    .explain{
      .icon-wrap;
      color:@light-black;
      i{
        margin-left: 5px;
      }
    }
    a{
      display: block;
      position: relative;
      overflow: hidden;
      .img{
        float: left;
        .img-wrap(80px,80px);
        box-sizing: border-box;
        border: 1px solid @border;
        img{
          vertical-align: middle;

        }
      }
      .name{
        margin-left: 130px;
        height: 80px;
        box-sizing: border-box;
        border-bottom: 1px dashed @border;
        position: relative;
        h3{
          font-size: 14px;
          color: @light-black;
          margin-bottom: 13px;
          &.support{
            .multi-text-overflow(21px,2);
          }
        }
        p{
          font-size: 12px;
          color: @gray;
          span{
            color: @red;
          }
        }
      }
      &:after{
        content: '';
        .abs-right-center;
        .icon-right-arrow;
      }
    }
    &.big-img-layout{
      a{
        .img{
          width: 110px;
          height: 71px;
          line-height: 71px;
          img{
            width: 100%;
          }
        }
        .name{
          height: 71px;
        }
        .name{
          h3{
            margin-bottom: 8px;
          }
          > .date-box{
            color:#999;
            font-size: 12px;
            > i{
              margin-right: 5px;
              margin-top: -3px;
            }
            > span{
              vertical-align: middle;
            }
          }
        }
        & + a{
          margin-top: 20px;
        }
      }
    }
  }

  .donate-summary {
    background-color: #eaf6da;
    padding: 17px 20px;
    .item {
      font-size: 18px;
      margin-bottom: 20px;
      .title {
        margin-bottom: 15px;
        color: @green;
      }
      .content {
        height: 35px;
        line-height: 35px;
        overflow: hidden;
        .count {
          height: 35px;
          line-height: 35px;
          color: @orange;
          width: 208px;
          float: left;
          font-size: 18px;
          font-weight: 400;
          &:hover{
            color: @orange;
            background-color: #fff !important;
          }
        }
        .sub {
          float: left;
          font-size: 16px;
          margin-left: 15px;
          color: @gray;
        }
      }
    }
  }
  .news {
    //.sidebar-box;
  }
  .topic {
    .sidebar-box;
    .topic-slide{
      position: relative;
      .hd{//幻灯片页码
        position: absolute;
        right: 10px;
        bottom: 25px;
        z-index: 2;
        li{
          width: 10px;
          height: 10px;
        }
      }
      .bd { //内容
        ul {
          li {
            a {
              overflow: hidden;
              height: 175px;
              display: block;
              img {
                max-width: 100%;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .new-topic-container {
    .hd-pagination {
      width: unset;
      right: 10px;
      bottom: 25px;
      height: 10px;
      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        border: 1px solid #fff;
        margin-left: 15px;
        cursor: pointer;
        background: transparent;
        opacity: 1;
        box-sizing: border-box;
        &-active {
          background-color: #fff;
        }
      }
    }
  }

  .org-info{
    ul{
      font-size: 14px;
      li{
        overflow: hidden;
        & + li{
          margin-top: 10px;
        }
        .ques{
          color: @gray;
          float: left;
          line-height: 1.5;
        }
        .answer{
          margin-left: 70px;
          color: @light-black;
          line-height: 1.5;
          &.cert-wrap{
            .btn{
              height: 25px;
              line-height: 23px;
              padding: 0 5px;
              & + .btn{
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }

  .recommend-project{
    //修改了padding-top:luojianbiao
    padding: 10px 20px 20px 20px;
  }
  .news{
    padding: 0 20px 20px 20px;
  }

  .helper-promise{
    .icon-wrap{
      i{
        float: left;
        margin-top: 3px;
      }
      .desc{
        display: inline;
        vertical-align: top;
      }
    }
  }

  .news{
    border: none;
    >.title{
      .title-under-line;
      margin-bottom: 0;
      .more{
        display: none;
      }
    }
    .news-recommed-list{
      li{
        &:last-child{
          padding-bottom: 20px;
          border-bottom: 1px dashed #ededed;
        }
      }
    }
  }
}

.news-recommed-list {
  padding-left: 10px;
  li {
    padding: 15px 0;
    position: relative;
    &:last-child {
      padding-bottom: 0;
    }
    &:before {
      content: '';
      position: absolute;
      left: -12px;
      top: 23px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: @green;
    }
    &:hover {
      a {
        color: @green;
      }
    }
    a {
      .title {
        font-size: 16px;
        .text-overflow;
        color: @light-black;
        min-width: 0;
        display: block;
        margin-bottom: 10px;
        line-height: 1.2;
      }
      .time {
        font-size: 14px;
        color: @gray;
        line-height: 1.2;
      }
    }
    & + li {
      border-top: 1px dashed @border;
    }
  }
}

//推荐项目列表
.recommend-project-list{
  li{
    & + li{
      margin-top: 15px;
      border-top: 1px dashed #ededed;
      padding-top: 15px;
    }
    a{
      .img{
        margin-bottom: 10px;
        .img-wrap(100%,160px);
      }
      .desc{
        color: @black;
        line-height: 1.5;
        font-size: 16px;
      }
    }
  }
}

//合作伙伴
.partner{
  .title-wrapper{
    margin-bottom: 20px;
  }
  .tab-content{
    padding: 20px 15px;
    border: 1px solid @green;
    .partner-list{
      margin-right: -5px;
      width: 970px;
      .margin-center;
      .partner-item{
        a{
          .img-wrap(100%,100px);
          display: block;
          box-sizing: border-box;
          border: 1px solid @border;
          img{
            width: auto;
            height: auto;
            margin: 5px auto;
          }
          & + a{
            margin-top: 10px;
          }
        }
      }
    }
  }
  .owl-theme{
    .owl-nav{
      margin-top: 0;
      .left-arrow{
        .icon-left-arrow;
      }
      .owl-prev,.owl-next{
        position: absolute;
        width: 20px;
        height: 50px;
        background-color: #e1f3de;
        top: 25px;
        margin: 0;
        transition: all .1s;
        &:hover{
          background-color: #e1f3de;
        }
      }
      .owl-prev{
        left: -37px;
        &:not(.disabled){
          &:hover{
            left: -40px;
          }
        }
      }
      .owl-next{
        right: -37px;
        &:not(.disabled){
          &:hover{
            right: -40px;
          }
        }
        i{
          transform: rotateY(180deg);
        }
      }
    }
  }
}

//面包屑导航
.bread_crumb{
  font-size: 14px;
  color: @gray;
  margin-bottom: 20px;
  a{
    color: @gray;
  }
}

//项目详情
.project-detail{
  padding-top: 165px;
  //tab搜索框
  .search-content{
    margin-bottom: 10px;
    margin-top: 25px;
    overflow: hidden;
    @height: 30px;
    form{
      float: left;
      line-height: @height;
      height: @height;
      .search-form-wrapper{
        line-height: @height;
        height: @height;
        input[type="text"]{
          background-color: #f5f5f5;
          border: 1px solid #e6e6e6;
          border-right: 0;
          line-height: @height;
          height: @height;
        }
        .btn-search{
          line-height: @height;
          height: @height;
        }
      }
    }
    .btn{
      line-height: @height;
      height: @height;
      width: 100px;
      float: right;
    }
  }
  //举报弹窗
  .report-popup{
    .popup-content{
      max-height: unset !important;
    }
    .btn-confirm{
      width: 140px;
    }
  }

  //顶部项目描述
  .summary{
    height: 284px;
    .clearfix;
    padding-bottom: 40px;
    >.img{
      float: left;
      .img-wrap(460px,284px);
    }
    >.content{
      min-height: 284px;
      position: relative;
      margin-left: 480px;
      h1{
        line-height: 1.2;
        font-size: 22px;
        color: @green;
        margin-bottom: 20px;
        .remaining-time{
          font-size: 14px;
          color: @gray;
          .time{
            color: @green;
          }
        }
      }
      .summary-list{
        margin-left: -40px;
        margin-bottom: 20px;
        .title{
          margin-bottom: 10px;
        }
        .desc{
          margin-bottom: 0;
        }
      }
      .progress{
        margin-bottom: 20px;
      }
      .title-desc-list{
        margin-bottom: 15px;
        li{
          line-height: 1.2;
        }
      }

      .headimg-info{
        border-top: 1px solid @border;
        padding-top: 20px;
        .headimg-info(50px);
      }
      .bottom{
        position: absolute;
        left: 0;
        bottom: 0;
        .btn-donate{
          width: 150px;
          float: left;
        }
        .share{
          float: left;
          margin-left: 15px;
          height: 40px;
          line-height: 40px;
        }
      }
      .follow-area{
        position: absolute;
        color: @gray;
        right: 15px;
        bottom: 0;
        border-radius: 4px;
        border: 1px solid @green;
        padding: 5px;
        .img{
          border: 1px dotted #f7fcf6;
          float: left;
          .img-wrap(70px,70px);
          box-sizing: border-box;
        }
        .desc{
          float: left;
          margin-left: 5px;
          margin-top: 7px;
          p{
            line-height: 1.2;
            font-size: 12px;
            &:last-child{
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  //项目详情
  .detail{
    border-top: 5px solid @border;
    padding-top: 5px;
    .main-info{
      width: 760px;
      float: left;
      &.tab-pannel{
        .tab-title-list{
          &.default{
            height: 50px;
            line-height: 50px;
          }
        }
        .tab-comfirm{
          padding-top: 15px;
          .confirm-header{
            height: 25px;
            line-height: 25px;
            .title{
              float: left;
              font-size: 16px;
              color: @green;
            }
            .btn-confirm{
              float: right;
              height: 25px;
              line-height: 25px;
              padding: 0 15px;
            }
          }

        }
      }
      .article-content{
        padding: 20px 0;
      }
    }
  }
}

//分享样式
.share{
  color: @gray;
  font-size: 0;
  height: 22px;
  line-height: 22px;
  .desc{
    font-size: 14px;
  }
  i{
    & + i{
      margin-left: 10px;
    }
  }
}


//捐赠记录
.donate-record{
  .record-content{
    .item{
      position: relative;
      overflow: hidden;
      border-bottom: 1px dashed @border;
      padding: 20px 0;
      .img{
        .avatar(70px);
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        .obfit-cover;
      }
      .name{
        margin-left: 90px;
        h4{
          font-size: 16px;
          margin-bottom: 15px;
          color: @black;
        }
        .date{
          font-size: 12px;
          color: @gray;
        }
        .remark{
          font-size: 14px;
          color: @black;
          margin-bottom: 0;
          max-width: 570px;
        }
      }
    }
  }
  .more{

  }
}

//捐赠数据
.donate-amount{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  .count{
    color: @red;
    font-weight: 600;
  }
}


//登陆页面
.login{
  background-color:#eaf2dd;
  margin-top: 145px;
  box-sizing: border-box;
  min-height: calc(~"100% - 255px"); //255 = header.height + .login.margin-top
  &.update-login {
    margin-top: 0;
    padding-top: 145px;
    min-height: calc(~"100% - 255px + 145px");
  }
  .login-box{
    overflow: hidden;
    .white-pannel;
    width: 620px;
    .margin-center;
    .tab-title-list{
      overflow: hidden;
      font-size: 24px;
      height: 65px;
      line-height: 65px;
      text-align: center;
      li{
        float: left;
        width: 100%;
        // width: 50%;
        background-color: #e8eae5;
        color: @light-black;
        // cursor: pointer;
        &.active{
          color: #fff;
          background-color: @green;
        }
      }
    }
    .tab-content-list{
      li{
        padding: 50px 30px;

        .form-control{
          .field-name{
            width: 100px;
          }
          .form-field{
            margin-left: 110px;
          }
          &.validate{
            input{
              width: 360px;
            }
          }
        }

        .btn-login,.btn-register,.btn-reset-psd{
          margin-top: 40px;
          margin-bottom: 20px;
          font-size: 18px;
        }
        input{
          width: 100%;
        }
        .login-meta{
          overflow: hidden;
          font-size: 14px;
          a{
            color: @green;
          }
        }
      }
    }
  }
  &.org-login{
    .form-control{
      .field-name{
        width: 50px !important;;
      }
      .form-field{
        margin-left: 60px !important;
      }
    }
  }
}

//form设置
.form-control{
  & + &{
    margin-top: 20px;
  }
  .field-name{
    font-size: 16px;
    color: @light-black;
  }
  .form-field{
    input[type="text"]{
      width: 100%;
    }
  }

  //验证码
  &.validate{
    .form-field{
      input{
        vertical-align: middle;
        width: 80%;
        border-radius: 4px 0 0 4px;
      }
      a{
        .btn;
        .btn-green;
        .btn-border;
        display: inline-block;
        width: 20%;
        text-align: center;
        border-radius: 0 4px 4px 0;
        height: 45px;
        line-height: 45px;
        vertical-align: middle;
        font-size: 14px;
      }
    }
  }

  //图形验证
  &.verify{

    .form-field{
      height: 45px;
      line-height: 45px;
      overflow: hidden;
      position: relative;
      border-radius: 4px;
      input{
      }
      .validate-img{
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
      }
    }
  }
}



//求助证实列表
.confirm-list {
  >li {
    border-bottom: 1px #e9e9e9 dashed;
    .headimg-info(70px);
    padding: 20px 0;
  }
}

//求助动态列表
//.help-list{
//  >li{
//    border-bottom: 1px dashed @border;
//    padding: 20px 0;
//    .headimg-info(70px);
//    .content{
//      .info{
//        margin-bottom: 15px;
//        overflow: hidden;
//        .user-name{
//          font-size: 16px;
//          float: left;
//        }
//        .date{
//          font-size: 14px;
//          float: right;
//          color: @gray;
//        }
//      }
//      .recollection{
//        font-size: 14px;
//        margin-bottom: 15px;
//      }
//    }
//  }
//}

//无数据
.no-data{
  font-size: 16px;
  padding: 20px 0;
  color: @light-black;
  width: 100%;
  text-align: center;
  display: block;
}

.sz-more {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 20px;
  border: 1px dashed #E9E9E9;
  width: 100px;
  text-align: center;
}


//善助->捐赠列表
.donate-list{
  margin-bottom: 30px;
  .donate-item{
    padding: 20px 0;
    .headimg-info(70px);
    border-bottom: 1px dashed @border;
    .content{
      .top{
        font-size: 16px;
        color: @black;
        line-height: 1.2;
        margin-bottom: 15px;
      }
      .middle{
        margin-bottom: 15px;
        line-height: 1.2;
        font-size: 12px;
        color: @gray;
      }
      .remark{
        max-width: 500px;
        line-height: 1.5;
        font-size: 12px;
        color: @light-black;
      }
    }

  }
}

//求助动态
.dynamic-img-pupup{
  li{
    height: 85px;
  }
}

//资金公示列表
.publicity-list{
  margin-bottom: 20px;
}
//资金公示列表
.publicity-item{
  border-bottom: 1px dashed @border;
  padding: 20px 0;
  .top{
    overflow: hidden;
    margin-bottom: 10px;
    h3{
      font-size: 16px;
      float: left;
      color: @black;
    }
    .show-all{
      font-size: 14px;
      float: right;
      color: @green;
      height: 24px;
      line-height: 24px;
    }
  }
  .time{
    font-size: 12px;
    color: @gray;
    margin-bottom: 10px;
    line-height: 1.2;
  }
  .desc{
    color: @light-black;
    margin-bottom: 15px;
  }
  .img-list{
    li{
      height: 85px;
    }
  }
}
//求助动态
.help-list{
  .help-item{
    position: relative;
    .left{
      position: absolute;
      left: 0;
      top: 20px;
      width: 50px;
      .img{
        margin-bottom: 10px;
        .avatar(50px);
      }
      .user-name{
        font-size: 14px;
        color: @light-black;
        text-align: center;
      }
    }
    .content{
      .publicity-item{
        padding-left: 70px;
      }
    }
  }
}

//捐赠金额选择
.select-label(@width: 105px,@height: 45px){
  overflow: hidden;
  height: @height;
  line-height: @height;
  span{
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    height: @height;
    line-height: @height - 2px;
    box-sizing: border-box;
    float: left;
    border: 1px solid @border;
    border-radius: 4px;
    width: @width;
    color: @black;
    position: relative;
    &.on{
      border: 3px solid @red;
      line-height: @height - 6px;
      &:after{
        content: '';
        .icon-donate-select;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    & + span{
      margin-left: 20px;
    }
  }
}


.image-source-link {
  color: #98C3D1;
}

//捐赠页面
.donation-detail{
  .main-info{
    >.title{
      margin-bottom: 30px;
      padding: 15px 0;
      overflow: hidden;
      border-bottom: 1px solid @green;
      .meta{
        float: left;
        width: 90px;
        font-size: 18px;
        color: @green;
      }
      .content{
        line-height: 1.5;
        margin-left: 90px;
        font-size: 16px;
        color: @black;
      }
    }
    .form-group,.form-control{
      @left: 125px;
      margin-bottom: 30px;
      .wrapper{
      }
      .title,.field-name{
        width: @left;
      }
      .input,.form-field{
        margin-left: @left;
      }
      .msg{
        margin-left: @left;
      }
      &.donate-count{
        .input{
          .select-label(105px,45px);
        }
      }
      &.pay-method{
        margin-bottom: 45px;
        height: 60px;
        line-height: 60px;
        .title{
          height: 60px;
          line-height: 60px;
        }
        .input{
          .select-label(180px,60px);
        }
      }
      &.field-remark{
        margin-bottom: 0;
        .msg{
          margin-top: 20px;
          padding-bottom: 30px;
        }
      }
      &.form-invoice{
        padding: 30px 0;
      }
      &.field-inline{
        line-height: 15px;
        .title{
          line-height: 1.2;
          height: auto;
        }
        .input{
          input{
            margin-right: 5px;
          }
          label{
            display: inline-block;
            & + label{
              margin-left: 45px;
            }
          }
        }
      }
    }
    .btn-submit{
      width: 145px;
      height: 35px;
      line-height: 35px;
    }
    .tips-text{
      padding-left: 25px;
      margin-left: 105px;
      background: url('../../../../img/notice.png') no-repeat center left 3px;
      background-size: 14px 14px;
      color: #999;
      font-size: 14px;
      margin-top: 15px;
      line-height: 1.2;
    }
  }
}

//弹窗文字
.show-popup{
  color: @orange;
  position: relative;
  &:after{
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    border-bottom: 1px dashed @orange;
  }
}


//机构详情
.org-detail{
  .summary{
    margin-bottom: 40px;
    .img{
      float: left;
      .img-wrap(380px,215px);
    }
    .content{
      min-height: 215px;
      border-bottom: 1px dashed @border;
      margin-left: 415px;
      h1{
        font-size: 22px;
        color: @green;
        margin-bottom: 20px;
      }
      .org-summary{
        font-size: 14px;
        color: @light-black;
        margin-bottom: 20px;
      }
      .excerpt{
        margin-bottom: 30px;
        font-size: 14px;
        color: @gray;
        i{
          margin-left: 10px;
        }
      }
      .summary-list{
        margin-left: -25px;
        li{
          padding: 0 25px;
        }
      }
    }
  }
  .detail-wrap{
    .detail{
      border-top: 5px solid #ededed;
      padding-top: 5px;
      overflow: hidden;

      .main-info{
        float: left;
        width: 760px;
        &.tab-pannel{
          .tab-title-list{
            &.default{
              height: 50px;
              line-height: 50px;
            }
          }
          .tab-content-list{
            padding-top: 20px;
          }
        }
        .list{
          margin-top: 20px;
          margin-bottom: 50px;
        }
        .pagination{

        }
      }
      .sidebar{
        width: 300px;
      }
    }
  }
}

//捐赠完成
.donation-success{
  height: 100%;
  padding-bottom: 200px;
  padding-top: 165px;
  box-sizing: border-box;
  .detail{
    width: 605px;
    padding-bottom: 50px;
    .margin-center;
    .content{
      padding-top: 45px;
      .title{
        margin-bottom: 35px;
        font-size: 24px;
        color: @green;
      }
      .donate-info{
        font-size: 16px;
        line-height: 1.5;
        color: @light-black;
        border-radius: 4px;
        border: 1px dashed @green;
        padding: 30px 25px;
        margin-bottom: 30px;
        p{
          line-height: 2;
        }
        .desc{
          text-indent: 2em;
          margin-bottom: 30px;
        }
        .time{
          font-size: 14px;
          color: @gray;
        }
        .last{
          margin-bottom: 0;
        }
      }
      .donate-gratitude{
        margin-bottom: 55px;
        span{
          font-size: 16px;
          color: @green;
          & + span{
            margin-left: 25px;
          }
        }
        .btn{
          width: 90px;
          line-height: 25px;
          height: 25px;
          margin-top: 10px;
        }
      }
      .share-card{
        margin-bottom: 55px;
        height: 300px;
        border-radius: 4px;
        overflow: hidden;
        padding: 55px 40px;
        box-sizing: border-box;
        .bg(bg-share-card,jpg);
        .info{
          font-size: 20px;
          color: #fff;
          margin-bottom: 75px;
          .desc{
            line-height: 1.2;
            & + .desc{
              margin-top: 20px;
            }
          }
        }
        .btn-share{
          font-size: 18px;
          color: @green;
          background-color: #fff;
          border-radius: 25px;
          height: 35px;
          line-height: 35px;
          text-align: center;
          width: 140px;
          display: inline-block;
          box-shadow: 0 1px 10px 0 rgba(19, 73, 4, 0.22);
        }
      }
      .btn-group{
        text-align: center;
        .btn{
          & + .btn{
            margin-left: 40px;
          }
        }
      }
    }
  }
}



//分享弹窗
.popup-wrapper{
 &.share-popup{
   padding-top: 40px;
   padding-bottom: 20px;
   width: 490px;
   .icon-close-share{
     position: absolute;
     right: 25px;
     top: 25px;
   }
   .popup-content{
     margin-bottom: 30px;
     position: relative;
     .headimg-info{
       .headimg-info(70px,10px);
       .content{
         font-size: 16px;
       }
     }

     .project-info{
       .project-cover{
         margin-bottom: 20px;
         .img-wrap(100%,265px);
         position: relative;
         .wish{
           font-size: 18px;
           color: #fff;
           position: absolute;
           right: 20px;
           top: 30px;
         }
       }
     }
     .project-content{
       padding-bottom: 40px;
       .img-info(70px,70px,10px);
       .img{
         border: 1px solid @green;
         box-sizing: border-box;
       }
       .content{
         h2{
           font-size: 16px;
           color: @black;
         }
         p{
           font-size: 14px;
           color: @light-black;
         }
       }
     }
   }
 }
}

//页面默认设置
.page-default{
  background-color: @bg-gray;
  .detail {
    overflow: hidden;
    .main-info {
      background-color: #fff;
      float: left;
      width: 760px;
      box-sizing: border-box;
    }
  }
}

//列表页
.page-list{
  .detail{
    .main-info{
      background-color: #fff;
      padding: 10px 20px 65px;
      .pagination{
        margin-top: 55px;
      }
    }
  }
}

.text-wrap{
  .text-overflow;
  min-width: 0;
}

.page-topic-list{
  .detail{
    background-color: #fff;
    padding: 10px 20px 65px;
    box-sizing: border-box;
    .pagination{
      margin-top: 55px;
    }
  }
}

//慈善专题
.topic-list{
  >li{
    & + li{
      margin-top: 30px;
    }
    &:hover{
      a{
        .content{
          >.title{
            color: @green;
          }
        }
      }
    }
    a{
      .img-info(270px,166px,30px);
      .content{
        border-bottom: 1px dashed @border;
        >.title{
          font-size: 18px;
          margin-bottom: 15px;
          line-height: 1.2;
        }
        .time{
          line-height: 1.2;
          font-size: 14px;
          margin-bottom: 20px;
        }
        .summary-list{
          margin-bottom: 15px;
        }
        .owner{
          font-size: 14px;
          color: @light-black;
        }
      }
    }
  }
}

#msg-popup{
	width: 745px;
	.popup-header{
		margin-bottom: 0;
		h3.title{
			margin-bottom: 0;
		}
	}
	.popup-content{
		margin-top: 0;
	}
	.msg-list{
    max-height: 230px;
    overflow-y: auto;
		margin: 0 -30px 50px;
		>li{
			padding: 30px 30px 20px;
			display: flex;
			& + li{
				border-top: 1px dashed @border;
			}
			.avatar{
				.avatar(50px);
				flex-shrink: 0;
				margin-right: 15px;
				border: 1px solid @border;
				line-height: 49px;
			}
			.content{
				width: 630px;
				.msg{
					color: @black;
					margin-bottom: 25px;
					line-height: 1.5;
					word-break: break-word;
				}
				.time{
					color: @gray;
				}
			}
			&:hover,&.isOrg{
				background-color: @bg-gray;
				.content{
					.msg{
						color: @blue;
					}
				}
			}
		}
	}

	.leave-msg{
		border-top: 1px solid @border;
		margin: 0 -30px 15px;
		padding: 0 30px;
		.title{
			padding-top: 20px;
			font-size: 16px;
			color: @black;
			margin-bottom: 15px;
		}
		textarea{
			padding: 15px 30px;
			display: block;
			margin-bottom: 15px;
			width: 100%;
			height: 95px;
			border-radius: 5px;
		}
		.btn-group{
			.btn{
				.btn-set(120px, 35px);
			}
		}
	}
}

.menu {
  .menu-items {
    font-size: 16px;
    position: relative;
    color: @light-black;

    .menu-anchor {
      display: block;
      width: 100%;
      padding: 18px 35px;
      box-sizing: border-box;
      cursor: pointer;
      position: relative;

      &:hover {
        font-weight: bold;
      }

      &.triangle {
        &:after {
          content: '';
          display: inline-block;
          width: 0;
          height: 0;
          border-width: 6px;
          border-style: solid;
          border-color: @green transparent transparent transparent;
          position: absolute;
          transform: rotate(-90deg);
          right: 18px;
          top: 28px;
          margin-top: -3px;
          transition: all .3s;
          border-bottom: 0;
        }

        &.active {
          &:after {
            transform: rotate(0deg);
          }
        }
      }

      .menu-anchor-desc{
        position: relative;
        color: @black;
      }

      &.red-point{
        .menu-anchor-desc{
          //.red-point-mark;
          &:after{
            top: -2px;
          }
        }
      }
    }

    &.active {
      .menu-anchor {
        &.triangle {
          &:after {
            transform: rotate(0deg);
          }
        }
      }
    }

    &.current{
      .menu-anchor{
        font-weight: bold;
      }
    }

    &.active, &.current {
      .menu-anchor {
        background-color: #e4ecd8;
        &:before{
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 5px;
          background-color: @green;
        }
      }

      .sub-menu-list {
        display: block;

        > li {
          a {
            &:before {
              content: '';
              display: inline-block;
              position: absolute;
              top: 50%;
              left: 35px;
              width: 10px;
              border-bottom: 1px solid @green;
            }
          }
        }
      }
    }

    .sub-menu-list {
      display: none;
      font-size: 14px;

      &.active {
        display: block;
      }

      > li {
        &:first-child {
          padding-top: 10px;
        }

        &:last-child {
          padding-bottom: 10px;
        }

        height: 35px;
        line-height: 35px;

        a {
          display: inline-block;
          width: 100%;
          box-sizing: border-box;
          padding: 0 5px 0 55px;
          position: relative;

          .sub-menu-desc{
            display: inline-block;
            box-sizing: border-box;
            position: relative;
            &.red-point{
              //.red-point-mark;
              &:after{
                top: 6px;
              }
            }
          }

          &:hover, &.active {
            color: @green;
          }
        }
      }
    }
  }
}

//机构中心  善品列表
.page-orguser-sp-order-list{
	.orguser-sp-list{
		>li{
			padding: 30px 0;
			display: flex;
			align-items: center;
			height: 85px;
			border-bottom: 1px dashed @border;
			>a{
				display: flex;
				align-items: center;
				.img{
					.img-wrap(134px, 85px);
				}
				.content{
					width: 245px;
					margin-right: 60px;
					margin-left: 20px;
					.title{
						font-size: 16px;
						height: 50px;
						line-height: 1.5;
						color: @light-black;
					}
					.fare,.time{
						font-size: 14px;
						color: @gray;
					}
				}
			}
			.status-info{
				font-size: 14px;
				height: 100%;
				display: flex;
				justify-content: center;
				flex-direction: column;
				width: 141px;
				.status{
					color: @orange;
					line-height: 1.5;
				}
				.remain-count{
					margin-top: 10px;
					color: @light-black;
					line-height: 1.5;
				}
			}
			.opera{
				min-width: 255px;
			}
		}
	}
}

//新闻详情
.page-news-detail{
  .detail{
    .main-info{
      padding: 35px 20px 90px;
      h1{
        font-size: 18px;
        color: @green;
        text-align: center;
        margin-bottom: 20px;
      }
      .date{
        margin-bottom: 30px;
        text-align: center;
        font-size: 12px;
        color: @gray;
      }
    }
  }
}



//新闻列表
.news-list,.org-list{
  .item{
    margin-top: 30px;
    .img-info(190px,130px,15px);
    display: block;
    overflow: hidden;
    .content{
      border-bottom: 1px dashed @border;
      box-sizing: border-box;
      .title{
        font-size: 18px;
        color: @black;
        margin-bottom: 15px;
        line-height: 1.2;
      }
      .time{
        font-size: 12px;
        line-height: 1.2;
        color: @gray;
        margin-bottom: 20px;
      }
      .desc{
        font-size: 14px;
        color: @light-black;
        margin-bottom: 0;
      }
    }
  }
}

.org-list{
  .item{
    .img-info(90px,90px,15px);
    .img{
      border: 1px solid @border;
    }
    .content{
      .title{
        margin-bottom: 20px;
      }
      .org-info-list{
        li{
          overflow: hidden;
          &:first-child{
            float: left;
          }
          &:last-child{
            float: right;
          }
          & + li{
            margin-top: 0;
          }
        }
      }
    }
  }
}

//问题答疑
.qa-detail{
  .main-info{
    padding: 10px 20px 20px;
    background-color: #fff;
  }
  .title-under-line{
    margin-bottom: 0;
  }
  .detail{
    .item{
      padding: 20px 0;
      .q,.a{
        i{
          float: left;
        }
        .content{
          margin-left: 30px;
        }
      }
      .q{
        margin-bottom: 15px;
        font-size: 16px;
        color: @green;
      }
      .a{
        font-size: 14px;
        color: @light-black;
        i{
          margin: 2px 0;
        }
      }
      & + .item{
        border-top: 1px dashed @border;
      }
    }
  }
}

//添加求助
.page-help-add,.page-help-applicat{
  .project-extend-info{
    width: 850px;
    .margin-center;
    padding: 10px 20px 60px;
    .help-form-header{
      margin-bottom: 25px;
      font-size: 18px;
      color: @green;
    }
    .form-group{
      @left: 105px;
      .title{
        width: @left;
      }
      .input{
        margin-left: @left;
      }
      .msg{
        margin-left: @left;
      }
    }
  }
  .help-promise{
    margin-bottom: 25px;
    .input{
      font-size: 14px;
      color: @light-black;
    }
  }
  .show-agree{
    color: @orange;
    font-size: 16px;
    text-decoration: underline;
    display: block;
    margin-bottom: 15px;
  }
  .agree-area{
    display: block;
    color: @light-black;
    input{
      width: 16px;
      height: 16px;
    }
  }
  .help-btn{
    width: 145px;
    height: 35px;
    line-height: 35px;
  }
}

//表单标题
.help-title{
  font-size: 18px !important;
  color: @green;
  line-height: 1.2;
  height: 18px;
  white-space: nowrap;
}



.ngdialog.ngdialog-theme-default{
  .ngdialog-content{
    background-color: #fff !important;
  }
}

//搜索机构
.org-select-mask{
  .ngdialog-close{
    display: none;
  }
}
.claim_box{
  //邀请机构顶部
  .claim-header{
    overflow: hidden;
    line-height: 35px;
    padding: 0 25px 20px 30px;
    position: relative;
    border-bottom: 1px solid @border;
    .title{
      font-size: 20px;
      float: left;
    }
    .search-keyword{
      float: right;
      margin-right: 100px;
    }
    .mask_close_img{
      position: absolute;
      top: 0;
      right: 25px;
    }
  }
  .mechanism_box{
    max-height: 370px;
    overflow: auto;
    padding: 10px 50px;
    .mechanism_item{
      padding: 10px 0;
      overflow: hidden;
      box-sizing: border-box;
      cursor: pointer;
      position: relative;
      border: 1px solid transparent;
      border-bottom-color: @border;
      & + .mechanism_item{
        margin-top: 10px;
      }
      &.active{
        border: 1px solid @green;
        .btn-invite{
          background-color: @green !important;
          color: #fff !important;
        }
      }
      .mechanism_img{
        width: 158px;
        height: 90px;
        float: left;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      .mechanism_desc{
        margin-left: 200px;
        margin-right: 120px;
        .mechanism_name{
          font-size: 16px;
          color: @black;
          line-height: 1.5;
        }
        .mechanism_status-desc{
          font-size: 14px;
        }
      }
      .btn-invite{
        display: none;
        width: 105px;
        line-height: 35px;
        height: 35px;
        .abs-right-center;
        right: 5px;
        &.active{
          background-color: @green !important;
          color: #fff !important;
        }
      }
    }
  }
  .invitation_box{
    line-height: 1.5;
    margin-bottom: 50px;
    padding: 15px 50px 0;
    border-top: 1px solid @border;
    font-size: 14px;
    overflow: hidden;
    color: @light-black;
    .ng-pagination{
      margin-bottom: 30px;
    }
    .invitation_count{
      float: left;
    }
    .invitation_name{
      float: right;
      span{
        & + span{
          margin-left: 5px;
        }
      }
    }
  }
  .button_confirm{
    width: 145px;
    line-height: 35px;
    height: 35px;
  }
}

.select-org{
  margin-bottom: 70px;
  .org_box{
    .meta{
      margin-bottom: 25px;
      overflow: hidden;
      .button_invitation{
        width: 180px;
        float: left;
      }
      .inviate-msg{
        position: relative;
        margin-left: 220px;
        .icon-wrap;
        i{
          .abs-left-center;
        }
        .desc{
          margin-left: 25px;
        }
      }
    }
  }
}
.org_list{
  .clearfix;
  .org_item{
    float: left;
    position: relative;
    font-size: 14px;
    color: @light-black;
    .remove_mechanism{
      position: absolute;
      right: -10px;
      top: -10px;
      cursor: pointer;
    }
    .org_img{
      width: 180px;
      height: 101px;
      margin-bottom: 12px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      .img-wrap(180px,101px);
      border: 1px solid @border;
      box-sizing: border-box;
    }
    & + .org_item{
      margin-left: 20px;
    }
  }
}

//求助申请成功
.page-result{
  height: calc(~"100% - 350px"); //255 = header.height + .login.margin-top
  padding-bottom: 0;
  .container{
    position: relative;
    min-height: 100%; //255 = header.height + .login.margin-top
  }
  .msg-content{
    box-sizing: border-box;
    width: 100%;
    display: table;
    .icon-checked{
      margin-bottom: 30px;
    }
    .title{
      font-size: 24px;
      color: @green;
      margin-bottom: 45px;
    }
    .desc{
      font-size: 14px;
      color: @black;
      margin-bottom: 110px;
    }
    .btn-group{
      .margin-center;
    }
  }
}

.page-help-success {
  .container {
    .msg-content {
      top: 0;
      transform: translate(-50%, 0);
    }
  }
}

//三角形
.triangle{
  &.s{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    background-color: #fff;
    transform: rotate(45deg);
    &:after{
      content: '';
      display: block;
      width: 33px;
      height: 33px;
    }
  }
}

//机构添加项目进度
.project-progress{
  overflow: hidden;
  text-align: center;
  .step{
    position: relative;
    width: 33.333%;
    float: left;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    background-color: #dbdbdb;
    color: @black;
    &.on{
      &:after{
        background-color: #fff;
      }
    }
    &:after{
      content: '';
      position: absolute;
      box-sizing: border-box;
      right: -18px;
      top: 7px;
      width: 36px;
      height: 36px;
      border-right: 1px solid #fff;
      border-top: 1px solid #fff;
      transform: rotate(45deg);
      background-color: #dbdbdb;
      z-index: 1;
    }
    .number{
      line-height: 15px;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      color: #fff;
      background-color: #999999;
    }
    &.on{
      background-color: #fff;
      .number {
        background-color: @green;
      }
    }
  }
}

.btn-group{
  font-size: 14px;
  .btn{
    height: 35px;
    line-height: 35px;
    width: 145px;
    &:not(.hidden) + .btn{
      margin-left: 75px;
    }
  }
  &.btn-group4{
    .btn{
      & + .btn{
        margin-left: 20px;
      }
    }
  }
}

//善助  添加项目
.project-base-info,.project-extend-info{
  .content{
    padding: 10px 20px 105px;
    .title-under-line{
      h3{
        font-size: 24px;
      }
      span{
        font-size: 16px;
        color: @gray;
      }
    }
    .form-group{
      margin-bottom: 30px;
    }
    .money{
      .item{
        overflow: hidden;
        & + .item{
          margin-top: 20px;
        }
        .amount,span,.description{
          float: left;
        }
        .amount{
          width: 190px;
          margin-right: -35px;
        }
        span{
          line-height: 45px;
          height: 45px;
        }
        .description{
          width: 705px;
          margin-left: 35px;
        }
      }
    }
    .tips-link{
      color: @orange;
      border-color: @orange;
    }
  }
}

//关于我们
.page-about{
  .detail{
    padding: 10px 20px 135px;
  }
}

//机构信息列表
.org-info-list{
  overflow: hidden;
  font-size: 14px;
  li{
    overflow: hidden;
    color: @light-black;
    span{
      float: left;
    }
    & + li{
      margin-top: 10px;
    }
  }
}

//angular 指令的分页
.ng-pagination{
  overflow: hidden;
  font-size: 16px;
  line-height: 16px;
  // height: 16px;
  text-align: center;
  li{
    display: inline-block;
    line-height: 16px;
    height: 16px;
    vertical-align: middle;
    & + li{
      margin-left: 5px;
    }
    &.active{
      a{
        color: @green;
      }
    }
    a{
      display: inline-block;
      vertical-align: middle;
      color: @light-black;
    }
  }
}

//专题详情
.page-topic-detail{
  .topic-detail{
    margin-bottom: 40px;
    .img-info(460px,284px,20px);
    .content{
      border-bottom: 1px dashed @border;
      >.title{
        color: @green;
        font-size: 22px;
        margin-bottom: 25px;
      }
      .info{
        font-size: 14px;
        line-height: 1.2;
        margin-bottom: 25px;
        li{
          & + li{
            margin-top: 15px;
          }
        }
      }
      .summary-list{
        margin-bottom: 30px;
      }
    }
  }
  .summary {
    border-top: 5px solid #ededed;
    padding-top: 30px;
    padding-bottom: 130px;
    .donate-list{
      .item{
        width: 255px;
        padding-left: 17.5px;
        padding-right: 17.5px;
      }
    }

  }
}

//支付配置说明
.guide{
  margin-top: 50px;
  .title{
    height: 30px;
    line-height: 30px;
    margin-bottom: 30px;
    h2{
      font-size: 16px;
      color: @green;
      float: left;
      padding: 3px 0;
    }
    .btn-toggle-show{
      float: right;
      line-height: 30px;
      height: 30px;
      width: 65px;
    }
  }
}

//善捐 -> 项目进展
.timeline{
  .item{
    padding: 25px 0 20px;
    border-bottom: 1px dashed @border;
    .title{
      overflow: hidden;
      h3{
        font-size: 16px;
        color: @black;
        margin-bottom: 10px;
        margin-right: 60px;
      }
      a{
        color: @green;
        font-size: 14px;
        float: right;
      }
    }
    .time{
      margin-bottom: 15px;
      font-size: 12px;
      color: @gray;
    }
    p{
      font-size: 14px;
      color: @light-black;
    }
    .timeline-popup{
      margin-left: -2%;
      overflow: hidden;
      margin-bottom: -5px;
      .popup-item{
        width: 23%;
        margin-left: 2%;
        float: left;
        .img{
          .img-wrap(100%,80px);
        }

      }
    }
  }
}

//进展详情
.page-progress-detail{
  background-color: transparent;
  .detail{
    .content{
      padding: 20px 30px;
      background-color: #fff;
      h1{
        font-size: 18px;
        color: @black;
        line-height: 1.5;
        margin-bottom: 10px;
      }
      .date{
        margin-bottom: 30px;
        font-size: 14px;
        color: @gray;
      }
      .btn-group{
        margin-top: 70px;
      }
    }

  }
}

.load-more{
  color: @light-black;
  line-height: 1.5;
  font-size: 16px;
  padding: 20px 0;
  width: 100%;
  text-align: center;
  display: block;
}

//查看原因
.look-content{
  .title-desc-list{
    font-size: 16px;
    li{
      overflow: hidden;
      .title{
        width: 140px;
      }
      .desc{
        margin-left: 140px;
      }
    }
  }
}

//数据前后对比
.old-content,.new-content{
  h4{
    font-size: 22px;
    color: @black;
    margin-bottom: 10px;
  }
  .row{

  }
  .box{
    max-width: 45%;
    & + .box{
      margin-right: 5%;
    }
  }
  .form-group{
    font-size: 16px;
    .strong{
      font-size: 18px;
    }
  }
}

#retn-modal{
  .edui-editor{
    max-width: 100%;
  }
}





//慈善活动月
.page-charitable-active{
  .bg('active/bg','jpg',center,cover);
  min-height: 100%;
  box-sizing: border-box;
  padding-bottom: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  .header{
    position: absolute;
    left: .8rem;
    top: .5rem;
    color: #fff;
    font-weight: bold;
    font-size: .3rem;
    height: .65rem;
    line-height: .65rem;
    a{
      height: .58rem;
      display: inline-block;
      img{
        height: 100%;
        vertical-align: top;
      }
    }
    .desc{
      padding-left: .2rem;
      margin-left: .2rem;
      height: 100%;
      min-height: 100%;
      vertical-align: top;
      position: relative;
      border-left: 1px solid #fff;
    }
  }
  .main{
    text-align: center;
    width: 12.3rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    padding-top: 1.3rem;
    >.title{
      img{
        max-width: 8.38rem;
      }
    }

    .content{
      box-shadow: 3px 19px 50px 0px rgba(0, 0, 0, 0.16);
      margin-top: .6rem;
      overflow: hidden;
      border-radius: 4px;
      height: 5.9rem;
      .title{
        font-size: .36rem;
        color: @green;
        height: .9rem;
        line-height: .9rem;
      }
      .record{
        float: left;
        width: 4.6rem;
        background-color: #fff;
        height: 100%;
        .record-title{
          span{
            position: relative;
            &:after,&:before{
              content: '';
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              display: inline-block;
              width: .2rem;
              border-top: 1px solid @green;
            }
            &:after{
              left: -.4rem;
            }
            &:before{
              right: -.4rem;
            }
          }
        }
        .record-list-wrap{
          position: relative;
          height: 4.2rem;
          max-height: 4.2rem;
          overflow: hidden;
          .record-list{
            position: relative;
            font-size: .24rem;
            li{
              height: .6rem;
              line-height: .6rem;
              padding: 0 .2rem;
              overflow: hidden;
              transition: background-color .3s;
              position: relative;
              display: flex;
              justify-content: space-between;
              &.active{
                background-color: #e2f3e2;
                &:after{
                  display: none;
                }
              }
              .name{
                .text-overflow;
              }
              .desc{
                flex-shrink: 0;
                margin-left: 30px;
              }
              &:after{
                content: '';
                display: block;
                border-bottom: 1px solid @border;
                position: absolute;
                left: .2rem;
                right: .2rem;
                bottom: 0;
              }
              &:hover{
                background-color: #e2f3e2;
                &:after{
                  display: none;
                }
              }
            }
          }
        }
      }
      .count-wrap{
        height: 100%;
        margin-left: 4.6rem;
        background-color: rgba(255, 255, 255, 0.8);
        position: relative;
        &:after{
          content: '';
          position: absolute;
          .bg('active/bg-content','png',center bottom,100% auto);
          left: 0;
          width: 100%;
          bottom: 0;
          height: 1.2rem;
        }
        .count-title{
        }
        .counter{
        }
        .qr-code{
          width: 2.8rem;
          box-sizing: border-box;
          padding: .1rem .15rem;
          padding-bottom: 0;
          border-radius: 4px;
          background-color: #22a525;
          position: relative;
          z-index: 1;
          .margin-center;
          .img{
            background-color: #fff;
            .img-wrap(2.5rem,2.5rem);
          }
          .qr-code-desc{
            font-size: .24rem;
            color: #fff;
            line-height: .5rem;
            height: .5rem;
          }
        }
      }
    }
  }
}

.counter-show{
  display: flex;
  justify-content: center;
}

.counter  {
  margin-bottom: .5rem;
  border: 1px solid @green;
  display: flex;
  height: .8rem;
  visibility: hidden;
  i,span{
    background-color: #fff;
    width: .65rem;
    height: .8rem;
    box-sizing: border-box;
  }
  i{
    .bg('active/number1','png',0 0,100% 8rem);
    & + i{
      border-left: 1px solid @green;
    }
  }
  span{
    border-left: 1px solid @green;
    border-right: 1px solid @green;
    position: relative;
    &:after{
      content: '';
      position: absolute;
      left: .25rem;
      bottom: .2rem;
      border-radius: 50%;
      background-color: @red;
      width: 5px;
      height: 5px;
    }
  }
  &:after{
    content: '元';
    font-size: .36rem;
    background-color: #fff;
    width: .65rem;
    height: .8rem;
    color: @red;
    border-left: 1px solid @green;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}

.center-wish-list{
  >li{
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid @border;
    >a{
      display: flex;
      align-items: flex-start;
      .img{
        .img-wrap(135px, 85px);
      }
      .content{
        height: 85px;
        margin-left: 20px;
        width: 280px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title{
          margin-bottom: 3px;
          color: @black;
          font-size: 18px;
          .text-overflow;
        }
        .time{
          color: @gray;
          font-size: 14px;
        }
        .bottom{
          color: @light-black;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .bottom-item{
            width: 120px;
          }
        }
      }
    }
    .right{
      width: 370px;
      .status-text{
        text-align: right;
        font-size: 14px;
        color: @orange;
      }
      .opera{
        max-width: 382px;
      }
    }

  }
}


//善行
.sx-container{
  padding: 0 20px 85px;
  box-sizing: border-box;
}

//发布活动
.sx-release-activity{
  .form-group{
    margin-bottom: 30px;
    >.msg{
      margin-top: 10px;
    }
    &.activity-address{
      .input{
        .addr-select{
          width: 150px;
          margin-right: 10px;
        }
        input[name="address"]{
          width: 576px;
        }
      }
    }
    &.support-project{
      .input{
        .choose-wrapper{
          margin-bottom: 20px;
          overflow: hidden;
          line-height: 50px;
          .btn{
            float: left;
            margin-right: 20px;
          }
          .icon-list{
            line-height: 50px;
            height: 50px;
            float: left;
            font-size: 14px;
            color: @gray;
          }
        }
      }
    }

    //选择框
    &.checkbox-select{
      @line-height: 20px;
      .title{
        height: @line-height;
        line-height: @line-height;
      }
      .input{
        height: @line-height;
        line-height: @line-height;
        label{
          display: inline-block;
          vertical-align: top;
          height: @line-height;
          line-height: @line-height;
          input[type='radio'],input[type='checkbox']{
            width: 20px;
            height: 20px;
          }
          span,input[type='radio'],input[type='checkbox']{
            vertical-align: top;
          }
          span{
            height: @line-height;
            line-height: @line-height;
            margin-left: 10px;
          }
          & + label{
            margin-left: 45px;
          }
        }
      }
      .msg{
      }
    }

    //队伍人数
    &.group-number{
      .input{
        overflow: hidden;
        .unit-wrapper{
          width: 425px;
          float: left;
        }
        .desc{
          width: 50px;
          float: left;
          text-align: center;
          line-height: 45px;
        }
      }
    }


    &.sign-type{
      margin-top: -12px;
    }

    &.pay-account{
      .input {
        font-size: 0;
        select{
          width: 296px;
          vertical-align: middle;
          & + select{
            margin-left: 5px;
          }
        }
      }
    }

    &.money-limit{
      .input{
        height: 25px;
        .money-collect{
          input{
            width: 95px;
            height: 25px;
            line-height: 25px;
            background-color: #fff;
            border: 1px solid @border;
            box-sizing: border-box;
            padding: 0 10px;
            margin-right: 10px;
          }
          margin-left: 15px;
        }
      }
    }

  }

  //报名类型
  .apply-type-list{
    .header{
      padding-top: 12px;
      margin-bottom: 20px;
      overflow: hidden;
      .item{
        float: left;
        &.type-name{
          width: 340px;
        }
        &.number, &.money{
          width: 235px;
          margin-left: 20px;
        }
      }
    }
    .list{
      .item{
        overflow: hidden;
        margin-bottom: 20px;
        .type-name, .number, .money{
          float: left;
        }
        .type-name{
          width: 340px;
        }
        .number, .money{
          width: 235px;
          margin-left: 20px;
        }
        .operation{
          float: left;
          width: 50px;
          text-align: center;
          line-height: 45px;
          height: 45px;
          display: table;
          a{
            display: table-cell;
            vertical-align: middle;
          }
        }
      }
    }
    .bottom{
      overflow: hidden;
      .add{
        float: left;
      }
      .msg{
        float: left;
        margin-left: 20px;
        margin-top: 0;
        line-height: 32px;
        .desc{
          line-height: 32px;
        }
      }
    }
  }

}
.apply-form-set{
  margin: 50px 0 60px;
  padding: 50px 0;
  border-top: 1px solid @border;
  border-bottom: 1px solid @border;
  .multip-filed{
    display: none;
  }
  .desc-content{
    text-align: center;
    h3{
      font-size: 20px;
      font-weight: bold;
      color: #333;
      margin: 10px 0;
    }
  }
  .apply-form-wrapper{
    overflow: hidden;
    zoom: 1;
    margin-top: 40px;
  }
  .apply-form-list{
    float: left;
    width: 730px;
    border: 1px solid @border;
    padding: 20px;
    box-sizing: border-box;
    h3.inf-title{
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid @border;
      span{
        color: @gray;
        vertical-align: middle;
        display: inline;
      }
    }
    .base-inf{
      .info-list{
        margin: 0 -20px;
        li{
          padding: 0 20px;
          margin-bottom: 25px;
          overflow: hidden;
          &.off{
            background-color: #eeeeee;
          }
          .checbox-wrapper{
            float: left;
            height: 45px;
            line-height: 45px;
            width: 65px;
            font-size: 14px;
            color: @light-black;
            input[type=checkbox]{
              width: 17px;
              height: 17px;
              margin-right: 10px;
            }
          }
          .form-group{
            margin-bottom: 0;
            @width: 80px;
            .title{
              width: @width;
            }
            .input{
              margin-left: @width;
              .tips{
                color: @gray;
              }
            }
          }
        }
      }
    }
    .extend-inf{
      padding-top: 20px;
      .icon-list{
        & + .item{
          margin-top: 20px;
        }
      }
      .item{
        padding-bottom: 20px;
        border-bottom: 1px solid #e8e8e8;
        overflow: hidden;
        zoom: 1;
        & + .item{
          margin-top: 20px;
        }
        .item-name{
          float: left;
          width: 120px;
          text-align: right;
          line-height: 45px;
          font-size: 16px;
        }
        .item-content{
          float: right;
          width: 560px;
          font-size: 0;
          label{
            float: left;
            margin-top: 12px;
            display: inline-block;
          }
          .is-need{
            font-size: 14px;
          }
          .delete-form-item{
            float: right;
            margin-top: 14px;
            margin-right: 14px;
          }
        }
        input{
          margin-right: 10px;
          &[type=checkbox]{
            margin-right: 5px;
          }
        }
        label{
          margin-right: 10px;
        }
        .name{
          width: 160px;
        }
        .desc{
          width: 290px;
        }
        .option{
          margin-top: 10px;
          zoom: 1;
          overflow: hidden;
          h4{
            float: left;
            height: 42px;
            line-height: 42px;
            margin-right: 10px;
          }
          .list{
            float: left;
            width: 468px;
            .item{
              display: inline-block;
              border: none;
              margin: 0 5px;
              padding: 10px 10px 0 0;
              position: relative;
              vertical-align: middle;
              input{
                width: 190px;
                height: 40px;
                line-height: 40px;
                margin-right: 0;
              }
              a.delete-form-option{
                position: absolute;
                right: 0;
                top: 0;
              }
            }
            a{
              display: inline-block;
              margin: 5px;
            }
          }
        }
      }
    }


  }
  .form-item-list{
    float: right;
    width: 260px;
    border: 1px solid #e8e8e8;
    padding: 20px;
    box-sizing: border-box;
    h3{
      font-size: 16px;
      padding-bottom: 20px;
      border-bottom: 1px solid #e8e8e8;
    }
    .item{
      margin-top: 20px;
      padding: 10px;
      .bg(form-item-add,png,192px center,unset);
      background-color: #eeeeee;
      color: #666;
      font-size: 14px;
      a{
        display: block;
      }
      img{
        display: inline-block;
        margin-right: 10px;
        vertical-align: middle;
      }
    }
  }
}

.page-orguser-sale-order-list{
	.orguser-sale-order-list{
		>li{
			& + li{
				margin-top: 20px;
			}
			border: 1px solid @border;
			.list-header{
				height: 40px;
				line-height: 40px;
				color: @black;
				background-color: @bg-gray;
				font-size: 16px;
				display: flex;
				align-items: center;
				padding: 0 20px;
				.order-number{
					width: 280px;
				}
				.create-time{
					width: 330px;
				}
				.donator{
					.text-overflow;
				}
			}
			.list-main{
				padding: 30px 20px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				@height: 86px;
				>a{
					display: inline-flex;
					align-items: center;
					width: 360px;
					.img{
						.img-wrap(132px, @height);
						margin-right: 20px;
						flex-shrink: 0;
					}
					.content{
						max-width: 240px;
						.sale-title{
							font-size: 16px;
							color: @light-black;
							line-height: 1.5;
							margin-bottom: 15px;
						}
						.category{
							font-size: 14px;
							color: @gray;
							.text-overflow;
						}
					}
				}
				.right{
					height: @height;
					font-size: 14px;
					width: 335px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: flex-end;
					.order-info{
						color: @light-black;
						display: flex;
						align-items: center;
						.unit-price{
							width: 150px;
						}
						.total-price{
							width: 140px;
						}
						.order-status{

						}
					}
					.opera{
					}
				}
			}
		}
	}
}

.page-orguser-sp-emit-goods{
	.title-under-gray-line{
		margin-bottom: 45px;
	}
	.form-group{
		&.receiving-goods{
			.user-info-wrapper{
				margin-bottom: 10px;
				line-height: 1.5;
				font-size: 14px;
				color: @black;
				.user-info-item{
					& + .user-info-item{
						margin-left: 30px;
					}
				}
			}
		}
		&.send-type-wrapper{
			.send-type{
				display: flex;
				align-items: center;
				justify-content: space-between;
				select{
					width: 205px;
					margin-right: 10px;
					flex-shrink: 0;
				}
				input{
					flex-grow: 1;
				}
			}
		}
		.order-detail-wrapper{
			display: flex;
			align-items: center;
			padding: 30px 40px 30px 30px;
			border: 1px solid @border;
			>a{
				width: 435px;
				display: flex;
				align-items: center;
				.img{
					.img-wrap(132px, 86px);
					flex-shrink: 0;
					margin-right: 20px;
				}
				.content{
					flex-shrink: 0;
					width: 205px;
					padding: 10px 0;
					.order-detail-title{
						color: @light-black;
						line-height: 1.5;
						font-size: 16px;
						margin-bottom: 10px;
					}
					.category{
						font-size: 14px;
						color: @gray;
					}
				}
			}
			.price-info{
				padding: 10px 0;
				align-self: flex-start;
				width: 160px;
				font-size: 14px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				color: @light-black;
			}
		}
	}
}

.sp-stat{
  background-color: @bg-gray;
  border-radius: 4px;
  .title-desc-list{
    height: 65px;
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid @border;
    >li{
      margin-top: 0;
      align-items: center;
      >.title{
        width: auto;
        color: @light-black;
        font-size: 18px;
      }
      >.desc{
        color: @orange;
        font-size: 24px;
        display: inline-flex;
        align-items: baseline;
        .sub{
          font-size: 14px;
        }
      }
    }
  }
  .title-list{
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    >li{
      font-size: 14px;
      height: 45px;
      line-height: 45px;
      a{
        display: block;
        color: @light-black;
        .red-point{
          line-height: 1.2;
          .red-point-mark;
        }
      }
      &.active{
        a{
          color: @orange;
        }
      }
    }
  }
}

//已经选择的项目
.selected-list{
  margin: 10px 0 -20px -20px;
  .selected-activity{
    margin-right: 20px;
    margin-bottom: 20px;
    display: inline-block;
    margin-left: 20px;
    background-color: #eeeeee;
    color: #666;
    padding: 0 50px 0 10px;
    height: 45px;
    line-height: 45px;
    border-radius: 3px;
    .text-overflow;
    position: relative;
    img{
      position: absolute;
      right: 10px;
      top: 13px;
      cursor: pointer;
      width: 20px;
    }
  }

}

//选择支持的项目
#chooseSupportItem{
  min-width: 865px;
  .popup-content{
    max-height: unset;
    .search_box {
      .search_content,#search-type,.search_icon{
        float: left;
        height: 40px;
        line-height: 40px;
      }
      #search-type{
        border-radius: 0;
      }
      .search_content {
        font-size: 14px;
        padding-left: 10px;
        width: 675px;
      }
      .search_icon{
        margin-left: 10px;
        box-sizing: border-box;
      }
    }
    .activity-list{
      margin-bottom: 20px;
      ul {
        overflow: hidden;
        zoom: 1;
        margin-right: -20px;
        li {
          float: left;
          margin: 20px 20px 0 0;
          width: 253px;
          border: 1px solid @border;
          &.on{
            border: 1px solid @green;
          }
          .img{
            position: relative;
            .img-wrap(100%,173px);
            img{

            }
            .tag{
              position: absolute;
              top: 0;
              right: 0;
              padding: 5px 15px;
              color: #fff;
              font-size: 12px;
              text-align: center;
              background-color: #47acdb;
            }
          }
          .content{
            padding: 10px;
            h3{
              overflow: hidden;
              color: @black;
              font-size: 16px;
              .text-overflow;
            }
            .meta{
              color: #999999;
              font-size: 12px;
            }
            .time{
              float: left;
              padding-left: 25px;
              background: url(../img/activity-time.png) no-repeat left center;
            }
            .apply{
              float: right;
              padding-left: 25px;
              background: url(../img/activity-apply.png) no-repeat left center;
            }
            .address{
              margin-top: 10px;
              padding-left: 25px;
              background: url(../img/activity-address.png) no-repeat 3px center;
            }
            .provider{
              padding: 10px 0 0;
              margin-top: 10px;
              border-top: 1px solid #f4f4f4;
              color: #333;
              font-size: 14px;
              height: 36px;
              line-height: 36px;
              img{
                width: 36px;
                height: 36px;
                .round{
                  border-radius: 50%;
                }
              }
            }
          }
        }
      }
    }

  }
}




.sx-popup-header{
  height: 80px;
  line-height: 80px;
  padding: 0 30px;
  font-size: 18px;
  color: @black;
  position: relative;
  border-bottom: 1px dashed @border;
}

//报名码
.sign-code{
  .content{
    position: relative;
    padding-bottom: 20px;
    overflow: hidden;
    &:after{
      content: '';
      position: absolute;
      left: 315px;
      top: 0;
      bottom: 0;
      border-left: 1px dashed @border;
    }
    .left-content{
      width: 315px;
      float: left;
      text-align: center;
      .img{
        .img-wrap(250px,154px);
        margin: 30px auto;
      }
      .title{
        font-size: 14px;
        color: @black;
        margin-bottom: 20px;
      }
      .code{
        font-size: 26px;
        color: @orange;
      }

    }
    .right-content{
      float: left;
      padding: 30px 35px 0 30px;
      position: relative;
      width: 735px;
      box-sizing: border-box;
      .icon-sign-code-invalid{
        position: absolute;
        right: 30px;
        bottom: 30px;
      }
      .activity-info-list{
        li{
          margin-top: 0;
          margin-bottom: 15px;
        }
      }

      .user-info-list{
        border-bottom: 1px dashed @border;
        border-top: 1px dashed @border;
        line-height: 55px;
        height: 55px;
        li{
          & + li{
            margin-left: 75px;
          }
        }
      }
      .tips-wrapper{
        margin-top: 30px;
        font-size: 14px;
        .title{
          margin-bottom: 15px;
          color: @light-black;
        }
        .tips-content{
          color: @black;
          p{
            font-size: 14px;
            &:last-child{
              margin-bottom: 0;
            }
          }
        }
      }

    }
  }
}

//用户中心
.user-center{
  .card-list{
    li,.item{
      position: relative;
      margin-bottom: 10px;
      &:after{
        content: '';
        display: block;
        position: absolute;
        left: -20px;
        right: -20px;
        bottom: -10px;
        border-bottom: 10px solid @border;
      }
    }

  }
  .user-content{
    >.title-under-gray-line{
      position: relative;
      .title{
        margin-top: 10px;
        & + .title{
          margin-left: 90px;
        }
        &.off{
          color: @black;
        }
      }
      .fr{
        height: 59px;
        line-height: 59px;
        box-sizing: border-box;
        margin-top: 0 !important;
        select{
          border-radius: 4px;
          width: 110px;
          height: 29px;
          vertical-align: middle;
        }
        .btn{
          padding: 0 10px;
          min-width: 90px;
          height: 30px;
          line-height: 30px;
          margin-left: 5px;
        }
      }
    }
    .search-form{
      padding-bottom: 20px;
      border-bottom: 1px solid @border;
      &.no-border{
        padding-bottom: 0;
        border-bottom: 0;
      }
      .btns{
        margin: 20px 0;
      }
      .search{
        .time-range{
          width: 200px;
          margin-right: 20px;
        }
        input,select{
          height: 35px;
          line-height: 35px;
        }
      }
      .select-content-search{
        float: right;
      }
    }

  }
  td.status {
    white-space: nowrap;
  }

  &.page-activity{
       .my-activity{
         .tab-content{
           .list{
             li{
               overflow: hidden;
               padding: 20px 0;
               border-bottom: 1px dashed @border;
               position: relative;
               a{
               }
               &:hover{
                 .content{
                   .title{
                     color: @green;
                   }
                 }
               }
               .img{
                 .img-wrap(135px, 95px);
                 margin-right: 20px;
                 float: left;
               }
               .content{
                 width: 300px;
                 float: left;
                 .title{
                   font-size: 16px;
                   color: @black;
                   margin-bottom: 25px;
                 }
                 .time{
                   font-size: 14px;
                   color: @gray;
                 }
               }
               .right-side{
                 position: absolute;
                 top: 20px;
                 bottom: 20px;
                 right: 0;
                 width: 270px;
                 box-sizing: border-box;
                 .status-text{
                   font-size: 14px;
                   color: @orange;
                   text-align: right;

                 }
                 .opera{
                   overflow: hidden;
                   position: absolute;
                   right: 0;
                   bottom: 0;
                   margin-bottom: -10px;
                   margin-left: -10px;
                   text-align: right;
                   .btn{
                     float: left;
                     margin-bottom: 10px;
                     margin-left: 10px;
                   }
                 }
               }
             }
           }
         }
       }
       #view-code{
         width: 1050px;
         max-width: unset;
         border-radius: 0;
         .popup-header{
           .title{
             margin-bottom: 2px;
             span{
               border: 0;
             }
           }
         }
         .popup-content{
           padding: 0;
           margin: 0;
           max-height: unset;
         }
       }
     }
  &.page-team-profile{
    .img-cover-upload('队伍封面');
    .title-under-gray-line{
      margin-bottom: 50px;
    }
    .form-desc-list{
      .sub{
        font-size: 14px;
        color: @light-black;
        margin-left: 25px;
      }
      .team-cover{
        .desc{
          .img{
            .img-wrap(300px,171px,4px);
          }
        }
      }
    }

  }

  &.page-team-manager{
    .team-manager{
      position: relative;
      .btn-add-team-member{
        position: absolute;
        right: 20px;
        top: 70px;
        width: 90px;
        height: 30px;
        line-height: 30px;
        z-index: 3;
      }
      .team-member-list{
        .headimg-manager-list(100px,30px,470px);
        margin-top: 30px;
        margin-bottom: 85px;
        >li{
          padding: 20px 0;
          .content{
            position: relative;
            font-size: 14px;
            .user-name{
              font-size: 16px;
              color: @black;
              margin-bottom: 5px;
            }
            .user-rank{
              color: @orange;
            }
            .time{
              position: absolute;
              bottom: 10px;
              left: 0;
              color: @gray;
            }
          }
          .opera{
            text-align: right;
            .btn{
              width: 80px;
            }
          }
        }
      }
    }
    #add-team-member{
      max-width: 820px;
      .popup-content{
        margin-top: 0;
      }
      .base-info,.extend-info{
        padding-right: 115px;
        border-bottom: 1px solid @border;
        .info-title{
          font-size: 16px;
        }
      }
      .extend-info{
        padding-top: 30px;
        margin-bottom: 45px;
      }
    }
  }

  &.page-activity-info{
    .user-content{

    }
  }
  .opera{
    max-width: 255px;
    margin-left: -10px;
    margin-bottom: -10px;
    text-align: right;
    margin-top: 5px;
    font-size: 0;
    .btn{
      white-space: nowrap;
      height: 26px;
      line-height: 26px;
      padding: 0 20px;
      margin-left: 10px;
      margin-bottom: 10px;
      position: relative;
      >.num{
        position: absolute;
        top: -10px;
        right: 0;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        background-color: @orange;
        color: #fff;
        line-height: 20px;
        text-align: center;
        overflow: hidden;
      }
    }
  }

  //机构中心 -> 活动列表
  .activity-list{
    .img-info(135px, 94px, 20px);
    padding-top: 10px;
    &.user-activity-list{
      >li{
        a{
          .content{
            width: 300px;
          }
        }
      }
    }
    >li{
      border-bottom: 1px dashed @border;
      position: relative;
      padding: 20px 0;
      a{
        &:hover{
          color: @green;
        }
      }
      .content{
        overflow: hidden;
        .title{
          font-size: 16px;
          color: @black;
          margin-bottom: 20px;
          height: 48px;
        }
        .time{
          font-size: 14px;
          color: @gray;
        }
        .left-content{
          width: 295px;
          padding-right: 50px;
          box-sizing: border-box;
          float: left;
          .set-top{
            font-size: 16px;
            color: @gray;
          }
        }
        .right-content{
          font-size: 14px;
          float: left;
          display: table;
          height: 100%;
          .sign-num-title{
            color: @orange;
            margin-bottom: 10px;
          }
          .sign-num{
            color: @gray;
          }
        }
      }
      >.opera{
        .abs-right-center;
        text-align: right;
        font-size: 0;
      }
      .right-side{
        position: absolute;
        top: 20px;
        bottom: 20px;
        right: 0;
        width: 270px;
        box-sizing: border-box;
        text-align: right;
        .status-text{
          font-size: 14px;
          color: @orange;
          text-align: right;
          display: inline-block;
          .tips{
            .icon-green-question{
              display: none;
            }
            .icon-green-question-border{
              display: inline-block;
            }
            &:hover{
              .icon-green-question{
                display: inline-block;
              }
              .icon-green-question-border{
                display: none;
              }
            }
          }
        }
        .opera{
          position: absolute;
          right: 0;
          bottom: 0;
          margin-bottom: -10px;
          margin-left: -10px;
          margin-top: 5px;
          text-align: right;
          .btn{
            margin-bottom: 10px;
            margin-left: 10px;
          }
        }
      }
    }
  }

  //活动变更申请 列表
  &.page-activity-update-list{
    .update-table{
      .activity-name{
        width: 250px;
      }
      .status{
        width: 160px;
      }
      .time{
        width: 140px;
      }
      .opera{
        text-align: right;

      }
    }
  }

  //活动变更申请 详情
  &.page-activity-alter{
    .form-desc-list{
      @width: 130px;
      .title{
        width: @width;
        height: 22px;
      }
      >.desc,.content .desc{
        margin-left: @width;
      }
      .desc{
      }
      .msg{
        margin-left: @width;
      }
    }
    .pay-item{
      img{
        height: 20px;
        width: auto;
        margin-right: 2px;
      }
      & + .pay-item{
        margin-left: 25px;
      }
    }
  }


  //签到列表
  &.page-sign-list{
    //签到列表
    .sx-sign-list{
    }
    .user-content{
      .activity-title{
        padding: 20px 0;
        font-size: 18px;
        .title{
          color: @gray;
        }
        .desc{
          color: @black;
        }
      }
      .sign-wrapper{
        margin-bottom: 25px;
        overflow: hidden;
        line-height: 45px;
        label{
          input{
            width: 17px;
            height: 17px;
          }
          span{
            margin-left: 5px;
          }
        }
        .btn{
          float: left;
          min-width: 75px;
          height: 35px;
          line-height: 35px;
          padding: 0 15px;
          & + .btn{
            margin-left: 25px;
          }
        }
      }
    }
  }

  
  .user-center-table,.table-excel-import{
		width: 100%;
		height: 60px;
		line-height: 60px;
		text-align: left;
		th{
			white-space: nowrap;
		}
		th,td{
			box-sizing: border-box;
			padding-left: 15px;
			padding-right: 15px;
			vertical-align: middle;
			&:first-child{
				padding-left: 0;
			}
			&:last-child{
				padding-right: 0;
			}
		}
		thead{
			border-bottom: 1px solid @border;
			tr{
				th{
					color: @black;
					font-size: 16px;
					&.opera{
						text-align: left;
					}
				}
			}
		}
		tbody{
			tr{
				border-bottom: 1px dashed @border;
				td{
					padding-top: 20px;
					padding-bottom: 20px;
					color: @light-black;
					font-size: 14px;
					line-height: 1.5;
					&.opera{
						.btn{
							margin-top: 5px;
							margin-bottom: 5px;
							&:first-child{
								margin-left: 0;
							}
						}
					}
				}
			}
		}
	}
}

//报名用户信息弹窗
.popup-applicant-info{
  max-width: 820px;
  //&.popup-team-info{
  //  .popup-info{
  //    .user-info{
  //      .user{
  //        .avatar{
  //          float: left;
  //          .img-wrap(135px,95px);
  //          margin-right: 40px;
  //        }
  //      }
  //    }
  //  }
  //}
  .popup-info{
    width: 725px;
    .user-info{
      border-bottom: 1px solid @border;
      padding: 10px 0;
      overflow: hidden;
      .user,.info{
        height: 135px;
        float: left;
        display: table;
      }
      .user{
        border-right: 1px solid @border;
        overflow: hidden;
        align-items: center;
        color: @black;
        .avatar{
          float: left;
          .avatar(100px);
          margin-right: 40px;
        }
        .title{
          float: left;
          width: 220px;
          h3{
            font-size: 16px;
            margin-bottom: 20px;
          }
          .date{
            font-size: 14px;
          }
        }
      }
      .info{
        padding-left: 45px;
        box-sizing: border-box;
        font-size: 14px;
        flex-direction: column;
        color: @black;
        justify-content: center;
        max-width: 319px;
        .in{
          margin-bottom: 10px;
        }
        .out{

        }
      }
    }
    .form-info{
      .extend{
        padding-top: 30px;
        .form-control{
          .form-field{
            label{
              height: 45px;
              line-height: 45px;
              display: block;
              input[type='checkbox'],input[type='radio']{
                margin-right: 3px;
              }
              span{
              }
            }
          }
        }
      }
      .sub-title{
        font-size: 14px;
        color: @black;
        padding: 30px 0 25px;
      }
      .title-desc-list{
        margin-bottom: 30px;
        li{
          .title{
            float: left;
          }
          .desc{
            margin-left: 75px;
          }
          & + li{
            margin-top: 20px;
          }
        }
      }
    }
  }
}

//捐赠表格
.donate-table{
  font-size: 14px;
  text-align: left;
  width: 100%;
  th,td{
    vertical-align: middle;
  }
  th{
    height: 30px;
    font-weight: bold;
  }
  tbody{
    color: @light-black;
    tr{
      border-bottom: 1px dashed @border;
      td{
        padding: 20px 10px 20px 0;
      }
    }
  }
}

.search-wrapper{
  background-color: #96d345;
  padding: 0 15px;
  height: 60px;
  line-height: 60px;
  margin-bottom: 30px;
  border-radius: 4px;
  .title{
    font-size: 24px;
    color: #fff;
    border-left: 3px solid #fff;
    display: inline-block;
    vertical-align: middle;
    line-height: 1.2;
    padding-left: 10px;
  }
  form{
    float: right;
    .search-form-wrapper{
      margin-top: 12px;
    }
  }
}

.search-form-wrapper{
  font-size: 0;
  height: 36px;
  line-height: 36px;
	&.bg-gray{
		input,select{
			background-color: @bg-gray !important;
		}
	}
  input{
    font-size: 14px;
    color: @light-black;
    background-color: #fff;
    width: 355px;
    height: 36px;
    line-height: 36px;
    border-radius: 4px 0 0 4px;
    vertical-align: middle;
  }
  .btn-search{
    vertical-align: middle;
    padding: 0;
    height: 36px;
    line-height: 36px;
    background-color: @orange;
    width: 65px;
    border: 0;
    border-radius: 0  4px 4px 0;
  }
}

.page-support-project-list{
  .support-project-list{
    .item{
      height: 340px;
    }
  }
}

.page-signin-list{
  .signin-list{
    .item{
      height: 320px;
      .img{
      }
    }
    &.person-signin-list{
      .img{
        margin-left: auto;
        margin-right: auto;
        .avatar(150px);
      }
    }
  }
}

.page-donate-record-list{
  .donate-table{
    th{
      font-size: 16px;
      font-weight: bold;
    }
    td{
      font-size: 14px;
    }
  }
}

.data-pannel{
  padding: 0 20px;
  border-radius: 4px;
  .bg(bg-data-pannel,jpg);
  .data-pannel-item{
    overflow: hidden;
    padding-top: 8px;
    padding-bottom: 10px;
    font-size: 0;
    color: #fff !important;
    a{
      color: #fff !important;
    }
    .num{
      line-height: 1.2;
      font-size: 28px;
    }
    .left,.right{
      float: left;
    }
    .left{
      width: 250px;
      margin-right: 30px;
    }
    span{
      vertical-align: baseline !important;
      font-size: 14px;
      line-height: 1.5;
    }
    & + .data-pannel-item{
      border-top: 1px solid #fc9f52;
    }
  }
}

.export-guide{
  width: 60% !important;
  .form-group{
    width: 540px;
  }
}

//进度圆样式
.process-circle-wrapper{
  height: 98px;
  position: relative;
  width: 98px;
  display: inline-block;
}
.process-circle-wrapper .process-count-wrapper{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  font-size: 24px;
  line-height: 1.5;
  color: @orange;
  white-space: nowrap;
}
.process-circle-wrapper .process-count-wrapper{
  display: inline-block;
  vertical-align: middle;
}
.process-circle-wrapper .process-circle{
  transform: rotate(-90deg);
  transform-origin: center;
}
//进度圆样式  end

.pagination-number{
  display: inline-block;
  height: 30px;
  line-height: 30px;
  margin-left: 10px;
  .pagination-number-input{
    text-align: center;
    padding: 0;
    height: 30px;
    line-height: 30px;
    background-color: #fff;
    border-bottom: 1px solid @border;
    width: 30px;
    border-radius: 0;
  }
  button{
    margin-left: 10px;
  }
}

.status-show-wrapper{
  overflow: hidden;
  font-size: 12px;
  margin-right: -10px;
  .status-show{
    float: left;
    padding: 0 10px;
    & + .status-show{
      border-left: 1px solid @border;
    }
    &:first-child{
      color: @orange;
    }
    &:nth-child(2){
      color: @green;
    }
  }
}

.page-org-list{
  padding-top: 165px;
}
.sp-confirm-pay{
  padding-top: 165px;
}
//义卖
.page-sp{
	margin-bottom: 30px;
  padding-top: 165px;
}

.page-sp-index {
	.stat-list{
		margin-bottom: 40px;
	}
	.charity-sale-list {
		margin-bottom: 40px;

		> li {
			display: flex;
			height: 296px;

			& + li {
				margin-top: 30px;
			}

			.img {
				display: block;
				.img-wrap(480px, 100%);
				position: relative;

				.desc {
					position: absolute;
					bottom: 0;
					width: 100%;
					left: 0;
					font-size: 16px;
					box-sizing: border-box;
					margin: 0;
					height: 50px;
					line-height: 50px;
					padding: 0 15px;
					color: @black;
					background-color: rgba(255, 255, 255, 0.4);
					text-align: left;
				}
			}

			.charity-sale-swiper-wrapper {
				width: 600px;
				height: 100%;
				background-color: #f7f7f7;
				padding: 20px;
				border: 1px solid @border;
				box-sizing: border-box;
				position: relative;

				.charity-sale-swiper-prev, .charity-sale-swiper-next {
					width: 26px;
					height: 26px;
					border: 1px solid #eeeeee;
					border-radius: 50%;
					position: absolute;
					top: 50%;
					z-index: 2;
					margin-top: -13px;
					margin-left: -13px;
					.flex-center;
					i {
						filter: grayscale(5);
					}

					&.active, &:hover {
						&:not(.swiper-button-disabled) {
							cursor: pointer;
							i {
								filter: unset;
							}
						}
					}
				}

				.charity-sale-swiper-prev {
					left: 20px;
				}

				.charity-sale-swiper-next {
					right: 7px;

					i {
						transform: rotateY(180deg);
					}
				}

				.swiper-container {
					.swiper-wrapper {
						.swiper-slide{
							background-color: #fff;
							.charity-sale-item {
								width: 172px;
								height: 260px;
								display: inline-block;
								.img {
									.img-wrap(100%, 112px);
								}

								.content {
									padding: 20px 15px 0;

									.title {
										height: 48px;
										font-size: 16px;
										line-height: 1.5;
										margin-bottom: 15px;
									}

									.cost {
										font-size: 18px;
										margin-bottom: 20px;
										color: @red;
										line-height: 1.2;
									}

									.help-count {
										color: @gray;
										font-size: 14px;
										line-height: 1.2;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

//义卖列表
.charity-list {
  padding-bottom: 80px;
  .number-left-list(20px, 4, 30px);
	> li {
		// width: 255px;
		height: 305px;
		position: relative;
		border: 1px solid @border;
		box-sizing: border-box;

		&:hover {
			border-color: @green;
		}

		.img {
			position: relative;
			.img-wrap(100%, 165px);
		}

		.badge{
			position: absolute;
			right: 0;
			top: 0;
		}

		.content {
			padding: 16px 20px 20px;
			border-top: 0;
			height: 139px;
			box-sizing: border-box;

			.title {
				height: 48px;
				font-size: 16px;
				line-height: 1.5;
				margin-bottom: 8px;
			}

			.cost {
				font-size: 18px;
				margin-bottom: 8px;
				color: @red;
				line-height: 1.2;
			}

			.bottom {
				display: flex;
				align-items: center;
				color: @gray;
				font-size: 14px;
				line-height: 1.2;

				.raise-count, .help-count {
					white-space: nowrap;
				}

				.raise-count {
					width: 60%;
				}

				.help-count {
					text-align: right;
					flex-grow: 1;
				}
			}
		}
	}
}

.sp-topic-list{
	>li{
		background-color: #fff;
		& + li{
			margin-top: 30px;
		}
		&:hover{
			a{
				.img{
					img{
						transform: scale(1.1);
					}
				}
				.content{
					>.title{
						color: @green;
					}
				}
			}
		}
		a{
			display: flex;
			align-items: center;
			height: 250px;
			.img{
				.img-wrap(410px, 100%);
				flex-shrink: 0;
				img{
					transition: all 1s;
				}
			}
			.content{
				border: 1px solid #ededed;
				border-left: 0;
				box-sizing: border-box;
				height: 100%;
				padding: 50px 30px 0 60px;
				background-color: #fff;
				width: 670px;
				>.title{
					font-size: 24px;
					color: @black;
					line-height: 1.5;
					margin-bottom: 15px;
				}
				.org-name{
					font-size: 16px;
					line-height: 1.2;
					color: @gray;
					margin-bottom: 45px;
				}
				.summary-list{
					margin-left: -40px;
					>li{
						.desc{
							font-weight: bold;
						}
						.title{
							color: @light-black;
						}
					}
				}
			}
		}
	}
}

.page-topic-index{
	.title-wrapper{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.sp-topic-list{
		margin-bottom: 70px;
	}
	.search{
		margin-bottom: 0;
		display: flex;
		align-items: center;
	}
}

.sp-page-topic-detail{
	.summary{
		display: flex;
		align-items: flex-start;
		padding-bottom: 40px;

		.img{
			flex-shrink: 0;
			.img-wrap(460px, 285px);
		}
		.content{
			width: 620px;
			padding: 0 5px 0 50px;
			>.title{
				font-size: 24px;
				color: @black;
				line-height: 1.5;
				margin-bottom: 15px;
			}
			.org-name{
				font-size: 16px;
				line-height: 1.2;
				color: @gray;
				margin-bottom: 45px;
			}
			.summary-list{
				margin-left: -40px;
				margin-bottom: 40px;
				>li{
					.desc{
						font-weight: bold;
					}
					.title{
						color: @light-black;
					}
				}
			}
			>.desc{
				font-size: 14px;
				line-height: 1.5;
				color: @light-black;
			}
		}
	}
	.detail{
		border-top: 5px solid #ededed;
		padding-top: 5px;
		.search-form{
			padding-top: 30px;
		}
	}
}

.sp-page-charity-list{
	.search-form-wrapper{
		margin-bottom: 25px;
		display: flex;
		align-items: center;
		padding-top: 10px;
		.title{
			font-size: 14px;
			width: 80px;
			margin-right: 20px;
		}
		.search{
			display: flex;
			margin-bottom: 0;
		}
	}
}

.sp-page-goods-detail{
	min-height: 725px;
	.summary{
		padding-bottom: 40px;
		display: flex;
		align-items: center;
		height: 298px;
		>.img{
			.img-wrap(460px, 100%);
			flex-shrink: 0;
		}
		>.content{
			position: relative;
			margin-left: 40px;
			width: 620px;
			height: 100%;
			.title-area{
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 15px;
				.title{
					line-height: 1.5;
					font-size: 24px;
					color: @black;
					width: 460px;
				}
				.collect-sale{
					display: inline-flex;
					align-items: center;
					color: @gray;
					flex-shrink: 0;
					margin-left: 10px;
					font-size: 14px;
					i{
						margin-right: 5px;
					}
				}
			}
			.goods-info{
				height: 95px;
				background-color: @bg-gray;
				padding: 0 20px;
				border: 1px solid @border;
				font-size: 16px;
				display: flex;
				justify-content: center;
				flex-direction: column;
				margin-bottom: 25px;
				color: @gray;
				.title-desc-list{
					& + .title-desc-list{
						margin-top: 5px;
					}
					.price{
						font-size: 24px;
					}
					>li{
						display: flex;
						align-items: baseline;
						width: 50%;
						>.title{
							display: inline-block;
						}
						>.desc{
							color: @gray;
							display: inline-flex;
							align-items: baseline;
						}
					}
				}
			}

			.sale-info-list{
				margin-bottom: 30px;
				>li{
					display: flex;
					align-items: center;
					>.icon{
						width: 20px;
						display: flex;
						align-items: center;
					}
					>.title{
						color: @light-black;
					}
				}
			}

			>.btn-group{
				display: flex;
				.btn{
					& + .btn{
						margin-left: 15px;
					}
				}
			}
			.qr-code-container {
				position: absolute;
				right: 0;
				bottom: 10px;
				.qr-code-wrapper;
			}

			.buy-panel{
				box-shadow: 0 2px 8px 1px rgba(51, 51, 51, 0.20);
				position: absolute;
				top: 100%;
				width: 100%;
				z-index: 12;
				background-color: #fff;
				padding: 30px 20px 40px;
				box-sizing: border-box;
				border-radius: 5px;
				transform: scale(0);
				transform-origin: left top;
				transition: all .3s;
				&.active{
					transform: scale(1);
				}
				&:before{
					content: '';
					background-color: #fff;
					position: absolute;
					left: 70px;
					top: -3px;
					width: 5px;
					height: 5px;
					border-top: 1px solid #ededed;
					transform: scaleX(0.7) rotate(45deg);
					border-left: 1px solid #ededed;
				}
				.item{
					display: flex;
					align-items: center;
					& + .item{
						margin-top: 20px;
					}
					.title{
						width: 75px;
						flex-shrink: 0;
						font-size: 14px;
						color: @light-black;
					}
					&.sp-standard{
						align-items: flex-start;
						.title{
							height: 32px;
							line-height: 32px;
						}
					}
					&.send-types{
						.select-type-render{
							>li{
								margin-bottom: 0;
							}
						}
					}
					.fetch-msg{
						margin-left: 35px;
						color: @light-black;
						display: none;
						align-items: center;
						&.show{
							display: flex;
						}
						.btn-self-pay-fare{
							cursor: default;
							.btn-set(70px, 30px);
						}
					}
					&.buy-number{
						color: @gray;
						>.content{
							display: flex;
							align-items: center;
							.num-toggle-render{
								margin-right: 15px;
							}
						}
					}
				}
				.btn-group{
					margin-top: 35px;
					.btn{
						.btn-set(85px, 30px);
					}
				}
			}
		}
	}

	.detail{
		border-top: 5px solid #ededed;
		padding-top: 5px;
		.main-info{
			width: 740px;
			float: left;
			.sp-donate-list{
				margin-top: 10px;
			}
		}
		.sidebar{
			width: 300px;
			float: right;
		}
	}


}

.sp-donate-list{
	>li{
		padding: 20px 0;
		display: flex;
		align-items: center;
		border-bottom: 1px dashed @border;
		.avatar{
			.avatar(70px);
			margin-right: 20px;
		}
		.donator{
			width: 300px;
			.name{
				font-size: 16px;
				color: @black;
				margin-bottom: 13px;
				.text-overflow;
			}
			.time{
				font-size: 12px;
				color: @gray;
			}
		}
		.donate-number,.donate-price{
			display: flex;
			align-items: center;
			width: 185px;
			color: @light-black;
		}
		.donate-number{
		}
		.donate-price{
			justify-content: flex-end;
		}
	}
}

.goods-info-block{
	background-color: #f5f5f5;
	border: 1px solid @border;
	padding: 15px 20px;
	box-sizing: border-box;
	>.meta{
		color: @black;
		font-size: 16px;
		display: flex;
		align-items: center;
		.title{
			line-height: 1.5;
			margin-right: 40px;
		}
		.info{
			line-height: 1.5;
		}
	}
	.desc{
		font-size: 14px;
		line-height: 1.5;
		color: @light-black;
	}
	& + &{
		margin-top: 20px;
	}
}

.sale-order-table{
	font-size: 14px;
	width: 1000px;
	border: 1px solid @border;
	th,td{
		vertical-align: middle;
		text-align: center;
		box-sizing: border-box;
		&:first-child{
			text-align: left;
			padding-left: 20px;
			width: 300px;
		}
	}
	thead{
		background-color: @bg-gray;
		tr{
			th{
				color: @black;
				height: 40px;
			}
		}
	}
	tbody{
		tr{
			td{
				color: @light-black;
				padding: 20px;
				padding-right: 10px;
				.product-info{
					display: flex;
					align-items: center;
					.img{
						.img-wrap(105px, auto);
						flex-shrink: 0;
						margin-right: 15px;
					}
				}
				&.color{
					width: 130px;
				}
			}
		}
	}
}

.sp-module-title{
	font-size: 18px;
	color: @black;
	.sp-module-anchor{
		font-size: 16px;
		color: @orange;
	}
}

//确认订单
.page-sale-order{
	>.main-content{
		padding: 0 20px;
		.sale-order-container{
			width: 1000px;
			margin: 0 auto;
		}
		.title-under-green-line{
			margin-bottom: 30px;
		}
		.sp-module-title{
			margin-bottom: 20px;
			display: flex;
			align-items: baseline;
		}
		.order-wrapper{
			padding-top: 30px;
			padding-bottom: 30px;
			border-bottom: 1px solid @border;
			border-top: 1px solid @border;
			.sale-order-table{

			}
		}
		.support-project{
			display: flex;
			padding: 30px 0;
			border-bottom: 1px solid @border;
			>.sale-order-title{
				margin-bottom: 0;
			}
			.support-project-list{
				font-size: 16px;
				>li{
					& + li{
						margin-top: 10px;
					}
					a{
						color: @blue;
					}
				}
			}
		}

		.donator-from{
			padding-top: 30px;
			padding-right: 20px;
			padding-bottom: 30px;
			border-bottom: 1px solid @border;

			.user-info{
				border-bottom: 1px solid @border;
			}

			.receipt-info{
				padding-bottom: 30px;
				border-bottom: 1px solid @border;
				padding-top: 25px;
				margin-bottom: 50px;
				.receipt-select{
					font-size: 14px;
					.receipt-item{
						display: inline-flex;
						color: @black;
						& + .flex-v-center{
							margin-left: 40px;
						}
					}
					.receipt-detail{
						margin-left: 20px;
					}
				}

				.bill-item{
					.receipt-show{
						& + .receipt-show{
							margin-left: 10px;
						}
					}
				}
			}

			.btn-submit{
				.btn-set(145px, 35px);
			}
		}

	}
}
.info-block{
	padding: 30px 0;
	.title-desc-item{
		>.title{
			vertical-align: top;
			color: @black;
		}
		>.desc{
			vertical-align: top;
			color: @light-black;
		}
		a{
			color: @blue;
		}
		& + .title-desc-item{
			margin-top: 10px;
		}
	}
}

.address-select-list,.invoice-select-list{
	>li{
		cursor: pointer;
		position: relative;
		.goods-info-block{
			>.meta{
				margin-bottom: 5px;
			}
		}
		&.active{
			border: 1px solid @red;
			.selected-item;
		}
	}
}

.invoice-address-popup{
	width: 740px !important;
	box-sizing: border-box;
	.popup-info{
		>.meta{
			margin-bottom: 15px;
			a{
				font-size: 16px;
				color: @orange;
			}
		}
		.address-select-list,.invoice-select-list{
			margin-bottom: 45px;
		}
	}
}

.sp-confirm-pay{
	.pay-method{
		margin-bottom: 45px;
		height: 60px;
		line-height: 60px;

		.title {
			height: 60px;
			line-height: 60px;
			color: @light-black;
		}

		.input {
			.select-label(180px, 60px);
		}
	}
}

.sp-order-list{
	margin-bottom: 30px;
	>li{
		padding: 30px 0;
		border-bottom: 1px dashed @border;
		display: flex;
		justify-content: space-between;
		.main-info{
			display: inline-flex;
			.img,.code-img{
				.img-wrap(134px, 86px);
				flex-shrink: 0;
				margin-right: 20px;
			}
			.title-info{
				height: 100%;
				width: 210px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				.title,.code-title{
					height: 53px;
					font-size: 16px;
					color: @light-black;
					line-height: 1.5;
					margin-bottom: 15px;
				}
				.type,.code-type{
					line-height: 1.2;
					font-size: 14px;
					color: @gray;
					.text-overflow;
				}
			}
		}
		.right-info{
			width: 345px;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			justify-content: space-between;
			.top-info{
				width: 100%;
				display: flex;
				justify-content: space-between;
				font-size: 14px;
				color: @light-black;
				.order-status{
					color: @orange;
				}
			}
			.opera{
				max-width: 324px;
			}
		}
	}
}

//订单详情
.page-user-sp-order-detail{
	.sp-order-list{
		margin-bottom: 0 !important;
		>li{
			border-bottom-style: solid;
		}
	}
}

.attachment-list{
  width: 600px;
  margin-top: 30px;
  li{
    a{
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      border-bottom: 1px dashed @border;
      .title{
        font-size: 14px;
        color: @light-black;
        .text-overflow;
        max-width: 500px;
      }
      .time{
        font-size: 12px;
        color: @gray;
      }
    }
  }
}

.ossuploader-upload-box{
  .ossuploader-add{
    justify-content: center;
    p{
      margin-bottom: 0;
      font-size: 14px;
      color: #666;
    }
    i{
      width: 40px !important;
      height: 40px !important;
    }
  }
}

.project-list-search {
  .project-list-input {
    background-color: #fff;
    width: 355px;
    border: 1px solid #68c258;
    border-right: 0;
  }
  margin-bottom: 30px;
}

.page-orguser-donate-apply-list{
	.user-center-table{
		.applier{
		}
		.mobile{
		}
		.wish_title{
			width: 130px;
		}
		.apply-time{
			width: 145px;
		}
		.apply-status{
			width: 95px;
		}
		.opera{
			width: 365px;
		}
	}
}

.page-user-wish-list{
	.search-list{
		>li{
			.export_excel{
				border: 0;
				background-color: transparent;
				line-height: 30px;
				height: 30px;
			}
			&.active{
				.export_excel{
					color: #fff;
				}
			}
		}
	}
}

.page-user-wish-process-list,
.page-edit-user-wish-process-detail,
.page-user-wish-process-detail{
	.wish-title{
		font-size: 16px;
		color: @black;
		margin-bottom: 10px;
		a{
			color: @black;
		}
	}
	.img-list{
		>li{
			width: 135px;
			height: 85px;
		}
	}
}

.page-user-wish-process-list{
	.center-wish-process-list{
		font-size: 16px;
		margin-bottom: 15px;
		>li{
			& + li{
				margin-top: 30px;
			}
			.meta{
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0 15px;
				background-color: #f5f5f5;
				border: 1px solid @border;
				font-size: 14px;
				color: @gray;
			}
			.content{
				padding: 0 15px 15px;
				border: 1px solid @border;
				border-top: 0;
				.wish-process-summary{
					padding-top: 10px;
					padding-bottom: 10px;
					color: @light-black;
				}
				.content-bottom{
					display: flex;
					align-items: center;
					justify-content: space-between;
					.left{

					}
					.opera{
						width: 120px;
					}
				}
			}
		}
	}
}

//topic 列表
.page-orguser-sp-topic-list{
	.orguser-sp-topic-list{
		>li{
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 30px 0;
			border-bottom: 1px dashed @border;
			a{
				flex-grow: 1;
				display: inline-flex;
				align-items: center;
				.img{
					margin-right: 20px;
					.img-wrap(139px, 86px);
				}
				.content{
					width: 265px;
					.title{
						font-size: 16px;
						color: @light-black;
						line-height: 1.5;
						height: 40px;
						margin-bottom: 10px;
					}
					.bottom{
						display: flex;
						line-height: 1.5;
						font-size: 14px;
						color: @gray;
						.raise-count{
							width: 130px;
						}
					}
				}
			}
		}
	}
}
.pick-up-info {
  .qr-code-wrapper {
    margin: 20px 0;
    height: auto;
    width: 195px;
    padding: 10px 5px;
    >.img {
      width: 80px;
      height: 80px;
    }
  }
}

.pick-up-point-list {
  .district-info {
    display: flex;
    .district_title {
      flex-shrink: 0;
    }
  }
}
.page-donate-people {
  .orguser-search {
    .form_item {
      margin-right: 30px !important;
    }
  }
  table {
    .btn.btn-border.btn-green {
      height: 30px;
      line-height: 30px;
      padding: 0 15px;
    }
  }
}


//选择地址
.address-wrapper{
	padding-bottom: 30px;
	.address-list{
		display: flex;
		flex-wrap: wrap;
		margin-left: -20px;
		margin-bottom: 0;
		>li{
			box-sizing: border-box;
			width: 320px;
			height: 130px;
			margin-left: 20px;
			margin-bottom: 20px;
			padding: 0 30px;
			overflow: hidden;
			border-radius: 5px;
			box-shadow: 0 0 11px 0 rgba(51, 51, 51, 0.18);
			position: relative;
			cursor: pointer;
			&:nth-child(n + 4){
				display: none;
			}
			&.active{
				box-shadow:    0px -1px 0px 0px @red,   /*上边阴影  红色*/
				-1px 0px 0px 0px @red,   /*左边阴影  绿色*/
				1px 0px 0px 0px @red,    /*右边阴影  蓝色*/
				0px 1px 0px 0px @red;    /*下边阴影  黄色*/;
				.selected-item;
			}
			.default-select{
				box-sizing: border-box;
			}
			.user-info{
				border-bottom: 1px solid @border;
				display: flex;
				align-items: center;
				font-size: 16px;
				color: @light-black;
				height: 53px;
				line-height: 53px;
				.name{
					width: 85px;
				}
				.tel{

				}
			}
			.address{
				box-sizing: border-box;
				padding: 20px 0;
				line-height: 1.2;
				font-size: 14px;
				color: @gray;
				height: 75px;
			}
		}
	}
	.bottom{
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}