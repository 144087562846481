@import 'var.less';



//.bg(@url,@width,@height) {
//  background: url("@{img_url}/@{url}.png") no-repeat center center;
//  background-size: @width @height;
//  width: @width;
//  height: @height;
//}

.iconGen(@icon,@width:10px,@height:10px){
  display: inline-block;
  background: url("@{icon-dir}/@{icon}.png") no-repeat center;
  width: @width;
  height: @height;
  background-origin:content-box;
  background-size: @width @height;
}
.icon-activity-msg{
  .iconGen(activity-msg,15px,17px);
}
.icon-price{
  .iconGen(price,17px,11px);
}
.icon-warning{
  .iconGen(warning,13px,13px);
}

.icon-show-qrcode{
  .iconGen(show-qrcode,42px,42px);
}

.icon-close{
  .iconGen(close,25px,25px);
}

.icon-close-no-popup {
  .iconGen(close,25px,25px);
}

.icon-search{
  .iconGen(search,21px,22px);
}

.icon-charitable-count{
  .iconGen(charitable-count,38px,30px);
}

.icon-donate{
  .iconGen(donate,29px,30px);
}

.icon-user{
  .iconGen(user,33px,33px);
}

.icon-left-arrow{
  .iconGen(left-arrow,13px,23px);
}

.icon-select-drow{
  .iconGen(select-drow,10px,7px);
}

.icon-share-sina-weibo{
  .iconGen(share-sina-weibo,22px,22px);
}

.icon-share-tencent-weibo{
  .iconGen(share-tencent-weibo,22px,22px);
}

.icon-share-tencent-wechat{
  .iconGen(share-tencent-wechat,22px,22px);
}

.icon-right-arrow{
  .iconGen(right-arrow,10px,18px);
}

.icon-tip{
  .iconGen(tip,14px,14px);
}

.icon-donate-select{
  .iconGen(donate-select,22px,22px);
}

.icon-close-share{
  .iconGen(close-share,28px,28px);
}

.icon-ques{
  .iconGen(ques,19px,21px);
}

.icon-checked{
  .iconGen(checked,44px,44px);
}


.icon-close-share{
  cursor: pointer;
}

.icon-clock{
  .iconGen(clock,14px,14px);
}

.icon-people{
  .iconGen(people,13px,14px);
}

.icon-address{
  .iconGen(address,14px,18px);
}

.icon-green-add-item{
  .iconGen(green-add-item,30px,30px);
}

.icon-top-allow{
  .iconGen(top-allow,15px,9px);
}

.icon-close-mask{
  .iconGen(close-mask,28px,28px);
}

.icon-sign-code-invalid{
  .iconGen(sign-code-invalid,188px,128px);
}

.icon-green-remove-item{
  .iconGen(green-remove-item,30px,30px);
}

.icon-orange-add-item{
  .iconGen(orange-add-item,30px,30px);
}

.icon-orange-remove-item{
  .iconGen(orange-remove-item,30px,30px);
}


.icon-green-question-border{
  .iconGen(green-question-border,14px,14px);
}

.icon-green-question{
  .iconGen(green-question,14px,14px);
}

.icon-heart-white{
  .iconGen(heart-white,32px,32px);
}

.icon-left-arrow-s{
  .iconGen(left-arrow-s,8px,13px);
}

.icon-select-checked{
  .iconGen(select-checked,12px,12px);
}